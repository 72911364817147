import { Grid } from "@mui/material";
import ProjectIcon from "./projectIcon";
import EditProjectInfo from "./editProjectInfo";
import ProjectLength from "./projectLength";
import ProjectTeam from "./projectTeam";
import { useEffect, useState } from "react";

const EditProject = ({ newProject, setNewProject, editing, setStateClientList }) => {

  const [totalDays, setTotalDays] = useState(0);

  const startDate = new Date(newProject.Start);
  const endDate = new Date(newProject.End);

  useEffect(() => {
    if(newProject.Start && newProject.End)
    {
      console.log("Start", newProject.Start);
      console.log("End", newProject.End);
    setTotalDays(Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)));
    }
  }
  , [newProject]);

  return (
    <div>
      <Grid container spacing={3} style={{ margin: "auto", maxWidth: "700px" }}>
        <Grid item xs={12} md={5} style={{ marginBottom: "10px" }}>
          <ProjectIcon
            project={newProject} // Pass the updated client with avatar URL
            setProject={setNewProject}
            editing={editing}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          {editing && (
            <EditProjectInfo
              newProject={newProject}
              setNewProject={setNewProject}
            />
          )}
        </Grid>
      </Grid>
      <div className="header">
        <div className="text">
          <div className="title">Durée du projet</div>
          <div className="sub-title">Sélectionner la durée du projet.</div>
        </div>
        <div style={{display:"flex", alignItems:"center", color:"#61dafb"}}>Durée: {totalDays} jours</div>
      </div>
      {
        /*

        <ProjectLength newProject={newProject} setNewProject={setNewProject} />

        */
      }
      
      <ProjectLength newProject={newProject} setNewProject={setNewProject} />
      <div>
      <ProjectTeam newProject={newProject} setNewProject={setNewProject} editing={editing} setStateClientList={setStateClientList} />
      </div>
      
    </div>
  );
};

export default EditProject;
