
import { Card } from '@mui/material';
import React from 'react';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';


const ClientCardSidebar = ({ scrollToTop, client, newWorker, setNewWorker, setSelectedClient, noOnClick }) => {
  return (
    <>
          <Card className="card-list" style={{ position: "relative", marginTop: "10px"}}
          title={`Ajouter ${client.userFirstName} à l'équipe.`}
          onClick={() => {
            if(!noOnClick)
            {
              scrollToTop()
              const fullName = client.userFirstName + " " + client.userLastName
              setNewWorker({
                ...newWorker,
                fullName,
                client:client
              })
              setSelectedClient(client)
            }
          }}
          >
            <div className="img">
              <img
                src={client.avatar}
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="info">
              <div className="card-title">
                
                  <>{client.userFirstName}</>
                
                
                  <> {client.userLastName}</>
                
              </div>
              <div className="card-subtitle" style={{display:"flex", alignItems:"center"}}>
              <PhoneIphoneIcon style={{fontSize:"15px", marginRight:"3px"}} />   {client.userCell}
              </div>
              <div className="card-subtitle"
              style={{display:"flex", alignItems:"center"}}
              >
              <EmailIcon style={{fontSize:"15px", marginRight:"3px"}} />
               {client.userEmail}
              </div>
            </div>
          </Card>
        
      
    </>
  );







};

export default ClientCardSidebar;
