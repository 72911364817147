import { Container } from "@mui/material";

const DashboardDepenses = () => {
    return (
        <Container maxWidth="lg">
            <div className="header">
                <div className="text">
                    <div className="title">Dépenses</div>
                    <div className="sub-title">Résumé des dépenses.</div>
                </div>
            </div>
        </Container>
    );
    }
export default DashboardDepenses;