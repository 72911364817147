import { Container } from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";

const Schedules = () => {
  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">Horaires</div>
            <div className="sub-title">
              Gérer et consulter les horaires de votre système.
            </div>
          </div>
          <AddBoxIcon className="add-icon" />
        </div>
      </Container>
    </div>
  );
};
export default Schedules;
