import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import InventoryIcon from '@mui/icons-material/Inventory';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import DomainIcon from '@mui/icons-material/Domain';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ContactsIcon from '@mui/icons-material/Contacts';
import DvrIcon from '@mui/icons-material/Dvr';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Dashboard from "@mui/icons-material/Dashboard";

const Sidebar = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  return (
    <div className="sidebar-dashboard">
      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"10px", backgroundColor:"#2c3e50"}}>

      <div style={{color:"#61dafb", letterSpacing:"1px"}}>
        Tableau de bord
      </div>
      <Dashboard style={{color:"#61dafb"}}/>
      </div>
      <Link to="/Dashboard/Résumé" className="noDecoration">
        <div
          className={
            location.pathname === "/Dashboard/R%C3%A9sum%C3%A9"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        >
          <SignalCellularAltIcon className="btn-icon"/>
          Résumé
        </div>
      </Link>

      <Link to="/dashboard/produits" className="noDecoration">
        <div 
          className={
            location.pathname === "/dashboard/produits"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        
        >
          
          <InventoryIcon className="btn-icon"/>
          
          Produits
          
          </div>
      </Link>
      <Link to="/Dashboard/Clients" className="noDecoration">
        <div
          className={
            location.pathname === "/Dashboard/Clients"
              ? "sidebar__item active"
              : "sidebar__item"
          }

        >
          <FolderSharedIcon className="btn-icon"/>
          Clients</div>
      </Link>
      <Link to="/Dashboard/Commandes" className="noDecoration">
        <div 
          className={
            location.pathname === "/Dashboard/Commandes"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        
        
        >
          <LocalShippingIcon className="btn-icon"/>
          
          Commandes</div>
      </Link>
      <Link to="/Dashboard/Factures" className="noDecoration">
        <div
          className={
            location.pathname === "/Dashboard/Factures"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        
        >
          <ReceiptIcon className="btn-icon"/>
          
          Factures</div>
      </Link>
      
      <Link to="/Dashboard/Compagnies" className="noDecoration">
        <div
          className={
            location.pathname === "/Dashboard/Compagnies"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        
        >
          <DomainIcon className="btn-icon"/>
          
          Compagnies</div>
      </Link>
      <Link to="/Dashboard/Employés" className="noDecoration">
        <div
          className={
            location.pathname === "/Dashboard/Employ%C3%A9s"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        >
          <ContactsIcon className="btn-icon"/>
          
          Employés</div>
      </Link>
      <Link to="/Dashboard/Dépenses" className="noDecoration">
        <div
          className={
            location.pathname === "/Dashboard/D%C3%A9penses"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        >
          <AttachMoneyIcon className="btn-icon"/>
          Dépenses</div>
      </Link>
      <Link to="/Dashboard/Logs" className="noDecoration">
        <div
          className={
            location.pathname === "/Dashboard/Logs"
              ? "sidebar__item active"
              : "sidebar__item"
          }
        >
          <DvrIcon className="btn-icon"/>
          Logs</div>
      </Link>
    </div>
  );
};

export default Sidebar;
