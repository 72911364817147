import AddBox from "@mui/icons-material/AddBox";
import { useEffect, useState } from "react";
import EditCompany from "./editCompany";
import CancelIcon from "@mui/icons-material/Cancel";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import config from "../../../config";
import { Grid } from "@mui/material";
import CompanyCard from "./companyCard";
import Loader from "../../../components/loader";
import SaveIcon from "@mui/icons-material/Save";
import { useNotify } from "../../../hooks/useNotify";

const MyCompanies = () => {
  const { user } = useGlobalContext();
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [newCompany, setNewCompany] = useState({
    companyName: "",
    companyEmail: "",
    companyTel: "",
    companyLogo: "",
    myCompany: true,
    companyAddresses: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  const { notify } = useNotify();

  const getMyCompanies = async () => {
    const response = await fetch(`${config.BASE_URL}/api/Company/myCompanies`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });

    const json = await response.json();
    console.log(json);
    if (json.length != 0) {
      setCompanies(json);
    } else {
      setCompanies([]);
      setIsLoading(false);

      return;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      getMyCompanies();
    }
  }, [user]);

  const handleSaveNewCompany = async () => {
    if (
      !newCompany.companyName ||
      !newCompany.companyEmail ||
      !newCompany.companyTel
    ) {
      notify("error", "Veuillez remplir tous les champs aves un astérix");
      return;
    }

    const response = await fetch(`${config.BASE_URL}/api/Company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`
      },
      body: JSON.stringify(newCompany)
    });

    const json = await response.json();
    if (json.error) {
      console.log(json.error);
      return;
    }

    setCompanies([json, ...companies]);
    setNewCompany({
      companyName: "",
      companyEmail: "",
      companyTel: "",
      companyLogo: "",
      myCompany: true,
      companyAddresses: []
    });
    setShowAddCompany(false);
  };

  return (
    <div>
      <div className="sub-header">
        <div className="text">
          <div className="title">Mes Compagnies</div>
          <div className="sub-title">
            Gérer les informations de vos compagnies.
          </div>
        </div>
        {!showAddCompany && (
          <div onClick={() => setShowAddCompany(true)}>
            <AddBox className="add-icon" />
          </div>
        )}
        {showAddCompany && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div onClick={handleSaveNewCompany}>
              <SaveIcon className="add-icon" />
            </div>
            <div onClick={() => setShowAddCompany(false)}>
              <CancelIcon className="add-icon" />
            </div>
          </div>
        )}
      </div>
      {showAddCompany && (
        <EditCompany
          newCompany={newCompany}
          setNewCompany={setNewCompany}
          editing={true}
        />
      )}
      {isLoading && <Loader />}
      {!isLoading && !showAddCompany && (
        <Grid container style={{ margin: "20px", padding: "0px" }}>
          {companies.map((company) => {
            return <CompanyCard key={company.id} company={company} />;
          })}
        </Grid>
      )}
    </div>
  );
};
export default MyCompanies;
