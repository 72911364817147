import { Link, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../../context/snackbarContext";
import { Container, Grid } from "@mui/material";
import Loader from "../../../components/loader";
import UserIcon from "./userIcon";
import config from "../../../config";
import "../employes.css";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ModifyUserInfo from "./modifyUserInfo";
import Permissions from "./permissions";

const InputsEmploye = () => {
  const { id } = useParams();
  const { user } = useGlobalContext();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();

  const [activeEmploye, setActiveEmploye] = useState({
    salaire: {
      montant: 0,
      method: "heure"
    },
    user: {
      userFirstName: "",
      userLastName: "",
      userGender: "male",
      userCell: "",
      userEmail: "",
      avatar: "",
      userVerified: false,
      userAddresses: [],
      userBirthDate: new Date(),
    },
    permissions:{
      dashboards: {},
      applications: {},
      settings: {}
    },
    codeSecurity:{
      digit1:0,
      digit2:0,
      digit3:0,
      digit4:0
    }
  });

  const fetchEmploye = async () => {
    const response = await fetch(`${config.BASE_URL}/api/employes/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      setActiveEmploye({
        ...json,
        poste: json.poste._id,
      }
        );



      console.log("Employe", json);
    }
    if (!response.ok) {
      showSnackbar("Erreur lors de la récupération de l'employé", "error");
    }
  };

  useEffect(() => {
    if (user && id) {
      fetchEmploye();
    } else if (!id && user) {
      generateAvatar();
    }
    window.scrollTo(0, 0);
  }, [id, user]);

  // Generate avatar when component mounts or newClient changes
  const generateAvatar = async () => {
    console.log("Generate Avatar");
    const gender = { gender: activeEmploye.user.userGender };

    try {
      const avatarUrl = await fetch(
        `${config.BASE_URL}/api/generate/generateAvatar`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          },
          body: JSON.stringify(gender)
        }
      );

      if (avatarUrl.ok) {
        const json = await avatarUrl.json();
        // Update the client with the generated avatar URL
        setActiveEmploye({
          ...activeEmploye,
          user: {
            ...activeEmploye.user,
            avatar: json.url
          }
        });
      } else {
        console.error("Failed to fetch avatar URL");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.startsWith("userAddresses.")) {
      const addressIndex = name.split(".")[1];
      const addressField = name.split(".")[2];

      const updatedAddresses = [...activeEmploye.user.userAddresses];

      updatedAddresses[addressIndex] = {
        ...updatedAddresses[addressIndex],
        [addressField]: value
      };

      setActiveEmploye({
        ...activeEmploye,
        user: {
          ...activeEmploye.user,
          userAddresses: updatedAddresses
        }
      });
    } else {
      setActiveEmploye({
        ...activeEmploye,
        user: { ...activeEmploye.user, [name]: value }
      });
    }
  };

  const handleSaveEmploye = async () => {
    setIsLoading(true);
    const method = id ? "PATCH" : "POST";
    const url = id
      ? `${config.BASE_URL}/api/employes/${id}`
      : `${config.BASE_URL}/api/employes`;
  
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify(activeEmploye)
      });
  
      if (response.ok) {
        showSnackbar("Employé enregistré avec succès", "success");
        navigate(-1);
      } else {
        const errorData = await response.json(); // Parse the JSON error response
        const errorMessage = errorData.error || "Erreur lors de l'enregistrement de l'employé";
        showSnackbar(errorMessage, "error");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      showSnackbar("Erreur lors de l'enregistrement de l'employé", "error");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="main-content">
      <Container maxWidth="lg" style={{ paddingBottom: "20px" }}>
        <div className="header">
          <div className="text">
            <div className="title">
              {id ? "Modifier l'employé" : "Nouvel employé"}
            </div>
            <div className="sub-title">
              {id
                ? "Modifier les informations de l'employé"
                : "Veuillez remplir les informations de l'employé"}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div title={id ? "Enregistrer" : "Ajouter"}
            onClick={handleSaveEmploye}
            >
              <SaveIcon className="add-icon" />
            </div>
            <div title="Annuler">
              <Link to={-1}>
                <CancelIcon className="add-icon" />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Grid
            container
            style={{ marginTop: "20px"}}
    
          >
            <Grid item xs={12} md={5} style={{ marginBottom: "10px" }}>
              <UserIcon
                client={activeEmploye} // Pass the updated client with avatar URL
                setClient={setActiveEmploye}
                editing={true}
                generateAvatar={generateAvatar}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <ModifyUserInfo
                handleInputChange={handleInputChange}
                client={activeEmploye}
                setClient={setActiveEmploye}
              />
            </Grid>
          </Grid>
        </div>
        <div className="header">
          <div className="text">
            <div className="title">Permissions</div>
            <div className="sub-title">
              Gérer et consulter les permissions de l'employé dans votre
              système.
            </div>
          </div>
        </div>
        <Permissions activeEmploye={activeEmploye} setActiveEmploye={setActiveEmploye} editing={true} />
      </Container>
    </div>
  );
};

export default InputsEmploye;
