import { useGlobalContext } from "../../../../hooks/useGlobalContext";
import { useState } from "react";
import AddTag from "./addTags";
import DeleteTag from "./deleteTag";
import "../../products.css";
import "../settings.css";

import { Grid, Card, Typography, Checkbox, FormControlLabel } from "@mui/material";

const TagsCheckboxes = ({ checkedTags, setCheckedTags }) => {
  const { tags } = useGlobalContext();
  const [activeMateriaux, setActiveMateriaux] = useState({ name: null, _id: null });
  const [activeCouleur, setActiveCouleur] = useState({ name: null, _id: null });
  const [activeCaracteristique, setActiveCaracteristique] = useState({ name: null, _id: null });
  const [activeDecor, setActiveDecor] = useState({ name: null, _id: null });

  const handleDivClick = (event) => {

    const newTag = {
      name: event.target.getAttribute("nom") || event.currentTarget.getAttribute("nom"),
      _id: event.target.getAttribute("id") || event.currentTarget.getAttribute("id"),
      tagType: event.target.getAttribute("tagtype") || event.currentTarget.getAttribute("tagtype"),
    };

    if (newTag.tagType === "Materiaux") setActiveMateriaux(newTag);
    if (newTag.tagType === "Couleur") setActiveCouleur(newTag);
    if (newTag.tagType === "Caracteristique") setActiveCaracteristique(newTag);
    if (newTag.tagType === "Décor") setActiveDecor(newTag);
  };

  const handleSelect = (tag, isChecked) => {
    const newTag = {
      name: tag.Name,
      _id: tag._id,
      tagType: tag.tagType,
    };

    console.log("newTag", newTag);

    if (isChecked) {
      setCheckedTags((prevTags) => [...prevTags, newTag]);
    } else {
      setCheckedTags((prevTags) => prevTags.filter((tag) => tag._id !== newTag._id));
    }
  };

  const renderTagSection = (tagType, activeTag, setActiveTag, title) => (
    <Grid item xs={12} sm={6} md={3} style={{marginTop:"20px"}}>
      <Card className="card-list-settings">
          <div className="title">{title}</div>
          <div className="content">
       
          {tags && tags.filter(tag => tag.tagType === tagType).map((tag) => (
            <div
              className={activeTag._id === tag._id ? "inputCheck active" : "inputCheck"}
              key={tag._id}
              id={tag._id}
              nom={tag.Name}
              tagtype={tag.tagType}
              onClick={handleDivClick}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id={`label-${tag._id}`}
                    checked={!!checkedTags.find((el) => el._id === tag._id)}
                    onChange={()=>{handleSelect(tag, !checkedTags.find((el) => el._id === tag._id))}}
                    
                  />
                }
                label={tag.Name}
              />
            </div>
          ))}
        </div>
        <div className="footer">
          <AddTag title={`Ajouter un ${title.toLowerCase()}`} tagType={tagType} setCheckedTags={setCheckedTags} checkedTags={checkedTags} />
          <DeleteTag title={`Supprimer un ${title.toLowerCase()}`} id={activeTag._id} nom={activeTag.name} setCheckedTags={setCheckedTags} checkedTags={checkedTags} />
        </div>
      </Card>
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      {renderTagSection("Décor", activeDecor, setActiveDecor, "Décor")}
      {renderTagSection("Materiaux", activeMateriaux, setActiveMateriaux, "Matériaux")}
      {renderTagSection("Couleur", activeCouleur, setActiveCouleur, "Couleur")}
      {renderTagSection("Caracteristique", activeCaracteristique, setActiveCaracteristique, "Caractéristique")}
    </Grid>
  );
};

export default TagsCheckboxes;

