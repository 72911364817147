import loaderIcon from "./loading-gif.gif"


const Loader = () =>{


    return (
        <div style={{minWidth:"100%", minHeight:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <img width="80" src={loaderIcon} alt="Loading..." />   
        </div>
    )
}

export default Loader;