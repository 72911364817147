import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import config from "../../../config";
import { Card, Grid } from "@mui/material";
import AddBox from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { set } from "date-fns";
import EditModal from "./editModal";
import { useNotify } from "../../../hooks/useNotify";

const Categories = () => {
  const { user } = useGlobalContext();
  const [allCategories, setallCategories] = useState([]);

  const [activeCategory, setActiveCategory] = useState({
    _id: null,
    Name: ""
  });
  const [activeSubCategory, setActiveSubCategory] = useState({
    _id: null,
    Name: ""
  });
  const [activeSubSubCategory, setActiveSubSubCategory] = useState({
    _id: null,
    Name: ""
  });
  const [activeSubSubSubCategory, setActiveSubSubSubCategory] = useState({
    _id: null,
    Name: ""
  });

  const { notify } = useNotify();

  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModalSubCategory, setShowModalSubCategory] = useState(false);
  const [showModalSubSubCategory, setShowModalSubSubCategory] = useState(false);
  const [showModalSubSubSubCategory, setShowModalSubSubSubCategory] =
    useState(false);

  const handleShowCategory = () => {
    setShowModalCategory(!showModalCategory);
  };
  const handleShowSubCategory = () => {
    setShowModalSubCategory(!showModalSubCategory);
  };
  const handleShowSubSubCategory = () => {
    setShowModalSubSubCategory(!showModalSubSubCategory);
  };
  const handleShowSubSubSubCategory = () => {
    setShowModalSubSubSubCategory(!showModalSubSubSubCategory);
  };

  const [action, setAction] = useState({
    type: "",
    title: ""
  });

  const handleAdd = (catType) => {
    switch (catType) {
      case "Category":
        setAction({ type: "add", title: "Ajouter une catégorie" });
        setActiveSubCategory({
          _id: null,
          Name: ""
        });

        setShowModalCategory(true);
        break;
      case "SubCategory":
        if (activeCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une catégorie avant d'ajouter une sous-catégorie"
          );
          return;
        }
        setActiveSubCategory({
          _id: null,
          Name: "",
          parentId: activeCategory._id
        });
        setAction({ type: "add", title: "Ajouter une sous-catégorie à" });
        setShowModalSubCategory(true);
        break;
      case "SubSubCategory":
        if (activeSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-catégorie avant d'ajouter une sous-sous-catégorie"
          );
          return;
        }
        setActiveSubSubCategory({
          _id: null,
          Name: "",
          parentId: activeSubCategory._id
        });
        setAction({ type: "add", title: "Ajouter une sous-sous-catégorie à" });
        setShowModalSubSubCategory(true);
        break;
      case "SubSubSubCategory":
        if (activeSubSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-sous-catégorie avant d'ajouter une sous-sous-sous-catégorie"
          );
          return;
        }
        setActiveSubSubSubCategory({
          _id: null,
          Name: "",
          parentId: activeSubSubCategory._id
        });

        setAction({
          type: "add",
          title: "Ajouter une sous-sous-sous-catégorie à"
        });
        setShowModalSubSubSubCategory(true);
        break;
      default:
        break;
    }
  };

  const handleDelete = (catType) => {
    switch (catType) {
      case "Category":
        if (activeCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une catégorie avant de pouvoir la supprimer."
          );
          return;
        }
        setAction({
          type: "delete",
          title: "Êtes-vous sûr de vouloir supprimer cette catégorie?"
        });
        setShowModalCategory(true);
        break;
      case "SubCategory":
        if (activeSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-catégorie avant de pouvoir la supprimer."
          );
          return;
        }
        setAction({
          type: "delete",
          title: "Êtes-vous sûr de vouloir supprimer cette sous-catégorie?"
        });
        setShowModalSubCategory(true);
        break;
      case "SubSubCategory":
        if (activeSubSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-catégorie avant de pouvoir la supprimer."
          );
          return;
        }
        setAction({
          type: "delete",
          title: "Êtes-vous sûr de vouloir supprimer cette sous-sous-catégorie?"
        });
        setShowModalSubSubCategory(true);
        break;
      case "SubSubSubCategory":
        if (activeSubSubSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-catégorie avant de pouvoir la supprimer."
          );
          return;
        }
        setAction({
          type: "delete",
          title:
            "Êtes-vous sûr de vouloir supprimer cette sous-sous-sous-catégorie?"
        });
        setShowModalSubSubSubCategory(true);
        break;
      default:
        break;
    }
  };

  const handleDoubleClick = (catType) => {
    switch (catType) {
      case "Category":
        if (activeCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une catégorie avant de pouvoir la modifier."
          );
          return;
        }
        setActiveCategory({
          _id: activeCategory._id,
          Name: activeCategory.Name
        });
        setAction({ type: "edit", title: "Modifier la catégorie" });
        setShowModalCategory(true);
        break;
      case "SubCategory":
        if (activeSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-catégorie avant de pouvoir la modifier."
          );
          return;
        }
        setActiveSubCategory({
          _id: activeSubCategory._id,
          Name: activeSubCategory.Name,
          parentId: activeCategory._id
        });
        setAction({ type: "edit", title: "Modifier la sous-catégorie" });
        setShowModalSubCategory(true);
        break;
      case "SubSubCategory":
        if (activeSubSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-sous-catégorie avant de pouvoir la modifier."
          );
          return;
        }
        setActiveSubSubCategory({
          _id: activeSubSubCategory._id,
          Name: activeSubSubCategory.Name,
          parentId: activeSubCategory._id
        });
        setAction({ type: "edit", title: "Modifier la sous-sous-catégorie" });
        setShowModalSubSubCategory(true);
        break;
      case "SubSubSubCategory":
        if (activeSubSubSubCategory._id === null) {
          notify(
            "error",
            "Veuillez sélectionner une sous-sous-sous-catégorie avant de pouvoir la modifier."
          );
          return;
        }
        setActiveSubSubSubCategory({
          _id: activeSubSubSubCategory._id,
          Name: activeSubSubSubCategory.Name,
          parentId: activeSubSubCategory._id
        });
        setAction({
          type: "edit",
          title: "Modifier la sous-sous-sous-catégorie"
        });
        setShowModalSubSubSubCategory(true);
        break;
      default:
        break;
    }
  };
  const fetchAllCategories = async () => {
    const response = await fetch(`${config.BASE_URL}/api/categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`
      }
    });
    const data = await response.json();
    console.log(data);
    setallCategories(data);
  };

  useEffect(() => {
    if(user) fetchAllCategories();
  }, [user]);

  const handleClick = (e, categoryType) => {
    console.log("Just click ", e.target.getAttribute("data"));

    switch (categoryType) {
      case "Category":
        setActiveCategory({
          _id: e.target.getAttribute("data"),
          Name: e.target.textContent
        });
        setActiveSubCategory({
          _id: null,
          Name: ""
        });
        setActiveSubSubCategory({
          _id: null,
          Name: ""
        });
        setActiveSubSubSubCategory({
          _id: null,
          Name: ""
        });

        break;
      case "SubCategory":
        setActiveSubCategory({
          _id: e.target.getAttribute("data"),
          Name: e.target.textContent,
          parentId: activeCategory._id
        });

        setActiveSubSubCategory({
          _id: null,
          Name: ""
        });
        setActiveSubSubSubCategory({
          _id: null,
          Name: ""
        });
        break;
      case "SubSubCategory":
        setActiveSubSubCategory({
          _id: e.target.getAttribute("data"),
          Name: e.target.textContent,
          parentId: activeSubCategory._id
        });
        setActiveSubSubSubCategory({
          _id: null,
          Name: ""
        });
        break;
      case "SubSubSubCategory":
        setActiveSubSubSubCategory({
          _id: e.target.getAttribute("data"),
          Name: e.target.textContent,
          parentId: activeSubSubCategory._id
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="sub-header">
        <div className="text">
          <div className="title">Catégories</div>
          <div className="sub-title">
            Ajouter, modifier ou supprimer des catégories.
          </div>
        </div>
      </div>

      <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Catégorie</div>
              <div className="content">
                {allCategories.map((category) => {
                  if (!category.parentId) {
                    return (
                      <div
                        onClick={(e) => handleClick(e, "Category")}
                        onDoubleClick={() => handleDoubleClick("Category")}
                        data={category._id}
                        className={
                          activeCategory._id === category._id
                            ? "tag active"
                            : "tag"
                        }
                        key={category._id}
                      >
                        {category.Name}
                      </div>
                    );
                  }
                })}
              </div>
              <div className="footer">
                <div
                  className="btn"
                  title="Ajouter une catégorie"
                  onClick={() => handleAdd("Category")}
                >
                  <AddBox />
                </div>

                <div
                  className="btn"
                  title="Supprimer une catégorie"
                  onClick={() => handleDelete("Category")}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Sous-catégorie 1</div>
              <div className="content">
                {activeCategory._id &&
                  allCategories.map((category) => {
                    if (category.parentId === activeCategory._id) {
                      return (
                        <div
                          onClick={(e) => handleClick(e, "SubCategory")}
                          onDoubleClick={() => handleDoubleClick("SubCategory")}
                          data={category._id}
                          className={
                            activeSubCategory._id === category._id
                              ? "tag active"
                              : "tag"
                          }
                          key={category._id}
                        >
                          {category.Name}
                        </div>
                      );
                    }
                  })}
              </div>
              <div className="footer">
                <div
                  className="btn"
                  title="Ajouter une sous-catégorie 1"
                  onClick={() => handleAdd("SubCategory")}
                >
                  <AddBox />
                </div>

                <div
                  className="btn"
                  title="Supprimer une catégorie"
                  onClick={() => handleDelete("SubCategory")}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Sous-catégorie 2</div>
              <div className="content">
                {activeSubCategory._id &&
                  allCategories.map((category) => {
                    if (category.parentId === activeSubCategory._id) {
                      return (
                        <div
                          onClick={(e) => handleClick(e, "SubSubCategory")}
                          onDoubleClick={() =>
                            handleDoubleClick("SubSubCategory")
                          }
                          data={category._id}
                          className={
                            activeSubSubCategory._id === category._id
                              ? "tag active"
                              : "tag"
                          }
                          key={category._id}
                        >
                          {category.Name}
                        </div>
                      );
                    }
                  })}
              </div>
              <div className="footer">
                <div
                  className="btn"
                  title="Ajouter une sous-catégorie 2"
                  onClick={() => handleAdd("SubSubCategory")}
                >
                  <AddBox />
                </div>

                <div
                  className="btn"
                  title="Supprimer une catégorie"
                  onClick={() => handleDelete("SubSubCategory")}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Sous-catégorie 3</div>
              <div className="content">
                {activeSubSubCategory._id &&
                  allCategories.map((category) => {
                    if (category.parentId === activeSubSubCategory._id) {
                      return (
                        <div
                          onClick={(e) => handleClick(e, "SubSubSubCategory")}
                          onDoubleClick={() =>
                            handleDoubleClick("SubSubSubCategory")
                          }
                          data={category._id}
                          className={
                            activeSubSubSubCategory._id === category._id
                              ? "tag active"
                              : "tag"
                          }
                          key={category._id}
                        >
                          {category.Name}
                        </div>
                      );
                    }
                  })}
              </div>

              <div className="footer">
                <div
                  className="btn"
                  title="Ajouter une sous-catégorie 3"
                  onClick={() => handleAdd("SubSubSubCategory")}
                >
                  <AddBox />
                </div>

                <div
                  className="btn"
                  title="Supprimer une catégorie"
                  onClick={() => handleDelete("SubSubSubCategory")}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <EditModal
        show={showModalCategory}
        handleClose={handleShowCategory}
        data={activeCategory}
        setDataList={setallCategories}
        action={action}
        from="categories"
      />
      <EditModal
        show={showModalSubCategory}
        handleClose={handleShowSubCategory}
        data={activeSubCategory}
        setDataList={setallCategories}
        action={action}
        from="categories"
        chemin={activeCategory.Name}
      />
      <EditModal
        show={showModalSubSubCategory}
        handleClose={handleShowSubSubCategory}
        data={activeSubSubCategory}
        setDataList={setallCategories}
        action={action}
        from="categories"
        chemin={activeCategory.Name + " > " + activeSubCategory.Name}
      />
      <EditModal
        show={showModalSubSubSubCategory}
        handleClose={handleShowSubSubSubCategory}
        data={activeSubSubSubCategory}
        setDataList={setallCategories}
        action={action}
        from="categories"
        chemin={
          activeCategory.Name +
          " > " +
          activeSubCategory.Name +
          " > " +
          activeSubSubCategory.Name
        }
      />
    </div>
  );
};
export default Categories;
