
import SearchIcon from '@mui/icons-material/Search';
const SearchBar = ({ search, setSearch }) => {
    return (
        <div className="search-bar">
        <SearchIcon style={{fontSize:"20px"}} />
        
        <input
            type="text"
            placeholder="Rechercher"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
        />
        </div>
    );
    }
    export default SearchBar;