import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";
//import { BallTriangle } from "react-loader-spinner";
import config from "../../config";
import { Container, Grid } from "@mui/material";
import "./clients.css";
import Loader from "../../components/loader";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ClientInfos from "./components/clientInfos";
import MyProjects from "../projects/components/myProjects";
import MyOrders from "../orders/components/myOrders";
import MyInvoices from "../invoices/components/myInvoices";
import ClientEdit from "./components/clientEdit";

const DetailsClient = () => {
  const params = useParams();
  const { id } = params;
  const { user } = useGlobalContext();
  const { notify } = useNotify();
  const [isLoading, setIsloading] = useState(true);
  const [newClient, setNewClient] = useState();
  const [myProjets, setMyProjets] = useState();

  const [activeClient, setActiveClient] = useState();
  const [editing, setEditing] = useState(false);

  const getMyProjets = async () => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/projects/Client/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch projets");
      }
      const json = await response.json();
      if (json.length != 0) {
        setMyProjets(json);
      }
    } catch (error) {
      notify("error", error.message); // Set error message in state variable
    }
    setIsloading(false);
  };

  
  const handleSaveClient = async () => {
    console.log("save User");
    console.log("Modifier ", newClient);
  
    try {
      const saveClient = await fetch(
        `${config.BASE_URL}/api/clients/save/${newClient._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          },
          body: JSON.stringify(newClient)
        }
      );
  
      if (saveClient.ok) {
        const json = await saveClient.json();
        setEditing(false);
        setActiveClient(json);
        notify("success", "Client information saved successfully");
      } else {
        const errorMessage = await saveClient.json();
        console.error("Failed to save user information:", errorMessage.error);
        notify("error", errorMessage.error);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      notify("error", "An error occurred while saving client information");
    }
  };
  
  const fetchClient = async () => {
    setIsloading(true);
    const response = await fetch(`${config.BASE_URL}/api/clients/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
    const json = await response.json();

    if (response.ok) {
      setActiveClient(json);
      setNewClient(json);
      getMyProjets();
    }
    if (!response.ok) {
      notify("error", json.error);
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (user) fetchClient();

    window.scrollTo(0, 0);
  }, [id, user]);

  return (
    <div className="main-content">
      {isLoading && (
        <Container maxWidth="lg" style={{ height: "100%" }}>
          <Loader />
        </Container>
      )}
      {!isLoading && (
        <Container maxWidth="lg">
          <div className="header">
            <div className="text">
              <div className="title">Client</div>
              <div className="sub-title">Détails du client et ses projets.</div>
            </div>
            {!editing ? (
              <div style={{ display: "flex" }}>
                <div
                  className="add-icon"
                  title="Modifier le client"
                  onClick={() => setEditing(true)}
                >
                  <EditIcon />
                </div>
                <div className="add-icon" title="Archiver le client">
                  <ArchiveIcon />
                </div>
                <div className="add-icon" title="Supprimer le client">
                  <DeleteOutlineIcon />
                </div>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <div className="add-icon" title="Enregistrer le client"
                onClick={() => handleSaveClient()}
                >
                  <SaveIcon />
                </div>
                <div
                  className="add-icon"
                  title="Annuler"
                  onClick={() => setEditing(false)}
                >
                  <CancelIcon />
                </div>
              </div>
            )}
          </div>
          <div className="details">
            {!editing && (
              <ClientInfos client={activeClient} editing={editing} />
            )}
            {editing && (
              <ClientEdit
                newClient={newClient}
                setNewClient={setNewClient}
                editing={editing}
              />
            )}
          </div>

          <MyProjects projects={myProjets} />

          <MyOrders client={activeClient} />
          <MyInvoices />
        </Container>
      )}
    </div>
  );
};

export default DetailsClient;
