import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import ProjectCard from "./projectCard";

const MyProjects = ({ projects }) => {

  return (
    <div>
      <div className="header">
        <div className="text">
          <div className="title">Projets</div>
          <div className="sub-title">{projects && projects.length ? projects.length : 0} projet{projects && projects.length > 1 ? "s" : ""}</div>
        </div>
        <AddBoxIcon className="add-icon" />
      </div>
      { projects && projects.length > 0 ? (
        <Grid container 
        style={{ margin: "20px"}}>
          {projects &&
            projects.map((projet) => {
              return <ProjectCard project={projet} key={projet._id} />;
            })}
        </Grid>
      ) : (
        <div style={{ textAlign: "center", margin: "30px" }}>
          Aucun Projet
        </div>
      )}
      
    </div>
  );
};
export default MyProjects;
