import { useState } from "react";
import { useGlobalContext } from "../hooks/useGlobalContext";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { useNotify } from "./useNotify";


export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { userDispatch } = useGlobalContext();
  const { notify } = useNotify();

  const navigate = useNavigate();

  const login = async (userEmail, userPassword) => {
    setIsLoading(true);
    setError(null);

    const response = await fetch(`${config.BASE_URL}/api/user/login`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ userEmail, userPassword }),
    });

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.error);
      notify("error", json.error)
    }
    if (response.ok) {
      // Save to local storage

      if (json.userVerified) {
        localStorage.setItem("user", JSON.stringify(json));
        userDispatch({ type: "LOGIN", payload: json });
        setIsLoading(false);
        console.log("User", json);
        navigate("/");

      } else {
        const notUser = {
          email: json.userEmail,
          password: json.userPassword,
        };

        navigate("/NotVerified");
        localStorage.setItem("token", JSON.stringify(json.token));
        localStorage.setItem("notUser", notUser);
      }
    }
  };

  return { login, isLoading, error };
};
