import { createContext } from 'react'

export const OrderContext = createContext()

export const orderReducer = (state, action) => {
    switch(action.type){
        case 'SET_ORDER':
            return {
                order: action.payload
            }
        case 'DELETE_ORDER':
            return {
                
                order:null
            }   

        case 'REMOVE_ITEM_FROM_ORDER':
            console.log("Removing Item", action.payload)
            //If length of order.Produits = 1 delete Item
            if(state.order.Produits.length === 1){
                return {
                    
                }
            }

            return {
                order: action.payload
            }

        case 'ADD_ITEM_TO_ORDER':



            return {
                order: action.payload
            }
        case 'DELETE_ITEM_FROM_ORDER':
            console.log("Deleting Item", action.payload)
        return {
        order: action.payload
       }
       

                
        default:
            return state
    }
}