import { useEffect, useState } from "react";
import "../../products.css";
import "../settings.css";
import CreateCategory from "./createCategory";
import DeleteCategory from "./deleteCategory";
import CreateSubCategory from "./CreateSubCategory";
import { useGlobalContext } from "../../../../hooks/useGlobalContext";
import {
  Grid,
  Card,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import config from "../../../../config";

const CategoriesCheckboxes = ({
  checkedCategories,
  setCheckedCategories,
  setProduct
}) => {
  const { categories, categoriesDispatch } = useGlobalContext();

  /// Creer la liste des categories
  const fetchCategories = async () => {
    const response = await fetch(`${config.BASE_URL}/api/categories`);
    const json = await response.json();
    categoriesDispatch({ type: "SET_CATEGORIES", payload: json });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [activeCategory, setActiveCategory] = useState({
    name: null,
    _id: null
  });
  const [activeSubCategory, setActiveSubCategory] = useState({
    name: null,
    _id: null
  });
  const [activeSubSubCategory, setActiveSubSubCategory] = useState({
    name: null,
    _id: null
  });

  const handleDivClick = (e) => {
    const catType =
      e.target.getAttribute("cattype") ||
      e.currentTarget.getAttribute("cattype");
    const catName =
      e.target.getAttribute("nom") || e.currentTarget.getAttribute("nom");
    const catID = e.currentTarget.getAttribute("id");

    if (catType === "1") {
      if (activeCategory._id !== catID) {
        setActiveSubCategory({ name: null, _id: null });
        setActiveSubSubCategory({ name: null, _id: null });
        setActiveCategory({ name: catName, _id: catID });
      }
    } else if (catType === "2") {
      if (activeSubCategory._id !== catID) {
        setActiveSubSubCategory({ name: null, _id: null });
        setActiveSubCategory({ name: catName, _id: catID });
      }
    } else if (catType === "3") {
      if (activeSubSubCategory._id !== catID) {
        setActiveSubSubCategory({ name: catName, _id: catID });
      }
    }
  };

  const handleSelect = (category, isChecked) => {
    const currentCategory = {
      name: category.Name,
      _id: category._id,
      parentId: category.parentId || null
    };

    if (isChecked) {
      setCheckedCategories((prevCategories) => [
        ...prevCategories,
        currentCategory
      ]);
      let parentId = currentCategory.parentId;
      while (parentId) {
        let parentCategory = categories.find(
          (category) => category._id === parentId
        );
        if (
          !checkedCategories.find(
            (category) => category._id === parentCategory._id
          )
        ) {
          setCheckedCategories((prevCategories) => [
            ...prevCategories,
            parentCategory
          ]);
        }
        parentId = parentCategory.parentId;
      }
    } else {
      setCheckedCategories((prevCategories) =>
        prevCategories.filter(
          (category) => category._id !== currentCategory._id
        )
      );
      const removeChildCategories = (categoryId) => {
        const childCategories = categories.filter(
          (cat) => cat.parentId === categoryId
        );
        childCategories.forEach((childCategory) => {
          setCheckedCategories((prevCategories) =>
            prevCategories.filter(
              (category) => category._id !== childCategory._id
            )
          );
          removeChildCategories(childCategory._id);
        });
      };
      removeChildCategories(currentCategory._id);
    }
  };

  return (
    <Grid container spacing={3} style={{marginTop:"20px"}}>
      <Grid item xs={12} sm={6} md={4}>
        <Card className="card-list-settings">
          <div className="title">Catégorie</div>
          <div className="content">
            {categories &&
              categories.map((category) => {
                if (!category.parentId) {
                  return (
                    <div
                      className={
                        activeCategory._id === category._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={category._id}
                      id={category._id}
                      nom={category.Name}
                      cattype={1}
                      onClick={handleDivClick}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`label-${category._id}`}
                            checked={
                              !!checkedCategories.find(
                                (c) => c._id === category._id
                              )
                            }
                            onChange={(e) =>
                              handleSelect(category, e.target.checked)
                            }
                            data-id={category._id}
                            data-nom={category.Name}
                            parentid=""
                          />
                        }
                        label={category.Name}
                      />
                    </div>
                  );
                }
                return null;
              })}
          </div>
          <div className="footer">
            <CreateCategory title="Nouvelle catégorie" typeAction="Ajouter" />
            <DeleteCategory
              title="Supprimer une catégorie"
              id={activeCategory._id}
              nomSub="catégorie"
              nom={activeCategory.name}
            />
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <Card className="card-list-settings">
          <div className="title">Sous-catégories</div>
          <div className="content">
         
            {activeCategory._id &&
              categories.map((subCategory) => {
                if (subCategory.parentId === activeCategory._id) {
                  return (
                    <div
                      className={
                        activeSubCategory._id === subCategory._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={subCategory._id}
                      id={subCategory._id}
                      nom={subCategory.Name}
                      cattype={2}
                      onClick={handleDivClick}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`label-${subCategory._id}`}
                            checked={
                              !!checkedCategories.find(
                                (c) => c._id === subCategory._id
                              )
                            }
                            onChange={(e) =>
                              handleSelect(subCategory, e.target.checked)
                            }
                            data-id={subCategory._id}
                            data-nom={subCategory.Name}
                            parentid={subCategory.parentId}
                          />
                        }
                        label={subCategory.Name}
                      />
                    </div>
                  );
                }
                return null;
              })}
          </div>
          <div className="footer">
            <CreateSubCategory
              title="Nouvelle sous-catégorie"
              parentId={activeCategory._id}
              typeAction="Ajouter"
            />
            <DeleteCategory
              title="Supprimer une catégorie"
              id={activeSubCategory._id}
              nomSub="sous-catégorie"
            />
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <Card className="card-list-settings">
          <div className="title">Sous-catégories 2</div>
          <div className="content">
            {activeSubCategory._id &&
              categories.map((subSubCategory) => {
                if (subSubCategory.parentId === activeSubCategory._id) {
                  return (
                    <div
                      className={
                        activeSubSubCategory._id === subSubCategory._id
                          ? "inputCheck active"
                          : "inputCheck"
                      }
                      key={subSubCategory._id}
                      id={subSubCategory._id}
                      nom={subSubCategory.Name}
                      cattype={3}
                      parentid={subSubCategory.parentId}
                      onClick={handleDivClick}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`label-${subSubCategory._id}`}
                            checked={
                              !!checkedCategories.find(
                                (c) => c._id === subSubCategory._id
                              )
                            }
                            onChange={(e) =>
                              handleSelect(subSubCategory, e.target.checked)
                            }
                            data-id={subSubCategory._id}
                            data-nom={subSubCategory.Name}
                            parentid={subSubCategory.parentId}
                          />
                        }
                        label={subSubCategory.Name}
                      />
                    </div>
                  );
                }
                return null;
              })}
          </div>
          <div className="footer">
            <CreateSubCategory
              title="Nouvelle sous-catégorie"
              parentId={activeSubCategory._id}
              typeAction="Ajouter"
            />
            <DeleteCategory
              title="Supprimer une sous-catégorie"
              id={activeSubSubCategory._id}
              nomSub="sous-catégorie 2"
              nom={activeSubSubCategory.name}
            />
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CategoriesCheckboxes;
