
import "./search-box.css";

const SearchBox = ({setShowSearch, showSearch}) => {
    return (
        <div className="search-box"
        onMouseLeave={() => setShowSearch(false)}
        >
            
        </div>
    );
}

export default SearchBox;