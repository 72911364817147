import { Container } from "@mui/material";


const DashboardCompagnies = () => {
    return (
        <Container maxWidth="lg">
            <div className="header">
                <div className="text">
                    <div className="title">Companies</div>
                    <div className="sub-title">Résumé des compagnies.</div>
                </div>
            </div>
        </Container>
    )
    }
export default DashboardCompagnies;