import { createContext } from 'react'

export const SelectedCategoryContext = createContext()

export const selectedCategoryReducer = (state, action) => {
    switch(action.type){
        case 'SET_SELECTEDCATEGORIES':
            return {
                selectedCategories: action.payload
            }
        case 'CLEAR_SELECTEDCATEGORY':
                //console.log("Load un Produit")
                //console.log(action.payload)
            return {
                selectedCategories: []
                //categories:[action.payload]
                //products: state.products.filter((p) => p.id !== action.payload._id)
            }   
        case 'ADD_SELECTEDCATEGORY':
            console.log("Adding selected category")
            return {
                selectedCategories:[action.payload, ...state.selectedCategories]
            }
        case 'DELETE_SELECTEDCATEGORY':
            console.log("Deleting category")
            return {
                selectedCategories: state.selectedCategories.filter((c) => c._id !== action.payload)
            }
                
        default:
            return state
    }
}
