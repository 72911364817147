import React from 'react';
import { Grid, Switch } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import InventoryIcon from '@mui/icons-material/Inventory';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DomainIcon from '@mui/icons-material/Domain';
import VideocamIcon from '@mui/icons-material/Videocam';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactsIcon from '@mui/icons-material/Contacts';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Applications = ({ activeEmploye, setActiveEmploye, editing }) => {


   const handleApplicationSwitchChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'application') {
      // Toggle all applications based on the 'application' switch
      const newApplications = {
        application: checked,
        products: checked,
        kits: checked,
        orders: checked,
        customers: checked,
        employees: checked,
        invoices: checked,
        projects: checked,
        companies: checked,
        expenses: checked,
        Schedules: checked,
      };

      setActiveEmploye((prevEmploye) => ({
        ...prevEmploye,
        permissions: {
          ...prevEmploye.permissions,
          applications: newApplications,
        },
      }));
    } else {
      // Update individual application switch
      const newApplications = {
        ...activeEmploye.permissions.applications,
        [name]: checked,
      };

      // Determine if any application other than the master switch is on
      const anyApplicationOn = Object.keys(newApplications).some(
        (key) => key !== 'application' && newApplications[key]
      );

      setActiveEmploye((prevEmploye) => ({
        ...prevEmploye,
        permissions: {
          ...prevEmploye.permissions,
          applications: {
            ...newApplications,
            application: anyApplicationOn,
          },
        },
      }));
    }
  };


  const applications = {
    application: false,
    products: false,
    kits: false,
    orders: false,
    customers: false,
    employees: false,
    invoices: false,
    projects: false,
    companies: false,
    expenses: false,
    Schedules: false,
    ...activeEmploye.permissions.applications,
  };

  return (
    <Grid item xs={12} sm={6} md={4} style={{ marginBottom: '10px' }}>
      <div className="small-header">
        <div className="title" style={{ display: 'flex', alignItems: 'center' }}>
          <AppsIcon style={{ color: '#f3f3f3', marginRight: '5px' }} />
          <div>Applications</div>
        </div>
        {editing && (
        <div>
        <Switch
          name="application"
          checked={applications.application}
          onChange={handleApplicationSwitchChange}
        />
      </div>
    )}
        
      </div>
      {[
        { name: 'products', label: 'Produits', Icon: InventoryIcon },
        { name: 'kits', label: 'Ensembles', Icon: HomeRepairServiceIcon },
        { name: 'customers', label: 'Clients', Icon: FolderSharedIcon },
        { name: 'companies', label: 'Compagnies', Icon: DomainIcon },
        { name: 'projects', label: 'Projets', Icon: VideocamIcon },
        { name: 'orders', label: 'Commandes', Icon: LocalShippingIcon },
        { name: 'invoices', label: 'Factures', Icon: ReceiptIcon },
        { name: 'employees', label: 'Employés', Icon: ContactsIcon },
        { name: 'expenses', label: 'Dépenses', Icon: AttachMoneyIcon },
        { name: 'Schedules', label: 'Horaires', Icon: CalendarMonthIcon },
      ].map(({ name, label, Icon }) => (
        <div
          key={name}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginTop: '10px',
          }}
        >
          <div className="title" style={{ display: 'flex', alignItems: 'center' }}>
            <Icon style={{ marginRight: '5px' }} />
            <div>{label}</div>
          </div>
          {editing ? (
            <div>
              <Switch
                name={name}
                checked={applications[name]}
                onChange={handleApplicationSwitchChange}
              />
            </div>
          ) : (
            <div>{applications[name] ? 'ON' : 'OFF'}</div>
          )}  
        </div>
      ))}
    </Grid>
  );
};

export default Applications;
