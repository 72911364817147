import { Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ProjectIcon from "./projectIcon";
import { useEffect, useState } from "react";
import "../card.css"

const ProjectCard = ({ project }) => {
  const [projectStatus, setProjectStatus] = useState("");
  const startDate = new Date(project.Start);
  const endDate = new Date(project.End);

  const getStatus = () => {
    const today = new Date();

    if (today < startDate) {
      setProjectStatus("À venir");
    } else if (today > endDate) {
      setProjectStatus("Terminé");
    } else {
      setProjectStatus("En cours");
    }
  };

  useEffect(() => {
    getStatus();
  }, [project]);

  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      lg={2}
      style={{ padding: "5px", marginTop: "0px" }}
    >
      <Link className="noDecoration" to={`/projets/${project._id}`}>
        <Card className="card-project">
          <ProjectIcon project={project} editing={false} />
          <div className="card-title">{project.Nom}</div>
          <div
            className="card-subtitle"
            style={{ marginLeft: "5px", textTransform: "capitalize" }}
          >
            {project.projectType.Name}
          </div>

          <div
            className="card-subtitle"
            style={{ marginLeft: "5px", textTransform: "capitalize" }}
          >
            {project.Compagnie.companyName}
          </div>
          <div
            className="card-subtitle"
            style={{ marginLeft: "5px", textTransform: "capitalize" }}
          >
            {projectStatus}
          </div>
        </Card>
      </Link>
    </Grid>
  );
};
export default ProjectCard;
