import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Grid,
  Select,
  TextField,
  MenuItem,
  Switch
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import config from "../../../config";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import EditModal from "./editModal";

const ModifyUserInfo = ({ client, setClient, handleInputChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeJob, setActiveJob] = useState({
    _id: "",
    Name: "",
    Client: false
  });

  const [action, setAction] = useState({
    type: "",
    title: ""
  });

  const generateRandomCodeSecurity = () => {
    // Generate 4 numbers between 0 and 9 and set the digit 1 to 4 of the codeSecurity
    let digit1 = Math.floor(Math.random() * 10);
    let digit2 = Math.floor(Math.random() * 10);
    let digit3 = Math.floor(Math.random() * 10);
    let digit4 = Math.floor(Math.random() * 10);

    setClient({
      ...client,
      codeSecurity: {
        digit1,
        digit2,
        digit3,
        digit4
      }
    });
  };

  const [age, setAge] = useState(0);

  const [postes, setPostes] = useState([]);

  const { user } = useGlobalContext();

  useEffect(() => {
    if (client.user.userBirthDate) {
      calculateAge(dayjs(client.user.userBirthDate));
    }
  }, [client.user.userBirthDate]);

  const calculateAge = (birthday) => {
    const birthDate = birthday.toDate();
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    setAge(Math.abs(ageDate.getUTCFullYear() - 1970));
  };

  const fetchPostes = useCallback(async () => {
    if (!user) return; // Add check to ensure user is defined

    const response = await fetch(`${config.BASE_URL}/api/postes`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });

    let json = await response.json();

    // Filter out poste.Client = true
    json = json.filter((poste) => poste.Client === false);

    setPostes(json);
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchPostes();
    }
    if (!client.codeSecurity) {
      generateRandomCodeSecurity();
    }
  }, [user, fetchPostes]);

  const addAddress = () => {
    const newAddress = {
      name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      telephone: ""
    };

    setClient({
      ...client,
      user: {
        ...client.user,
        userAddresses: [...client.user.userAddresses, newAddress]
      }
    });
  };

  const handleChange = (e) => {
    setClient({
      ...client,
      user: { ...client.user, [e.target.name]: e.target.value }
    });
  };
  const handleChangeEmploye = (e) => {
    setClient({
      ...client,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeSalary = (e) => {
    setClient({
      ...client,
      salaire: {
        ...client.salaire,
        [e.target.name]: e.target.value
      }
    });
  };

  const deleteAddress = (index) => {
    const updatedAddresses = [...client.user.userAddresses];
    updatedAddresses.splice(index, 1);

    setClient({
      ...client,
      user: { ...client.user, userAddresses: updatedAddresses }
    });
  };

  const handleShow = () => {
    setShowModal(!showModal);
  };

  const handleAdd = (jopType) => {
    if (jopType === "employe") {
      setActiveJob({
        _id: "",
        Name: "",
        Client: false
      });
      setAction({ type: "add", title: "Ajouter un type de poste d'employé" });
      handleShow();
    }
  };

  if (!user) {
    return <div>Loading...</div>; // Render a loading state if user is not defined
  }

  return (
    <div
      style={{
        paddingBottom: "20px",
        paddingRight: "20px",
        paddingLeft: "20px"
      }}
    >
      <TextField
        label="Prénom"
        variant="outlined"
        name="userFirstName"
        style={{ width: "100%" }}
        onChange={handleChange}
        value={client.user.userFirstName || ""}
      />
      <TextField
        label="Nom de famille"
        variant="outlined"
        name="userLastName"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={client.user.userLastName || ""}
      />
      <div style={{ display: "flex" }}>
        <Select
          label="Poste"
          variant="outlined"
          name="poste"
          style={{ width: "100%", marginTop: "15px" }}
          onChange={(e) => {
            setClient({
              ...client,
              poste: e.target.value
            });
          }}
          value={client.poste || ""}
        >
          {postes.map((poste) => (
            <MenuItem key={poste._id} value={poste._id}>
              {poste.Name}
            </MenuItem>
          ))}
        </Select>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "5px",
            marginTop: "15px"
          }}
          title="Ajouter un poste"
          onClick={() => {
            handleAdd("employe");
          }}
        >
          <AddBoxIcon className="add-poste" style={{ fontSize: "25px" }} />
        </div>
      </div>
      <TextField
        label="Courriel"
        variant="outlined"
        name="userEmail"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={client.user.userEmail || ""}
      />
      <TextField
        label="Cellulaire"
        variant="outlined"
        name="userCell"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={client.user.userCell || ""}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ marginTop: "15px" }}>
            <DatePicker
              label="Date de naissance"
              value={
                client.user.userBirthDate
                  ? dayjs(client.user.userBirthDate)
                  : null
              }
              onChange={(newValue) => {
                setClient({
                  ...client,
                  user: { ...client.user, userBirthDate: newValue }
                });
                calculateAge(dayjs(newValue));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: "100%", marginTop: "15px" }}
                />
              )}
            />
          </div>
          <div
            style={{ marginLeft: "20px", marginTop: "15px", fontSize: "18px" }}
          >
            {age !== 0 ? age + " ans" : ""}
          </div>
        </div>
      </LocalizationProvider>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginTop: "15px" }}>
              <DatePicker
                label="Date de début"
                value={client.dateDebut ? dayjs(client.dateDebut) : null}
                onChange={(newValue) => {
                  setClient({
                    ...client,
                    dateDebut: newValue
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: "100%", marginTop: "15px" }}
                  />
                )}
              />
            </div>
          </div>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginTop: "15px" }}>
              <DatePicker
                label="Date de fin"
                value={client.dateFin ? dayjs(client.dateFin) : null}
                onChange={(newValue) => {
                  setClient({
                    ...client,
                    dateFin: newValue
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: "100%", marginTop: "15px" }}
                  />
                )}
              />
            </div>
          </div>
        </LocalizationProvider>
      </div>
      <TextField
        label="NAS"
        variant="outlined"
        name="NAS"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChangeEmploye}
        value={client.NAS || ""}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          label="Salaire $"
          variant="outlined"
          name="montant"
          style={{ width: "48%", marginTop: "15px" }}
          onChange={handleChangeSalary}
          value={client.salaire?.montant || ""}
        />
        <Select
          label="Type de salaire"
          name="method"
          style={{ width: "48%", marginTop: "15px" }}
          onChange={handleChangeSalary}
          value={client.salaire?.method || ""}
        >
          <MenuItem value="heure">heure</MenuItem>
          <MenuItem value="jour">jour</MenuItem>
          <MenuItem value="semaine">semaine</MenuItem>
          <MenuItem value="année">année</MenuItem>
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "15px"
        }}
      >
        <Select
          label="Type d'horaire"
          name="typeHoraire"
          style={{ width: "100%" }}
          onChange={handleChangeEmploye}
          value={client.typeHoraire || ""}
        >
          <MenuItem value="temps plein">Temps plein</MenuItem>
          <MenuItem value="temps partiel">Temps partiel</MenuItem>
        </Select>
      </div>
     
      {client.salaire?.method === "jour" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px"
          }}
        >
          <TextField
            label="Nombre de jour par semaine."
            variant="outlined"
            name="nombreEstime"
            style={{ width: "100%" }}
            onChange={handleChangeEmploye}
            value={client.nombreEstime || ""}
          />
        </div>
      ) :

      (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px"
          }}
        >
          <TextField
            label="Nombre d'heures par semaine."
            variant="outlined"
            name="nombreEstime"
            style={{ width: "100%" }}
            onChange={handleChangeEmploye}
            value={client.nombreEstime || ""}
          />
        </div>
      )
    
    }
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "15px"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          Administrateur{" "}
          <div
            style={{ fontSize: "12px", marginLeft: "3px", marginRight: "3px" }}
          >
            {" "}
            (affiche les montants){" "}
          </div>{" "}
          :
        </div>
        <Switch
          size="small"
          checked={client.user.isAdmin || false}
          onChange={(e) =>
            setClient({
              ...client,
              user: { ...client.user, isAdmin: e.target.checked }
            })
          }
          name="isAdmin"
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "15px"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          Envoyer un courriel de bienvenu :{" "}
          
        </div>
        <Switch
          size="small"
          checked={client.envoyerEmail || false}
          onChange={(e) =>
            setClient({
              ...client,
             envoyerEmail: e.target.checked
            })
          }
          name="envoyerEmail"
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ marginRight: "10px" }}>Code de sécurité (mode iPad)</div>
        <div className="codeSecurityDigit">
          {client.codeSecurity && client.codeSecurity.digit1}
        </div>
        <div className="codeSecurityDigit">
          {client.codeSecurity && client.codeSecurity.digit2}
        </div>
        <div className="codeSecurityDigit">
          {client.codeSecurity && client.codeSecurity.digit3}
        </div>
        <div className="codeSecurityDigit">
          {client.codeSecurity && client.codeSecurity.digit4}
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          className="btn-primary"
          variant="contained"
          onClick={generateRandomCodeSecurity}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
            marginTop: "20px"
          }}
        >
          Générer un nouveau code de sécurité
        </Button>
      </div>
      <div className="small-header">
        <div className="text">
          <div className="title">Adresses</div>
        </div>
        <div title="Ajouter une adresse" onClick={addAddress}>
          <AddBoxIcon className="add-icon" style={{ fontSize: "25px" }} />
        </div>
      </div>
      {client.user.userAddresses &&
        client.user.userAddresses.map((address, index) => (
          <div key={index}>
            <TextField
              label="Étiquette"
              variant="outlined"
              name={`userAddresses.${index}.label`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.label || ""}
            />
            <TextField
              label="Adresse"
              variant="outlined"
              name={`userAddresses.${index}.address`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.address || ""}
            />
            <Grid container>
              <Grid item xs={12} md={6} style={{ marginTop: "15px" }}>
                <TextField
                  label="Ville"
                  variant="outlined"
                  name={`userAddresses.${index}.city`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.city || ""}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "15px" }}>
                <TextField
                  label="Province"
                  variant="outlined"
                  name={`userAddresses.${index}.state`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.state || ""}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                className="mt-2"
                style={{ marginTop: "15px" }}
              >
                <TextField
                  label="Pays"
                  variant="outlined"
                  name={`userAddresses.${index}.country`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.country || ""}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "15px" }}>
                <TextField
                  label="Code postal"
                  variant="outlined"
                  name={`userAddresses.${index}.postalCode`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.postalCode || ""}
                />
              </Grid>
            </Grid>
            <TextField
              label="Téléphone"
              variant="outlined"
              name={`userAddresses.${index}.telephone`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.telephone || ""}
            />
            {index !== 0 && (
              <Button
                className="btn-primary"
                variant="contained"
                onClick={() => deleteAddress(index)}
                style={{
                  width: "100%",
                  fontSize: "12px",
                  padding: "5px 10px",
                  marginTop: "20px"
                }}
              >
                Retirer l'adresse
              </Button>
            )}
            <div className="separatorHorizontal"></div>
          </div>
        ))}
      <EditModal
        show={showModal}
        handleClose={handleShow}
        data={activeJob}
        setDataList={setPostes}
        action={action}
        from="jobs"
      />
    </div>
  );
};

export default ModifyUserInfo;
