import { Link } from "react-router-dom";
import "../card.css";

import { Card, Grid } from "@mui/material";

import greenDot from "../../../assets/images/green-dot.png";
import redDot from "../../../assets/images/red-dot.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import fakeIcon from "../../../assets/images/fake.png";

const ClientCard = ({ client }) => {

  console.log("Client Card client : ", client)

  return (
    
      <Link className="noDecoration" to={`/clients/${client._id}`}>
        <Card className="card">
          {client.fakeUser && <img src={fakeIcon} alt="" className="fake" />}
          <div className="img">
            <img src={client.avatar} alt="" />
          </div>
          <div className="card-title">
            {client.userFirstName} {client.userLastName}
          </div>
          <div className="card-subtitle">
            <PhoneIphoneIcon className="small-icon" /> {client.userCell}
          </div>
          <div className="card-subtitle">
            <EmailIcon className="small-icon" /> {client.userEmail}
          </div>
          <div>
            {client.userVerified && (
              <>
                <img src={greenDot} className="dot" alt="" />
                <span>Verified</span>
              </>
            )}
            {!client.userVerified && (
              <div
                style={{
                  fontSize: "12px",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <img src={redDot} className="dot" alt="" />
                <span style={{ marginLeft: "5px", fontSize: "11px" }}>
                  Not verified
                </span>
              </div>
            )}
          </div>
        </Card>
      </Link>
    
  );
};

export default ClientCard;
