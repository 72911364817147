
import "./desktop.css"

import decoLogo from "../../assets/images/deco_big.png"
import medicLogo from "../../assets/images/medic_big.png"
import { useEffect } from "react"
import { useGlobalContext } from "../../hooks/useGlobalContext"
import { useNavigate } from "react-router-dom"



const Desktop = () => {


    const {user} = useGlobalContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (!user) {
            navigate("/Login")
        }
    }, [user])

    return (
        <div className="desktop">
            <div className="logos">
                <img src={decoLogo} alt="Déco-Ciné" />
            </div>
        </div>
    )
    }
    export default Desktop;