import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import SearchBarMini from "./searchBarMini";
// import AddPoste from "./addPoste";
import config from "../../../config";
import { useNotify } from "../../../hooks/useNotify";
import ClientCardSidebar from "./clientCardSidebar";
import Loader from "../../../components/loader";
import { Button, Card, Select } from "@mui/material";
import EditModal from "../../settings/components/editModal";
import AddBoxIcon from "@mui/icons-material/AddBox";

const AddWorker = ({ setNewProject, newProject, setStateClientList }) => {
  const { user } = useGlobalContext();

  const { notify } = useNotify();

  const [isLoading, setIsLoading] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [search, setSearch] = useState("");

  const [filteredClients, setFilteredClients] = useState([]);

  const [selectedClient, setSelectedClient] = useState();

  const [newWorker, setNewWorker] = useState({
    client: "",
    fullName: "",
    poste: {
      Name: "",
      _id: ""
    }
  });

  const [showModal, setShowModal] = useState(false);

  const [action, setAction] = useState({
    type: "add",
    title: "Ajouter un type de poste de projet"
  });
  const [activeJobProject, setActiveJobProject] = useState({
    _id: "",
    Name: "",
    Client: true
  });

  const handleShow = () => {
    setShowModal(!showModal);
  };


  useEffect(() => {
    if (search) {
      // Let's search first name, last name,email and phone number
      const filteredClients = clientList.filter((client) => {
        return (
          client.userFirstName.toLowerCase().includes(search.toLowerCase()) ||
          client.userLastName.toLowerCase().includes(search.toLowerCase()) ||
          client.userEmail.toLowerCase().includes(search.toLowerCase()) ||
          client.userCell.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredClients(filteredClients);
    } else {
      setFilteredClients(clientList);
    }
  }, [search]);


  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    const element = document.getElementById("sideBarWorker");

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Filter the clientList based on the newProject.Equipe
    var filteredClients = [];

    if (clientList.length > 0) {
      filteredClients = clientList.filter((client) => {
        return !newProject.Equipe.some(
          (worker) => worker.client._id === client._id
        );
      });
    }

    setFilteredClients(filteredClients);
  }, [clientList]);

  const getPostes = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/postes/clients`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch clients");
      }
      const json = await response.json();
      setJobList(json);
    } catch (error) {
      notify("error", error.message);
    }
  };

  const getClientList = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/clients`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch clients");
      }
      const json = await response.json();
      setClientList(json.clients);
    } catch (error) {
      notify("error", error.message);
    }

    setIsLoading(false);
  };

  const ajouterEquipier = () => {
    
    console.log("New Projet", newProject);
    if (!newWorker.client) {
      notify("error", "Veuillez sélectionner un client svp.");
    } else if (newWorker.poste.Name === "") {
      notify("error", "Veuillez sélectionner un poste svp.");
    } else {
      setNewProject({
        ...newProject,
        Equipe: [...newProject.Equipe, newWorker]
      });
      setNewWorker({
        client: "",
        fullName: "",
        poste: {
          Name: "",
          _id: ""
        }
      });
      setStateClientList({ isPaneOpen: false });
    }
  };

  useEffect(() => {
    getPostes();
    getClientList();
  }, []);

  return (
    <div
      id="sideBarWorker"
      className="sidebarWorker"
      style={{ paddingBottom: "30px" }}
    >
      <div
        className="activeClient"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        {!newWorker.client && (
          <Card
            style={{
              width: "100%",
              height: "70px",
              borderRadius: "10px",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div>Sélectionner un client</div>
          </Card>
        )}
        {newWorker.client && selectedClient && (
          <ClientCardSidebar client={selectedClient} noOnClick={true} />
        )}
      </div>
      <div style={{ padding: "10px" }}>Poste *</div>
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div>
          <Select
            style={{ width: "100%" }}
            native
            value={newWorker.poste.Name}
              onChange={(e) => {
                setNewWorker({
                  ...newWorker,
                  poste: {
                    Name: e.target.value,
                    _id: e.target.options[e.target.selectedIndex].getAttribute(
                      "id"
                    ),
                  },
                });
                
              }}
          >
            <option value=""></option>
            {jobList &&
              jobList.map((job) => {
                return (
                  <option key={job._id} value={job.Name} id={job._id}>
                    {job.Name}
                  </option>
                );
              })}
          </Select>
        </div>
        <div>
          <AddBoxIcon onClick={handleShow} className="add-icon" />
        </div>
      </div>

      <div style={{ margin: "10px", width: "100%" }}>
        <Button
          className="btn-primary"
          style={{ width: "225px" }}
          onClick={() => {
            ajouterEquipier();
          }}
        >
          Ajouter
        </Button>
      </div>

      <div
        style={{ borderBottom: "#232f3e solid thick", margin: "10px" }}
      ></div>
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <SearchBarMini search={search} setSearch={setSearch} />
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            paddingTop: "30px",
            justifyContent: "center",
            justifyItems: "center"
          }}
        >
          <Loader />
        </div>
      )}
      {!isLoading && (
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          {filteredClients &&
            filteredClients.map((client) => {
              return (
                <ClientCardSidebar
                  key={client._id}
                  client={client}
                  newWorker={newWorker}
                  setNewWorker={setNewWorker}
                  scrollToTop={scrollToTop}
                  setSelectedClient={setSelectedClient}
                />
              );
            })}
        </div>
      )}
      <EditModal
        show={showModal}
        handleClose={handleShow}
        data={activeJobProject}
        setDataList={setJobList}
        action={action}
        from="jobs"
      />
    </div>
  );
};

export default AddWorker;
