import { useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { Card, Grid } from "@mui/material";
import AddBox from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditModal from "./editModal";
import { useNotify } from "../../../hooks/useNotify";

const Tags = () => {
  const { user } = useGlobalContext();

  const {notify} = useNotify()

  const [decors, setDecors] = useState([]);
  const [materiaux, setMateriaux] = useState([]);
  const [colors, setColors] = useState([]);
  const [caracteristics, setCaracteristics] = useState([]);
  const [activeDecor, setActiveDecor] = useState({
    Name: "",
    tagType: "Décor",
    _id: ""
  });
  const [activeMateriel, setActiveMateriel] = useState({
    Name: "",
    tagType: "Materiaux",
    _id: ""
  });
  const [activeColor, setActiveColor] = useState({
    Name: "",
    tagType: "Couleur",
    _id: ""
  });
  const [activeCaracteristic, setActiveCaracteristic] = useState({
    Name: "",
    tagType: "Caracteristique",
    _id: ""
  });
  const [action, setAction] = useState({
    type: "",
    title: ""
  });

  const [showModalDecor, setShowModalDecor] = useState(false);
  const [showModalMateriel, setShowModalMateriel] = useState(false);
  const [showModalColor, setShowModalColor] = useState(false);
  const [showModalCaracteristic, setShowModalCaracteristic] = useState(false);

  const handleShowDecor = () => {
    setShowModalDecor(!showModalDecor);
  };
  const handleShowMateriel = () => {
    setShowModalMateriel(!showModalMateriel);
  };
  const handleShowColor = () => {
    setShowModalColor(!showModalColor);
  };
  const handleShowCaracteristic = () => {
    setShowModalCaracteristic(!showModalCaracteristic);
  };

  const handleClick = (e, tagType) => {
    console.log(e.target.getAttribute("data"));

    switch (tagType) {
      case "Décor":
        setActiveDecor({
          Name: e.target.textContent,
          tagType: "Décor",
          _id: e.target.getAttribute("data")
        });

        break;
      case "Materiaux":
        setActiveMateriel({
          Name: e.target.textContent,
          tagType: "Materiaux",
          _id: e.target.getAttribute("data")
        });
        break;
      case "Couleur":
        setActiveColor({
          Name: e.target.textContent,
          tagType: "Couleur",
          _id: e.target.getAttribute("data")
        }
        );
        break;
      case "Caracteristique":
        setActiveCaracteristic({
          Name: e.target.textContent,
          tagType: "Caracteristique",
          _id: e.target.getAttribute("data")
        });
        break;
      default:
        break;
    }
  };

  const fetchAllTags = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/settings/tags`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        }
      });
      const data = await response.json();
      // filter tagType "Décors"
      setDecors(data.filter((tag) => tag.tagType === "Décor"));
      // filter tagType "Matériaux"
      setMateriaux(data.filter((tag) => tag.tagType === "Matériaux"));
      // filter tagType "Couleurs"
      setColors(data.filter((tag) => tag.tagType === "Couleur"));
      // filter tagType "Caractéristiques"
      setCaracteristics(
        data.filter((tag) => tag.tagType === "Caractéristique")
      );

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = (tagType) => {
    switch (tagType) {
      case "Décor":
        setAction({ type: "add", title: "Ajouter un décor" });
        setShowModalDecor(true);
        break;
      case "Materiaux":
        setAction({ type: "add", title: "Ajouter un matériaux" });
        setShowModalMateriel(true);
        break;
      case "Couleur":
        setAction({ type: "add", title: "Ajouter une couleur" });
        setShowModalColor(true);
        break;
      case "Caracteristique":
        setAction({ type: "add", title: "Ajouter une caractéristique" });
        setShowModalCaracteristic(true);
        break;
      default:
        break;
    }
  };


  const handleDelete = (tagType) => {
    switch (tagType) {
      case "Décor":
        setAction({ type: "delete", title: "Êtes-vous sûr de vouloir supprimer ce décor?" });
        setShowModalDecor(true);
        break;
      case "Materiaux":
        setAction({ type: "delete", title: "Êtes-vous sûr de vouloir supprimer ce matériaux?" });
        setShowModalMateriel(true);
        break;
      case "Couleur":
        setAction({ type: "delete", title: "Êtes-vous sûr de vouloir supprimer cette couleur?" });
        setShowModalColor(true);
        break;
      case "Caracteristique":
        setAction({ type: "delete", title: "Êtes-vous sûr de vouloir supprimer cette caractéristique?" });
        setShowModalCaracteristic(true);
        break;
      default:
        break;
    }
  };

  
  const handleDoubleClick = (e, tagType) => {
    switch (tagType) {
      case "Décor":
        setActiveDecor({
          _id:e.target.getAttribute("data"),
          Name: e.target.textContent,
          tagType: "Décor"
        });
        setAction({ type: "edit", title: "Modifier le décor" });
        setShowModalDecor(true);
        break;
      case "Materiaux":
        setActiveMateriel({
          _id:e.target.getAttribute("data"),
          Name: e.target.textContent,
          tagType: "Materiaux"
        });
        setAction({ type: "edit", title: "Modifier le matériaux" });
        setShowModalMateriel(true);
        break;
      case "Couleur":
        setActiveColor({
          _id:e.target.getAttribute("data"),
          Name: e.target.textContent,
          tagType: "Couleur"
        });
        setAction({ type: "edit", title: "Modifier la couleur" });
        setShowModalColor(true);
        break;
      case "Caracteristique":
        setActiveCaracteristic({
          _id:e.target.getAttribute("data"),
          Name: e.target.textContent,
          tagType: "Caracteristique"
        });
        setAction({ type: "edit", title: "Modifier la caractéristique" });
        setShowModalCaracteristic(true);
        break;

     
      default:
        break;
    }


    
  };



  useEffect(() => {
    if(user) fetchAllTags();
  }, [user]);

  useEffect(() => {
    console.log("active decor", activeDecor);
  }, [activeDecor]);

  return (
    <div>
      <div className="sub-header">
        <div className="text">
          <div className="title">Tags</div>
          <div className="sub-title">
            Ajouter, modifier ou supprimer des tags.
          </div>
        </div>
      </div>

      <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Décors</div>
              <div className="content">
                {decors.map((decor) => (
                  <div
                    className={activeDecor._id === decor._id ? "tag active" : "tag"}
                    key={decor._id}
                    data={decor._id}
                    onClick={(e) => handleClick(e, decor.tagType)}
                    onDoubleClick={(e) => handleDoubleClick(e, decor.tagType)}
                    
                    
                  >
                    {decor.Name}
                  </div>
                ))}
              </div>
              <div className="footer">
                <div
                  className="btn"
                  title="Ajouter un décor"
                  onClick={() => {
                    handleAdd("Décor");}}
                  
                >
                  <AddBox />
                </div>

                <div
                  className="btn"
                  title="Supprimer un décor"
                  onClick={() => {
                    if(activeDecor._id === "")
                    {
                      return notify("error", "Veuillez sélectionner un décor");
                    } 
                    handleDelete("Décor");
                  }}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Matériaux</div>
              <div className="content">
                {materiaux.map((materiel) => (
                  <div
                    className={
                      activeMateriel._id === materiel._id ? "tag active" : "tag"
                    }
                    key={materiel._id}
                    data={materiel._id}
                    onClick={(e) => handleClick(e, materiel.tagType)}
                    onDoubleClick={(e) => handleDoubleClick(e, materiel.tagType)}
                  >
                    {materiel.Name}
                  </div>
                ))}
              </div>
              <div className="footer">
                <div className="btn" title="Ajouter un matériaux"
                onClick={() => {
                  handleAdd("Materiaux");
                }
                }
                >
                  <AddBox />
                </div>

                <div className="btn" title="Supprimer un matériaux"
                onClick={() => {
                  if(activeMateriel._id === "") return notify("error", "Veuillez sélectionner un matériaux");
                  handleDelete("Materiaux");
                }}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Couleurs</div>
              <div className="content">
                {colors.map((color) => (
                  <div
                    className={activeColor._id === color._id ? "tag active" : "tag"}
                    key={color._id}
                    data={color._id}
                    onClick={(e) => handleClick(e, color.tagType)}
                    onDoubleClick={(e) => handleDoubleClick(e, color.tagType)}
                  >
                    {color.Name}
                  </div>
                ))}
              </div>
              <div className="footer">
                <div className="btn" title="Ajouter une couleur"
                onClick={() => {
                  handleAdd("Couleur");
                }}
                
                >
                  <AddBox />
                </div>

                <div className="btn" title="Supprimer une couleur"
                onClick={() => {
                  if(activeColor._id === "") return notify("error", "Veuillez sélectionner une couleur");
                  handleDelete("Couleur");
                }}
                
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Caractéristiques</div>
              <div className="content">
                {caracteristics.map((caracteristic) => (
                  <div
                    className={
                      activeCaracteristic._id === caracteristic._id
                        ? "tag active"
                        : "tag"
                    }
                    key={caracteristic._id}
                    data={caracteristic._id}
                    onClick={(e) => handleClick(e, caracteristic.tagType)}
                    onDoubleClick={(e) => handleDoubleClick(e, caracteristic.tagType)}
                  >
                    {caracteristic.Name}
                  </div>
                ))}
              </div>
              <div className="footer">
                <div className="btn" title="Ajouter une caractéristique"
                onClick={() => {
                  handleAdd("Caracteristique");
                }}
                
                >
                  <AddBox />
                </div>

                <div className="btn" title="Supprimer une caractéristique "
                onClick={() => {

                  if(activeCaracteristic._id === "") return notify("error", "Veuillez sélectionner une caractéristique");
                  handleDelete("Caracteristique");
                }
                }
                
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <EditModal
        show={showModalDecor}
        handleClose={handleShowDecor}
        data={activeDecor}
        setDataList={setDecors}
        action={action}
        from="tags"
      />
      <EditModal
        show={showModalColor}
        handleClose={handleShowColor}
        data={activeColor}
        setDataList={setColors}
        action={action}
        from="tags"
      />
      <EditModal
        show={showModalMateriel}
        handleClose={handleShowMateriel}
        data={activeMateriel}
        setDataList={setMateriaux}
        action={action}
        from="tags"
      />
      <EditModal
        show={showModalCaracteristic}
        handleClose={handleShowCaracteristic}
        data={activeCaracteristic}
        setDataList={setCaracteristics}
        action={action}
        from="tags"
      />
    </div>
  );
};
export default Tags;
