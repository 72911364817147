import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  BlobProvider
} from "@react-pdf/renderer";
import "./pdf.css";
import config from "../../../config";
import defaultImage from "../../../assets/images/no-image-icon.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import loader from "../../../assets/images/loading-gif.gif";

const styles = StyleSheet.create({
  pdfPage: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 10
  },
  section: {
    margin: 10,
    flexGrow: 1
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  myCompany: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    marginBottom: 20,
    marginLeft: 20
  },
  headerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "35%",
    marginBottom: 10,
    marginLeft: 20
  },
  title: {
    fontSize: 11,
    fontWeight: "bold",
    marginRight: "5px"
  },
  titleNumber: {
    fontSize: 11,
    fontWeight: "bold",
    width: "15px",
    textAlign: "center"
  },
  title2: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: "3px"
  },
  subtitle: {
    display: "flex",
    fontSize: 10,
    fontWeight: "normal"
  },
  subtitle2: {
    display: "flex",
    fontSize: 10,
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  article: {
    width: "96%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "2%",
    marginBottom: 10,
    paddingBottom: "3px",
    borderBottom: "1px solid #333"
  },
  subtitle3: {
    display: "flex",
    fontSize: 10,
    fontWeight: "bold"
  },
  subtitleLast: {
    display: "flex",
    fontSize: 10,
    marginBottom: 5
  },
  subtitleLast2: {
    display: "flex",
    fontSize: 10,
    marginBottom: 8
  },
  content: {
    fontSize: 12,
    marginBottom: 5
  },
  logo: {
    width: 120,
    marginBottom: 5
  },
  duration: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 3
  },
  column: {
    display: "flex",
    width: "30%",
    marginBottom: 8
  },
  number: {
    color: "#61dafb"
  },
  order: {
    fontSize: 12,
    marginBottom: 10,
    padding: 10,
    marginTop: 10,
    color: "#333",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  articles: {
    width: "96%",
    marginLeft: "2%"
  },
  line: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px",
    alignItems: "center"
  },
  container: {
    margin: "auto"
  },
  lineBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px",
    marginTop: 5,
    borderTop: "1px solid #333",
    fontSize: 12,
    fontWeight: "bold"
  },
  left: {
    display: "flex",
    flexDirection: "row"
  },
  smallImage: {
    width: "30px",
    marginRight: "5px"
  },

  imgContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 10,
    width: "96%",
    marginLeft: "2%"
  },
  bigImages: {
    pageBreakInside: "avoid",
    display: "flex",
    width: "32%",
    marginRight: "1%",
    marginTop: "50px",
    marginBottom: 5
  }
  

});

const formatDate = (date) => {
  const d = new Date(date);
  return d.toLocaleDateString("fr-CA", {
    day: "numeric",
    month: "numeric",
    year: "numeric"
  });
};

const PDFGenerator = ({ order, totalDays }) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [totalArticles, setTotalArticles] = useState(0);
  const [imagesList, setImagesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const date = new Date(order.createdAt);
    setFormattedDate(
      `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    );
  }, [order.createdAt]);

  useEffect(() => {
    let totalArticles = 0;
    order.Produits.forEach((product) => {
      totalArticles += product.quant;
    });
    setTotalArticles(totalArticles);
  }, [order.Produits]);

  useEffect(() => {
    fetchImages();
  }, [order.Produits]);

  const fetchImages = async () => {
    setIsLoading(true);
    const images = [];

    for (let i = 0; i < order.Produits.length; i++) {
      const product = order.Produits[i];
      images.push(await getImage(product));

      // After every 5 requests, pause
      if ((i + 1) % 5 === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust timing as needed
      }
    }

    setImagesList(images);
    setIsLoading(false);
  };

  const getImage = async (product, retries = 3) => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/images/${product.produit._id}`
      );
      if (!response.ok)
        throw new Error(
          `Failed to fetch image metadata: ${response.statusText}`
        );

      const imageData = await response.json();
      const signedUrlResponse = await fetch(
        `${config.BASE_URL}/api/generate-signed-url?key=${imageData.awsKey}`
      );
      if (!signedUrlResponse.ok)
        throw new Error(
          `Failed to fetch signed URL: ${signedUrlResponse.statusText}`
        );

      const signedUrlData = await signedUrlResponse.json();
      const imageResponse = await fetch(signedUrlData.signedUrl);
      if (!imageResponse.ok)
        throw new Error(`Failed to fetch image: ${imageResponse.statusText}`);

      const imageBlob = await imageResponse.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(imageBlob);
      });
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying... Attempts left: ${retries - 1}`);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
        return getImage(product, retries - 1);
      }
      console.error("Error in fetching or converting image:", error);
      return defaultImage;
    }
  };

  const MyDocument = (
    <Document>
      <Page size="LETTER" style={styles.pdfPage}>
        <View style={styles.section}>
          <View style={styles.order}>
            <Text>Commande {order.Numero}</Text>
            <Text>{formattedDate}</Text>
          </View>
          <View style={styles.header}>
            <View style={styles.myCompany}>
              <Image src={order.myCompany.companyLogo} style={styles.logo} />
              {order.myCompany.companyAddresses?.[0] && (
                <>
                  <Text style={styles.subtitle}>
                    {order.myCompany.companyAddresses[0].address}
                  </Text>
                  <Text style={styles.subtitle}>
                    {order.myCompany.companyAddresses[0].city},{" "}
                    {order.myCompany.companyAddresses[0].state}
                  </Text>
                  <Text style={styles.subtitleLast}>
                    {order.myCompany.companyAddresses[0].country},{" "}
                    {order.myCompany.companyAddresses[0].zipCode}
                  </Text>
                </>
              )}
              <Text style={styles.title}>{order.myCompany.companyEmail}</Text>
              <Text style={styles.title}>{order.myCompany.companyTel}</Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.title2}>Projet :</Text>
              <Text style={styles.subtitle2}>{order.Projet.Nom}</Text>
              <Text style={styles.subtitleLast2}>
                {order.Projet.projectType.Name}
              </Text>
              <Text style={styles.title2}>Commande passé par :</Text>
              <Text style={styles.subtitle2}>
                {order.commandePour.userFirstName}{" "}
                {order.commandePour.userLastName}
              </Text>
              <Text style={styles.subtitle}>{order.commandePour.userCell}</Text>
              <Text style={styles.subtitle}>
                {order.commandePour.userEmail}
              </Text>
            </View>
            <View style={styles.headerContent}>
              <Text style={styles.title2}>Compagnie :</Text>
              <Text style={styles.subtitle2}>{order.Company.companyName}</Text>
              {order.Company.companyAddresses?.[0] && (
                <>
                  <Text style={styles.subtitle}>
                    {order.Company.companyAddresses[0].address}
                  </Text>
                  <Text style={styles.subtitle}>
                    {order.Company.companyAddresses[0].city},{" "}
                    {order.Company.companyAddresses[0].state}
                  </Text>
                  <Text style={styles.subtitleLast}>
                    {order.Company.companyAddresses[0].country},{" "}
                    {order.Company.companyAddresses[0].zipCode}
                  </Text>
                </>
              )}
            </View>
          </View>

          <View style={styles.duration}>
            <View style={styles.column}>
              <Text style={styles.title2}>Départ :</Text>
              <Text style={styles.subtitle}>
                {formatDate(order.dateDepart)}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.title2}>Retour :</Text>
              <Text style={styles.subtitle}>
                {formatDate(order.dateRetour)}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.title2}>Durée :</Text>
              <Text style={styles.subtitle}>{totalDays} jours</Text>
            </View>
          </View>

          <View style={styles.article}>
            <View style={styles.left}>
              <Text style={styles.titleNumber}>#</Text>
              <Text style={styles.title}>Articles</Text>
            </View>
            <Text style={styles.title}>Quantité</Text>
          </View>

          <View style={styles.articles}>
            {imagesList.length === order.Produits.length &&
              order.Produits.map((product, index) => (
                <View key={index} style={styles.line}>
                  <View style={styles.line}>
                    <Text style={styles.title}>{index + 1}.</Text>
                    <Image
                      src={imagesList[index] || defaultImage}
                      style={styles.smallImage}
                    />
                    <Text style={styles.title}>{product.produit.Number}</Text>
                    <Text style={styles.title}> - {product.produit.Name}</Text>
                  </View>
                  <Text style={styles.title}>{product.quant}</Text>
                </View>
              ))}
            <View style={styles.lineBottom}>
              <Text style={styles.title}>Total articles</Text>
              <Text style={styles.title}>{totalArticles}</Text>
            </View>
          </View>
        </View>
        </Page>
      <Page size="LETTER" style={styles.pdfPage}>
        <View style={styles.section}>
          <View style={styles.imgContainer}>
            {imagesList.map((image, index) => (
              <View key={index} style={styles.bigImages}>
                <Text style={styles.title}>{index + 1}.</Text>
                <Image src={image} />
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      {!isLoading && (
        <BlobProvider document={MyDocument}>
          {({ url }) => (
            <a href={url} download={`order-${order.Numero}.pdf`}>
              <PictureAsPdfIcon className="add-icon" />
            </a>
          )}
        </BlobProvider>
      )}
      {isLoading && (
        <div>
          <img width="30" src={loader} alt="Loading..." />
        </div>
      )}
    </>
  );
};

export default PDFGenerator;
