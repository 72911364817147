import { Grid, Switch } from "@mui/material";
import removalAIICon from "../../../assets/images/apis/removalAI.png";
import { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import config from "../../../config";

const Apis = () => {
  const {user} = useGlobalContext()
    const [keyChanged, setKeyChanged] = useState(false);
    const [key, setKey] = useState("");

    const handleChange = () => {
        setKeyChanged(true);
    }
    


  return (
    <div>
      <div className="sub-header">
        <div className="text">
          <div className="title">API's</div>
          <div className="sub-title">
            Configurer les API's de votre système.{" "}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "centent",
          flexDirection: "column",
          margin: "20px"
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            <img
              src={removalAIICon}
              alt="removalAI"
              style={{ width: "100px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Switch
              size="small"
              defaultChecked
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{marginTop:"0px"}}>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            API key
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{display:"flex", justifyContent:"start", alignItems:"center"}}>
            <input
              type="text"
              style={{
                width: "300px",
                borderRadius: "5px",
                background: "none"
              }}
                value={key}
                onChange={(e) => {
                    setKey(e.target.value);
                    handleChange();
                }}
            />
            {keyChanged && <SaveIcon style={{ color: "green", cursor:"pointer" }} />}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Apis;
