import React from 'react';
import { Grid, Switch } from '@mui/material';
import DomainIcon from '@mui/icons-material/Domain';
import CategoryIcon from '@mui/icons-material/Category';
import StyleIcon from '@mui/icons-material/Style';
import WorkIcon from '@mui/icons-material/Work';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ApiIcon from '@mui/icons-material/Api';
import SettingsIcon from '@mui/icons-material/Settings';

const Settings = ({ activeEmploye, setActiveEmploye, editing }) => {
  const handleSettingsSwitchChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'setting') {
      // Toggle all settings based on the 'setting' switch
      const newSettings = {
        setting: checked,
        myCompanies: checked,
        categories: checked,
        tags: checked,
        types: checked,
        jobs: checked,
        apis: checked,
      };

      setActiveEmploye((prevEmploye) => ({
        ...prevEmploye,
        permissions: {
          ...prevEmploye.permissions,
          settings: newSettings,
        },
      }));
    } else {
      // Update individual setting switch
      const newSettings = {
        ...activeEmploye.permissions.settings,
        [name]: checked,
      };

      // Determine if any setting other than the master switch is on
      const anySettingOn = Object.keys(newSettings).some(
        (key) => key !== 'setting' && newSettings[key]
      );

      setActiveEmploye((prevEmploye) => ({
        ...prevEmploye,
        permissions: {
          ...prevEmploye.permissions,
          settings: {
            ...newSettings,
            setting: anySettingOn,
          },
        },
      }));
    }
  };

  const settings = {
    setting: false,
    myCompanies: false,
    categories: false,
    tags: false,
    types: false,
    jobs: false,
    apis: false,
    ...activeEmploye.permissions.settings,
  };

  return (
    <Grid item xs={12} sm={6} md={4} style={{ marginBottom: '10px' }}>
      <div className="small-header">
        <div className="title" style={{ display: 'flex', alignItems: 'center' }}>
          <SettingsIcon style={{ color: '#f3f3f3', marginRight: '5px' }} />
          <div>Paramètres</div>
        </div>
        {editing && (
          <Switch
            name="setting"
            checked={settings.setting}
            onChange={handleSettingsSwitchChange}
          />
        )
        }
      </div>
      {[
        { name: 'myCompanies', label: 'Mes compagnies', Icon: DomainIcon },
        { name: 'categories', label: 'Catégories', Icon: CategoryIcon },
        { name: 'tags', label: 'Tags', Icon: StyleIcon },
        { name: 'jobs', label: 'Jobs', Icon: WorkIcon },
        { name: 'types', label: 'Types', Icon: AccountTreeIcon },
        { name: 'apis', label: "API's", Icon: ApiIcon },
      ].map(({ name, label, Icon }) => (
        <div
          key={name}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginTop: '10px',
          }}
        >
          <div className="title" style={{ display: 'flex', alignItems: 'center' }}>
            <Icon style={{ marginRight: '5px' }} />
            <div>{label}</div>
          </div>
          {editing ? (
            <Switch
              name={name}
              checked={settings[name]}
              onChange={handleSettingsSwitchChange}
            />
          ) : (
            <div>{settings[name] ? 'ON' : 'OFF'}</div>
          )
          }
        </div>
      ))}
    </Grid>
  );
};

export default Settings;
