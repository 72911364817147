import React, { useState, useEffect, useRef } from 'react';
import './scrollToTop.css';

const ScrollToTop = ({ scrollContainer, onScrollToTop }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const inactivityTimer = useRef(null);

  const toggleVisibility = () => {
    if (scrollContainer?.current?.scrollTop > 300) {
      setIsVisible(true);
      resetInactivityTimer();
    } else {
      setIsVisible(false);
      clearInactivityTimer();
    }
  };
  const scrollToTop = () => {
    if (scrollContainer?.current) {
      // Scroll the scrollContainer to the top
      scrollContainer.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      // Fallback: scroll the window if no scrollContainer is available
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };
  

  const resetInactivityTimer = () => {
    clearInactivityTimer();
    inactivityTimer.current = setTimeout(() => {
      setIsInactive(true);
    }, 5000); // 5 seconds
  };

  const clearInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
      inactivityTimer.current = null;
    }
    setIsInactive(false);
  };

  useEffect(() => {
    const container = scrollContainer?.current;
    if (container) {
      container.addEventListener('scroll', toggleVisibility);
      return () => {
        container.removeEventListener('scroll', toggleVisibility);
        clearInactivityTimer();
      };
    }
  }, [scrollContainer]);

  useEffect(() => {
    if (isVisible) {
      resetInactivityTimer();
    } else {
      clearInactivityTimer();
    }
  }, [isVisible]);

  return (
    <div className="scroll-to-top">
      
        <button onClick={onScrollToTop} className={isVisible && !isInactive && "show"}>
          ↑
        </button>
    </div>
  );
};

export default ScrollToTop;
