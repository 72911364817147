import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../products.css';
import { useGlobalContext } from '../../../../hooks/useGlobalContext';
import config from '../../../../config';

function DeleteCategory(props) {
  const { categories, categoriesDispatch } = useGlobalContext();

  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClickDelete = async () => {
    console.log("delete");
    if (props.nomSub === "sous-catégorie") {
      for (let category of categories) {
        if (props.id === category.parentId) {
          const response = await fetch(`${config.BASE_URL}/api/categories/${category._id}`, {
            method: 'DELETE'
          });
          const json = await response.json();
          if (response.ok) {
            categoriesDispatch({ type: 'DELETE_CATEGORY', payload: json });
          }
        }
      }
    } else if (props.nomSub === "catégorie") {
      for (let category of categories) {
        if (props.id === category.parentId) {
          for (let subCategory of categories) {
            if (subCategory.parentId === category._id) {
              const response = await fetch(`${config.BASE_URL}/api/categories/${subCategory._id}`, {
                method: 'DELETE'
              });
              const json = await response.json();
              if (response.ok) {
                categoriesDispatch({ type: 'DELETE_CATEGORY', payload: json });
              }
            }
          }
          const response = await fetch(`${config.BASE_URL}/api/categories/${category._id}`, {
            method: 'DELETE'
          });
          const json = await response.json();
          if (response.ok) {
            categoriesDispatch({ type: 'DELETE_CATEGORY', payload: json });
          }
        }
      }
    }

    const response = await fetch(`${config.BASE_URL}/api/categories/${props.id}`, {
      method: 'DELETE'
    });
    const json = await response.json();
    if (response.ok) {
      categoriesDispatch({ type: 'DELETE_CATEGORY', payload: json });
    }

    handleClose();
  };

  return (
    <>
      
        <DeleteIcon className="add-icon" onClick={handleShow}/>
      

      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Êtes-vous certain de vouloir supprimer la {props.nomSub} <span className="lowerCase">{props.nom}</span> et toutes ses sous-catégories?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleClickDelete} color="primary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteCategory;
