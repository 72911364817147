import { Link } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import config from "../../../config.js";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useNotify } from "../../../hooks/useNotify.js";
import { Grid } from "@mui/material";

import OrderCard from "./orderCard.js";
import Loader from "../../../components/loader/index.js";

const MyOrders = ({ client }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useGlobalContext();
  const { notify } = useNotify();
  const fetchOrders = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${config.BASE_URL}/api/Commandes/clientOrders/${client._id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }
    );

    const json = await response.json();
    console.log(json);
    if (json.length != 0) {
      setOrders(json);
    } else {
      setOrders([]);
      setIsLoading(false);

      return;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if(client) fetchOrders();
  }, [user]);

  return (
    <div>
      <div className="header">
        <div className="text">
          <div className="title">Commandes</div>
          <div className="sub-title">Liste des commandes.</div>
        </div>
        <AddBoxIcon className="add-icon" />
      </div>
      {isLoading ? (
        <div
          className="loading"
          style={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </div>
      ) : orders && orders.length > 0 ? (
        <Grid container spacing={2}>
          {orders &&
            orders.map((order) => {
              return <OrderCard key={order._id} order={order} />;
            })}
        </Grid>
      ) : (
        <div style={{ textAlign: "center", margin: "30px" }}>
          Aucunne commande
        </div>
      )}
    </div>
  );
};

export default MyOrders;
