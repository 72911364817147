import { IconButton, Radio, FormControlLabel } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import noImage from "../../../assets/images/no-image-icon.png";

const UserIcon = ({ client, generateAvatar, setClient, editing }) => {
  const handleInputChange = (e) => {
    setClient({ ...client, 
      user: {
      ...client.user,
      userGender: e.target.value 
    }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <div className="imgUser">
        <img src={client?.user.avatar || noImage} alt="" style={{ borderRadius: "10px" }} />
      </div>
      {editing && client && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              sx={{ ".MuiFormControlLabel-label": { fontSize: 12 } }}
              control={
                <Radio
                  id="femaleRadio"
                  name="userGender"
                  value="female"
                  onChange={handleInputChange}
                  checked={client.user.userGender === "female"}
                />
              }
              label="Female"
            />
            <FormControlLabel
              sx={{ ".MuiFormControlLabel-label": { fontSize: 12 } }}
              control={
                <Radio
                  id="maleRadio"
                  name="userGender"
                  value="male"
                  onChange={handleInputChange}
                  checked={client.user.userGender === "male"}
                />
              }
              label="Male"
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton title="Changer d'avatar" onClick={generateAvatar}>
              <RefreshIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserIcon;
