import { Grid } from "@mui/material";
import CompanyIcon from "./companyIcon";
import EditCompanyInfo from "./editCompanyInfo";

const EditCompany = ({ newCompany, setNewCompany, editing }) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.startsWith("companyAddresses.")) {
      const addressIndex = name.split(".")[1];
      const addressField = name.split(".")[2];

      const updatedAddresses = [...newCompany.companyAddresses];

      updatedAddresses[addressIndex] = {
        ...updatedAddresses[addressIndex],
        [addressField]: value
      };

      setNewCompany({
        ...newCompany,
        companyAddresses: updatedAddresses
      });
    } else {
      setNewCompany({ ...newCompany, [name]: value });
    }
  };

 

  return (
    <div>
      <Grid container spacing={3} style={{ margin: "auto", maxWidth: "700px" }}>
        <Grid item xs={12} md={5} style={{ marginBottom: "10px" }}>
        {newCompany.avatar !== "" && (
            <CompanyIcon
            company={newCompany} // Pass the updated client with avatar URL
            setCompany={setNewCompany}
            editing={editing}
          />
        )}
          
        </Grid>
        <Grid item xs={12} md={7}>
          {editing && (
            <EditCompanyInfo
              handleInputChange={handleInputChange}
              newCompany={newCompany}
              setNewCompany={setNewCompany}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default EditCompany;
