import { createContext } from 'react'

export const TagsContext = createContext()

export const tagsReducer = (state, action) => {
    switch(action.type){
        case 'SET_TAGS':
            return {
                tags: action.payload
            }
        case 'SET_TAG':
                //console.log("Load un Produit")
                //console.log(action.payload)
            return {
                
                //categories:[action.payload]
                //products: state.products.filter((p) => p.id !== action.payload._id)
            }   
        case 'CREATE_TAG':
            console.log("Creating category")
            return {
                tags:[action.payload, ...state.tags]
            }
        case 'DELETE_TAG':
            console.log("Deleting category")
            return {
                tags: state.tags.filter((c) => c._id !== action.payload._id)
            }
                
        default:
            return state
    }
}
