import { Card, Container, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import config from "../../../config";
import { useGlobalContext } from "../../../hooks/useGlobalContext";


import ProductCard from "../../products/components/productCard";
import Loader from "../../../components/loader";

const DashboardProducts = () => {
  const { user } = useGlobalContext();

  /// LEt's fetch the products Stats
  const [productsStats, setProductsStats] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);

  const fetchProductsStats = async () => {
    setIsLoading(true);
    const response = await fetch(`${config.BASE_URL}/api/Stats/Products`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
    const data = await response.json();
    console.log(data);
    setProductsStats(data);

    // Extract labels and series data from productsStats
    const labels = data.totalPerCategory.map((item) => item.category);
    const series = data.totalPerCategory.map((item) => item.totalProducts);
    setLabels(labels);
    setSeries(series);

    setIsLoading(false);
  };

  const fetchProductsStatsMemo = useCallback(fetchProductsStats, []);
  useEffect(() => {
    fetchProductsStatsMemo();
  }, [fetchProductsStatsMemo]);

  // Chart options
  const chartOptions = {
    labels: labels.map((label, index) => `${label} (${series[index]})`), // Use the labels array and append the corresponding series value
    colors: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"], // Add more colors if needed
    legend: {
      show: true,
      position: "right", // Change the position of the legend to 'right'
      horizontalAlign: "top", // Center the legend horizontally
      offsetY: -10, // Add some offset to the legend for better spacing
      fontSize: "12px" // Set the font size of the legend to 12px
    },
    dataLabels: {
      enabled: false // Disable data labels inside the donut
    },
    plotOptions: {
      pie: {
        donut: {
          size: "75%", // Adjust the size of the donut
          labels: {
            show: false // Set the font size of the labels to 12px
          }
        }
      }
    }
  };

  // Chart series data
  const chartSeries = series; // Use the series array

  return (
    <Container maxWidth="lg">
      {!isLoading ? (
        <>
        <div className="header">
          <div className="text">
            <div className="title">Produits</div>
            <div className="sub-title">Résumé des produits.</div>
          </div>
        </div>
          
            
          

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Card style={{ maxHeight: "240px", display: "block" }}>
                <div className="info-line">
                  <div className="card-title">Produits Total</div>
                  <div className="card-value">
                    {productsStats.totalProducts}
                  </div>
                </div>
                <div className="info-line">
                  <div className="card-title">Total en Ligne</div>
                  <div className="card-value">
                    {productsStats.totalProductsOnline}
                  </div>
                </div>

                <div className="info-line">
                  <div className="card-title">Stock Total</div>
                  <div className="card-value">{productsStats.totalStock}</div>
                </div>
                <div className="info-line">
                  <div className="card-title">Valeur Total</div>
                  <div className="card-value">{productsStats.totalValue}$</div>
                </div>
                <div className="info-line">
                  <div className="card-title">Coût Total</div>
                  <div className="card-value">{productsStats.totalCost}$</div>
                </div>
              </Card>
            </Grid>

            <Grid item xs={8}>
              <Card style={{ minHeight: "240px", display: "block" }}>
                <div className="title">Produits par catégorie</div>
                <div className="chart-box">
                  <div className="chart">
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="donut"
                    />
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
          <div className="subTitle">Produits les moins louer</div>
          <Grid container spacing={3}>
            {productsStats.lessRented && productsStats.lessRented.map((product) => (
              <ProductCard key={product.id} products={product} />
            ))}
          </Grid>
          <div className="subTitle">Produits les plus louer</div>
          <Grid container spacing={3}>
            {productsStats.mostRented && productsStats.mostRented.map((product) => (
              <ProductCard key={product.id} products={product} />
            ))}
          </Grid>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            height: "400px",
            paddingTop: "100px",
            justifyContent: "center",
            justifyItems: "center"
          }}
        >
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default DashboardProducts;
