import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useSnackbar } from "../../context/snackbarContext";
import Loader from "../../components/loader";
import EmployeInfos from "./components/employeInfos";
import EditIcon from "@mui/icons-material/Edit";
import Permissions from "./components/permissions";
import "./employes.css";

const DetailsEmployes = () => {
  const { user } = useGlobalContext();
  const { id } = useParams();
  const [activeEmploye, setActiveEmploye] = useState({});
  const [isLoading, setIsloading] = useState(true);

  const { showSnackbar } = useSnackbar();

  const fetchEmploye = async () => {
    setIsloading(true);
    const response = await fetch(`${config.BASE_URL}/api/employes/${id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
    const json = await response.json();

    if (response.ok) {
      setActiveEmploye(json);
      console.log("Employe", json);
      setIsloading(false);
    }
    if (!response.ok) {
      showSnackbar("Erreur lors de la récupération de l'employé", "error");
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchEmploye();
    }
    window.scrollTo(0, 0);
  }, [id, user]);

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">Employé</div>
            <div className="sub-title">Détails de l'employé.</div>
          </div>
          <Link to={`/Employé/Modifier/${activeEmploye._id}`}>
            <EditIcon className="add-icon" />
          </Link>
        </div>

        {isLoading && <Loader />}
        {!isLoading && activeEmploye && (
          <>
            <EmployeInfos employe={activeEmploye} />
            <div className="header">
              <div className="text">
                <div className="title">Permissions</div>
                <div className="sub-title">
                  {" "}
                  Consulter les permissions de l'employé.{" "}
                </div>
              </div>
            </div>
            <Permissions activeEmploye={activeEmploye} editing={false} />
          </>
        )}
      </Container>
    </div>
  );
};
export default DetailsEmployes;
