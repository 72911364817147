import { Container, Grid } from "@mui/material";
import "./clients.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import { useNotify } from "../../hooks/useNotify";
import config from "../../config";
import ClientCard from "./components/clientCard";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchBar from "./components/searchBar";
import ClientEdit from "./components/clientEdit";
import Loader from "../../components/loader";

const Clients = ({editing}) => {
  const { user } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState();
  const [filteredClients, setFilteredClients] = useState();
  const [showAddClient, setShowAddClient] = useState(false);
  const [search, setSearch] = useState("");
  const { notify } = useNotify();

  const [newClient, setNewClient] = useState({
    userFirstName: "",
    userLastName: "",
    userCell: "",
    userGender: "male",
    userAddresses: [],
    avatar: ""
  });

  useEffect(() => {
    if (editing) {
      setShowAddClient(true);
    }
  }, [editing]);

  
  useEffect(() => {
    if (search) {
      // Let's search first name, last name,email and phone number
      const filteredClients = clients.filter((client) => {
        return (
          client.userFirstName.toLowerCase().includes(search.toLowerCase()) ||
          client.userLastName.toLowerCase().includes(search.toLowerCase()) ||
          client.userEmail.toLowerCase().includes(search.toLowerCase()) ||
          client.userCell.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredClients(filteredClients);
    } else {
      setFilteredClients(clients);
    }
  }, [search]);

  const getClients = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/clients`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch clients");
      }
      const json = await response.json();
      setClients(json.clients);
      setFilteredClients(json.clients);
      console.log(json);
    } catch (error) {
      notify("error", error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      getClients();
    }
  }, [user]);


  const addClient = async () => {
    try {
      const saveClient = await fetch(
        `${config.BASE_URL}/api/clients`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          },
          body: JSON.stringify(newClient)
        }
      );
      if (saveClient.ok) {
        notify("success", "Client ajouté avec succès");
        getClients();
        setShowAddClient(false);
      } else {
        const errorMessage = await saveClient.json();
        console.error(
          "Failed to save user information:",
          errorMessage.error
        );
        notify("error", errorMessage.error);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      notify(
        "error",
        "An error occurred while saving client information"
      );
    }
  }


  return (
    <div className="main-content">
      <Container maxWidth="lg">
        {!showAddClient && (
          <div className="header">
            <div className="text">
              <div className="title">Clients</div>
              <div className="sub-title">
                Gérer les clients de votre entreprise.
              </div>
            </div>
            <div
            title="Nouveau client"
              onClick={() => {
                setShowAddClient(true);
              }}
            >
              <AddBoxIcon className="add-icon" />
            </div>
          </div>
        )}

        {showAddClient && (
          <div className="header">
            <div className="text">
              <div className="title">Nouveau client</div>
              <div className="sub-title">
                Veuillez remplir les informations nécessaires pour le nouveau client.
              </div>
            </div>
            <div style={{display:"flex"}}>
              <div
                title="Ajouter le nouveau client."
                onClick={async () => {
                  await addClient();
                  
                }}
              >
                <SaveIcon className="add-icon" />
              </div>
              <div
                title="Annuler"
                onClick={() => {
                  setShowAddClient(false);
                }}
              >
                <CancelIcon className="add-icon" />
              </div>
            </div>
          </div>
        )}
        {isLoading && !showAddClient &&
        <div style={{height:"500px", display:"flex", alignItems:"center"}}>
        <Loader />
        </div>}
        {filteredClients && !showAddClient && (
          <div style={{ paddingRight: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px"
              }}
            >
              <SearchBar search={search} setSearch={setSearch} />
              <div style={{ marginLeft: "5px" }}>
                {filteredClients.length} clients
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Grid container style={{ margin: 0, padding: 0 }}>
                {!isLoading &&
                  filteredClients &&
                  filteredClients.map((client) => {
                    return <ClientCard key={client._id} client={client} />;
                  })}
              </Grid>
            </div>
          </div>
        )}
        <div className="details">
          {showAddClient && (
            <ClientEdit
              newClient={newClient}
              setNewClient={setNewClient}
              editing={true}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
export default Clients;
