import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField } from '@mui/material';
import AddBoxIcon from "@mui/icons-material/AddBox";
import '../../products.css';
import { useGlobalContext } from '../../../../hooks/useGlobalContext';
import config from '../../../../config';

function CreateSubCategory(props) {
  const { categoriesDispatch } = useGlobalContext();

  const [Name, setNewCategoryName] = useState("");
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addSubCategory = async () => {
    const parentId = props.parentId;
    const newSubCategory = { Name, parentId };
    console.log("PAss " + parentId);
    const response = await fetch(`${config.BASE_URL}/api/categories`, {
      method: 'POST',
      body: JSON.stringify(newSubCategory),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.error);
    }
    if (response.ok) {
      categoriesDispatch({ type: 'CREATE_CATEGORY', payload: json });
      setNewCategoryName('');
      setError(null);
      console.log("New Category added");
      handleClose();
    }
  };

  return (
    <>
      <div style={{display:"flex"}} onClick={() => {
        if (props.parentId) {
          handleShow();
        }
      }}>
        <AddBoxIcon className="add-icon" />
      </div>

      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <div className="chemin">{props.nom}</div>
          <TextField
            autoFocus
            margin="dense"
            label="Subcategory Name"
            type="text"
            fullWidth
            value={Name}
            onChange={(e) => setNewCategoryName(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Fermer
          </Button>
          <Button onClick={addSubCategory} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateSubCategory;
