//import "../css/account.css";

import { Button } from "@mui/material";

import { useState } from "react";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import greenDot from "../../../assets/images/green-dot.png";
import redDot from "../../../assets/images/red-dot.png";
import fakeIcon from "../../../assets/images/fake.png";

const UserInfo = ({ userInfo }) => {
  return (
    <>
      <div className="infos">
        <div className="details-title">
          {userInfo.userFirstName} {userInfo.userLastName}
        </div>
        <div className="details-subtitle">
          {" "}
          <EmailIcon className="small-icon" /> {userInfo.userEmail}{" "}
        </div>
        <div className="details-subtitle">
          {" "}
          <PhoneIphoneIcon className="small-icon" /> {userInfo.userCell}
        </div>
        <div>
          {userInfo.userVerified && (
            <div
              style={{
                fontSize: "12px",
                alignItems: "center",
                display: "flex",
                margin: "5px"
              }}
            >
              <img src={greenDot} className="dot" alt="" />
              <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                Verified
              </span>
            </div>
          )}
          {!userInfo.userVerified && (
            <div
              style={{
                fontSize: "12px",
                alignItems: "center",
                display: "flex",
                margin: "5px"
              }}
            >
              <img src={redDot} className="dot" alt="" />
              <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                Not verified
              </span>
            </div>
          )}
        </div>
        {userInfo.fakeUser && (
          <img src={fakeIcon} width="45px" alt="" className="fake" />
        )}
        <div>
          {userInfo.userCell && !userInfo.userCellVerified && (
            <Button
              variant="contained"
              className="btn-primary"
              title="Envoyer au client une invitation à s'inscrire."
              style={{
                fontSize: "12px",
                padding: "5px 10px",
                marginTop: "10px"
              }}
            >
              Envoyer Invitation
            </Button>
          )}
        </div>
      </div>

      {userInfo.userAddresses &&
        userInfo.userAddresses.map((address, index) => {
          return (
            <div key={index} className="addressCard">
              <div className="name">{address.label}</div>
              <div>{address.address}</div>
              <div>
                {address.city}
                {address.state && `, ${address.state}`}
              </div>
              <div>
                {address.country}
                {address.postalCode && `, ${address.postalCode}`}
              </div>
              <div>{address.telephone}</div>
            </div>
          );
        })}
    </>
  );
};

export default UserInfo;
