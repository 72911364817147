import { useState, useEffect, useRef } from "react";
import { getProductsPage } from "../api/axios";
import { useGlobalContext } from "./useGlobalContext";

const useProducts = () => {
  const { products, productsDispatch, searching, searchQuery } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});
  const [hasNextPage, setHasNextPage] = useState(false);

  // Track previous searching and searchQuery for comparison
  const prevSearchingRef = useRef(searching);
  const prevSearchQueryRef = useRef(searchQuery);

  useEffect(() => {
    console.log("Changes detected in searching or searchQuery");
    console.log("searching", searching);
    console.log("searchQuery", searchQuery);
    const isFirstPage = searching.page === 1;
    const isNewSearch = 
      JSON.stringify(prevSearchingRef.current) !== JSON.stringify(searching) ||
      prevSearchQueryRef.current !== searchQuery;

    // If there are no products loaded, load the first page
    if (products.length === 0 && isFirstPage) {
      setIsLoading(true);
      productsDispatch({ type: "CLEAR_PRODUCTS" });
    }
    // If it's a new search or query change, load the first page again
    else if (isFirstPage && isNewSearch) {
      setIsLoading(true);
      productsDispatch({ type: "CLEAR_PRODUCTS" });
    }
    // If the page number increases, load the next set of products
    else if (searching.page > prevSearchingRef.current.page) {
      setIsLoading(true);
    } 
    // If none of the conditions match, do not fetch
    else {
      setIsLoading(false);
      return;
    }

    setIsError(false);
    setError({});

    const controller = new AbortController();
    const { signal } = controller;

    getProductsPage(searching, searchQuery, { signal })
      .then((data) => {
        if (isFirstPage) {
          // Set products when loading the first page
          productsDispatch({
            type: "SET_PRODUCTS",
            payload: { products: data.products, total: data.total, scrollPosition: 0 },
          });
        } else {
          // Append new products when loading additional pages
          productsDispatch({
            type: "ADD_PRODUCTS",
            payload: { products: data.products, total: data.total },
          });
        }

        setHasNextPage(data.products.length > 0);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        if (signal.aborted) return;
        setIsError(true);
        setError({ message: e.message });
      });

    // Update previous search state
    prevSearchingRef.current = searching;
    prevSearchQueryRef.current = searchQuery;

    return () => controller.abort();
  }, [searching, searchQuery, productsDispatch]);

  return { isLoading, isError, error, hasNextPage, setHasNextPage };
};

export default useProducts;
