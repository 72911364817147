import "./css/dock.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InventoryIcon from "@mui/icons-material/Inventory";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import DomainIcon from "@mui/icons-material/Domain";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ContactsIcon from "@mui/icons-material/Contacts";
import DvrIcon from "@mui/icons-material/Dvr";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ComputerIcon from "@mui/icons-material/Computer";
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

import VideocamIcon from "@mui/icons-material/Videocam";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "@mui/material";
import UserMenu from "./components/userMenu";
import SearchBar from "./components/searchBar";

import logo from "../../assets/images/deco_small.png";
import SearchBox from "../search-box/index.js";

const Dock = ({ showSearch, setShowSearch }) => {
  const [showMouseOver, setShowMouseOver] = useState({
    userMenu: false,
    products: false,
    kits: false,
    clients: false,
    companies: false,
    projects: false,
    orders: false,
    invoices: false,
    employees: false,
    expenses: false,
    schedules: false,
    desktop: false
  });

  return (
    <div className="dock">
      <div className="dock-items">
        <div
          className="dock-item"
          onMouseEnter={() => {
            setShowMouseOver((prev) => ({
              ...prev,
              userMenu: true
            }));
          }}
        >
          {showMouseOver.userMenu && (
            <UserMenu
              showMouseOver={showMouseOver}
              setShowMouseOver={setShowMouseOver}
            />
          )}

          <AccountCircleIcon />
        </div>
        <div className="separator"> </div>
        <div
          onMouseEnter={() => {
            setShowMouseOver((prev) => ({
              ...prev,
              userMenu: false
            }));
          }}
          onClick={() => setShowSearch(true)}
          style={{ position: "relative" }}
        >
          <SearchBar />
          {showSearch && (
            <SearchBox setShowSearch={setShowSearch} showSearch={showSearch} />
          )}
        </div>

        <Link to="/produits" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                products: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                products: false
              });
            }}
          >
            {showMouseOver.products && (
              <div className="btn-title">Produits</div>
            )}

            <InventoryIcon />
          </div>
        </Link>
        <Link to="/ensembles" className="noDecoration">
          <div

            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                kits: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                kits: false
              });
            }}
          >
            {showMouseOver.kits && <div className="btn-title">Ensembles</div>}
            <HomeRepairServiceIcon />
          </div>
        </Link>
        
        <Link to="/clients" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                clients: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                clients: false
              });
            }}
          >
            {showMouseOver.clients && <div className="btn-title">Clients</div>}

            <FolderSharedIcon />
          </div>
        </Link>
        <Link to="/compagnies" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                companies: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                companies: false
              });
            }}
          >
            {showMouseOver.companies && (
              <div className="btn-title">Compagnies</div>
            )}

            <DomainIcon />
          </div>
        </Link>
        <Link to="/projets" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                projects: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                projects: false
              });
            }}
          >
            {showMouseOver.projects && <div className="btn-title">Projets</div>}

            <VideocamIcon />
          </div>
        </Link>
        <Link to="/Commandes" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                orders: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                orders: false
              });
            }}
          >
            {showMouseOver.orders && <div className="btn-title">Commandes</div>}

            <LocalShippingIcon />
          </div>
        </Link>
        <Link to="/factures" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                invoices: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                invoices: false
              });
            }}
          >
            {showMouseOver.invoices && (
              <div className="btn-title">Factures</div>
            )}

            <ReceiptIcon />
          </div>
        </Link>
        <Link to="/Employés" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                employees: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                employees: false
              });
            }}
          >
            {showMouseOver.employees && (
              <div className="btn-title">Employés</div>
            )}

            <ContactsIcon />
          </div>
        </Link>
        <Link to="/dépenses" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                expenses: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                expenses: false
              });
            }}
          >
            {showMouseOver.expenses && (
              <div className="btn-title">Dépenses</div>
            )}

            <AttachMoneyIcon />
          </div>
        </Link>
        <Link to="/horaires" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                schedules: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                schedules: false
              });
            }}
          >
            {showMouseOver.schedules && (
              <div className="btn-title">Horaires</div>
            )}

            <CalendarMonthIcon />
          </div>
        </Link>

        <div className="separator"></div>
        <Link to="/" className="noDecoration">
          <div
            className="dock-item"
            onMouseEnter={() => {
              setShowMouseOver((prev) => ({
                ...prev,
                desktop: true
              }));
            }}
            onMouseLeave={() => {
              setShowMouseOver({
                ...showMouseOver,
                desktop: false
              });
            }}
          >
            {showMouseOver.desktop && <div className="btn-title">Bureau</div>}

            <ComputerIcon />
          </div>
        </Link>
        <div className="dock-item">
          <Badge badgeContent={3} color="error">
            <NotificationsIcon />
          </Badge>
        </div>
      </div>
    </div>
  );
};
export default Dock;
