import React from "react";
import defaultImage from "../../../assets/images/imgIcon.png";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import config from "../../../config";
import { Card, Grid } from "@mui/material";
import { useSnackbar } from "../../../context/snackbarContext";

const ProductCard = React.forwardRef(
  ({ product, setProducts, id, order, setOrder }, ref) => {
    const { user } = useGlobalContext();
    const [enStock, setEnStock] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState();
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
      getImage();
    }, [product]);

    const getImage = async () => {
      setIsLoading(true);
      if (product.Image) {
        setImage(product.Image);
      } else {
        const response = await fetch(
          `${config.BASE_URL}/api/images/${product._id}`
        );
        try {
          const json = await response.json();
          if (response.ok) {
            const img = new Image();
            img.onload = async () => {
              const imageUrl = await getSignedURL(json.awsKey);
              setImage(imageUrl);
              setIsLoading(false);
            };
            img.onerror = () => {
              console.log("Error loading image. Using default image.");
              setImage(defaultImage);
            };
            img.src = await getSignedURL(json.awsKey);
          } else {
            setImage(defaultImage);
          }
        } catch (error) {
          setImage(defaultImage);
        }
      }
    };

    const getSignedURL = async (awsKey) => {
      const response = await fetch(
        `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
      );
      if (response.ok) {
        const data = await response.json();
        return data.signedUrl;
      } else {
        return defaultImage;
      }
    };

    const addProductToOrder = async () => {
      try {
        const response = await fetch(
          `${config.BASE_URL}/api/Commandes/item/add/${order._id}`,
          {
            method: "POST",
            body: JSON.stringify({
              productID: product._id,
              quant: 1
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`
            }
          }
        );
        if (!response.ok) {
          const json = await response.json();
          throw new Error(json.error);
        }
        const data = await response.json();

        if (product.Quantite === 1) {
          setProducts((prevProducts) =>
            prevProducts.filter((item) => item._id !== product._id)
          );
        } else {
          setProducts((prevProducts) =>
            prevProducts.map((item) => {
              if (item._id === product._id) {
                return { ...item, Quantite: item.Quantite - 1 };
              }
              return item;
            })
          );
        }
        console.log("Data", data);
        setOrder(data);
        showSnackbar("Produit ajouté à la commande", "success");
      } catch (error) {
        showSnackbar(error.message, "error");
      }
    };

    useEffect(() => {
      var stockLigne;
      if (product.Quantite > 1) {
        stockLigne = product.Quantite + " de disponibles";
        setEnStock(stockLigne);
      } else {
        stockLigne = product.Quantite + " de disponible";
        setEnStock(stockLigne);
      }
    }, [product.Quantite]);

    const productBody = (
      <>
        <Card
          className="card-item-small"
          onClick={addProductToOrder}
        >
          <Grid container>
            <Grid item xs={4}>
              <div className="card-item-img-small" style={{ border: "none" }}>
                {image && <img src={image} alt="" style={{ border: "none" }} />}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap"
                }}
              >
                {product.Number}
              </div>
              <div
                style={{
                  width: "135px",
                  fontSize: "14px",
                  overflow: "hidden",
                  flexWrap: "wrap",
                  whiteSpace: "nowrap"
                }}
              >
                {product.Name}
              </div>
              <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                {enStock}
              </div>
            </Grid>
          </Grid>
        </Card>
      </>
    );

    const content = ref ? (
      <div ref={ref}>
        {productBody}
      </div>
    ) : (
      <div>{productBody}</div>
    );

    return content;
  }
);

export default ProductCard;
