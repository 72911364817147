import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const SearchBarMini = ({search, setSearch}) => {
  

  return (
    <div className="searchBar">
      <SearchIcon />
      <input
        type="search"
        placeholder="Rechercher"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </div>
  );
};

export default SearchBarMini;
