import { Button, Grid, TextField } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

const EditCompanyInfo = ({
  newCompany,
  setNewCompany,
  handleInputChange,
}) => {
  const addAddress = () => {
    const newAddress = {
      name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      telephone: ""
    };

    setNewCompany({
      ...newCompany,
      companyAddresses: [...newCompany.companyAddresses, newAddress]
    });
  };

  const handleChange = (e) => {
    console.log("Name : ", e.target.name);
    console.log("Value : ", e.target.value);
    setNewCompany({
      ...newCompany,
      [e.target.name]: e.target.value
    });
  };

  const deleteAddress = (index) => {
    const updatedAddresses = [...newCompany.companyAddresses];
    updatedAddresses.splice(index, 1);

    setNewCompany({
      ...newCompany,
      companyAddresses: updatedAddresses
    });
  };

  return (
    <div>
      <TextField
        label="Nom de la compagnie*"
        variant="outlined"
        name={`companyName`}
        style={{ width: "100%" }}
        onChange={handleChange}
        value={newCompany.companyName}
      />
      
      <TextField
        label="Courriel*"
        variant="outlined"
        name="companyEmail"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={newCompany.companyEmail}
      />
      <TextField
        label="Téléphone*"
        variant="outlined"
        name="companyTel"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={newCompany.companyTel}
      />
      <TextField
        label="Numéro de TPS"
        variant="outlined"
        name="TPSnumber"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={newCompany.TPSnumber}
      />
      <TextField
        label="Numéro de TVQ"
        variant="outlined"
        name="TVQnumber"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={newCompany.TVQnumber}
      />
      <TextField
        label="Site web"
        variant="outlined"
        name="website"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={newCompany.website}
      />
      <div className="header">
        <div className="text">
          <div
            className="title"
          >
            Adresses
          </div>
        </div>
        <div
          title="Ajouter une adresse"
          onClick={addAddress}
        >
          <AddBoxIcon  className="add-icon" style={{ fontSize: "25px" }} />
        </div>
      </div>

      {newCompany.companyAddresses &&
        newCompany.companyAddresses.map((address, index) => (
          <div key={index}>
            <TextField
              label="Étiquette"
              variant="outlined"
              name={`companyAddresses.${index}.label`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.label}
            />
            <TextField
              label="Adresse"
              variant="outlined"
              name={`companyAddresses.${index}.address`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.address}
            />

            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} md={6} className="mt-2">
                <TextField
                  label="Ville"
                  variant="outlined"
                  name={`companyAddresses.${index}.city`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.city}
                />
              </Grid>
              <Grid xs={12} md={6} style={{ textAlign: "right" }}>
                <TextField
                  label="Province"
                  variant="outlined"
                  name={`companyAddresses.${index}.state`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.state}
                />
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} md={6} className="mt-2">
                <TextField
                  label="Pays"
                  variant="outlined"
                  name={`companyAddresses.${index}.country`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.country}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                <TextField
                  label="Code postal"
                  variant="outlined"
                  name={`companyAddresses.${index}.zipCode`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.zipCode}
                />
              </Grid>
            </Grid>
            <TextField
              label="Téléphone"
              variant="outlined"
              name={`companyAddresses.${index}.telephone`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.telephone}
            />
            {index != 0 && (
              <Button
                className="btn-primary"
                variant="contained"
                onClick={() => deleteAddress(index)}
                style={{
                  width: "100%",
                  fontSize: "12px",
                  padding: "5px 10px",
                  marginTop: "20px"
                }}
              >
                Retirer l'adresse
              </Button>
            )}
            <div className="separatorHorizontal"></div>
          </div>
        ))}
    </div>
  );
};

export default EditCompanyInfo;
