import { Button, Grid, TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

const ModifyUserInfo = ({
  client,
  setClient,
  handleInputChange,
}) => {
  const addAddress = () => {
    const newAddress = {
      name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      telephone: ""
    };

    setClient({
      ...client,
      userAddresses: [...client.userAddresses, newAddress]
    });
  };

  const handleChange = (e) => {
    console.log("Name : ", e.target.name);
    console.log("Value : ", e.target.value);
    setClient({
      ...client,
      [e.target.name]: e.target.value
    });
  };

  const deleteAddress = (index) => {
    const updatedAddresses = [...client.userAddresses];
    updatedAddresses.splice(index, 1);

    setClient({
      ...client,
      userAddresses: updatedAddresses
    });
  };

  return (
    <div>
      <TextField
        label="Prénom"
        variant="outlined"
        name={`userFirstName`}
        style={{ width: "100%" }}
        onChange={handleChange}
        value={client.userFirstName}
      />
      <TextField
        label="Nom de famille"
        variant="outlined"
        name="userLastName"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={client.userLastName}
      />

      <TextField
        label="Courriel"
        variant="outlined"
        name="userEmail"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={client.userEmail}
      />
      <TextField
        label="Cellulaire"
        variant="outlined"
        name="userCell"
        style={{ width: "100%", marginTop: "15px" }}
        onChange={handleChange}
        value={client.userCell}
      />
      <div className="header">
        <div className="text">
          <div
            className="title"
          >
            Adresses
          </div>
        </div>
        <div
          title="Ajouter une adresse"
          onClick={addAddress}
        >
          <AddBoxIcon  className="add-icon" style={{ fontSize: "25px" }} />
        </div>
      </div>

      {client.userAddresses &&
        client.userAddresses.map((address, index) => (
          <div key={index}>
            <TextField
              label="Étiquette"
              variant="outlined"
              name={`userAddresses.${index}.label`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.label}
            />
            <TextField
              label="Adresse"
              variant="outlined"
              name={`userAddresses.${index}.address`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.address}
            />

            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} md={6} className="mt-2">
                <TextField
                  label="Ville"
                  variant="outlined"
                  name={`userAddresses.${index}.city`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.city}
                />
              </Grid>
              <Grid xs={12} md={6} style={{ textAlign: "right" }}>
                <TextField
                  label="Province"
                  variant="outlined"
                  name={`userAddresses.${index}.state`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.state}
                />
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} md={6} className="mt-2">
                <TextField
                  label="Pays"
                  variant="outlined"
                  name={`userAddresses.${index}.country`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.country}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                <TextField
                  label="Code postal"
                  variant="outlined"
                  name={`userAddresses.${index}.postalCode`}
                  style={{ width: "98%" }}
                  onChange={handleInputChange}
                  value={address.postalCode}
                />
              </Grid>
            </Grid>
            <TextField
              label="Téléphone"
              variant="outlined"
              name={`userAddresses.${index}.telephone`}
              style={{ width: "100%", marginTop: "15px" }}
              onChange={handleInputChange}
              value={address.telephone}
            />
            {index != 0 && (
              <Button
                className="btn-primary"
                variant="contained"
                onClick={() => deleteAddress(index)}
                style={{
                  width: "100%",
                  fontSize: "12px",
                  padding: "5px 10px",
                  marginTop: "20px"
                }}
              >
                Retirer l'adresse
              </Button>
            )}
            <div className="separatorHorizontal"></div>
          </div>
        ))}
    </div>
  );
};

export default ModifyUserInfo;
