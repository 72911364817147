import { createContext } from "react";

export const UserContext = createContext();

export const userReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        user: action.payload,
      };
    case "LOGOUT":
      return {
        user: null,
      };
    case "SAVE_USER":
      return {
        user: action.payload,
      };
    case "ADD_COMPANY":
      console.log("action.payload : ", action.payload)
      const userAded = state.user;
      userAded.userCompanies = action.payload
      localStorage.setItem('user', JSON.stringify(userAded))
      return {
        //
        user: state.user
      };
      case 'REMOVE_COMPANY':

        
        const newUserList = state.user
        newUserList.userCompanies = action.payload
        localStorage.setItem('user', JSON.stringify(newUserList))
        return {
          user: state.user
        }
    default:
      return state;
  }
};
