import { Link } from "react-router-dom";
import "../card.css";
import { Card, Grid } from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useEffect } from "react";

const ClientCardProjet = ({
  client,
  activeProject,
  setActiveProject,
  editing
}) => {
  




  const removeClientFromEquipe = async (e) => {
    e.preventDefault();
      setActiveProject({
        ...activeProject,
        Equipe: activeProject.Equipe.filter(
          (teamMate) => teamMate.client._id !== client.client._id
        )
      });        
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{padding:"10px"}}>
        <Link className="noDecoration" to={`/clients/${client.client._id}`}>
          <Card className="card-list" style={{ position: "relative" }}>
            {editing && (
            <RemoveCircleIcon
              className="remove-button"
              title={`Retirer ${client.client.userFirstName} de l'équipe`}
              onClick={(e) => {
                removeClientFromEquipe(e);
              }}
            />
          )}
            <div className="img">
              <img
                src={client.client.avatar}
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="info">
              <div className="card-title">
                {client.client.userFirstName && (
                  <>{client.client.userFirstName}</>
                )}
                {client.client.userLastName && (
                  <> {client.client.userLastName}</>
                )}
              </div>
              <div className="card-subtitle">
                {client.poste.Name && <>{client.poste.Name}</>}
              </div>
              <div className="card-subtitle" style={{display:"flex", alignItems:"center"}}>
              <PhoneIphoneIcon style={{fontSize:"15px", marginRight:"3px"}} />   {client.poste.Name && <>{client.client.userCell}</>}
              </div>
              <div className="card-subtitle"
              style={{display:"flex", alignItems:"center"}}
              >
              <EmailIcon style={{fontSize:"15px", marginRight:"3px"}} />
                {client.poste.Name && <>{client.client.userEmail}</>}
              </div>
            </div>
          </Card>
        </Link>
      </Grid>
    </>
  );
};

export default ClientCardProjet;
