import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";

import config from "../../../config";

import defaultImage from "../../../assets/images/imgIcon.png";
import pointVert from "../../../assets/images/green-dot.png";
import pointRouge from "../../../assets/images/red-dot.png";

const ProductCard = React.forwardRef(({ products }, ref) => {
  const [enStock, setEnStock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [image, setImage] = useState();

  useEffect(() => {
    getImage();
  }, [products]);

  const getImage = async () => {
    setIsLoading(true);
    if (products.Image) {
      setImage(products.Image);
    } else {
      const response = await fetch(
        `${config.BASE_URL}/api/images/${products._id}`
      );
      try {
        const json = await response.json();
        if (response.ok) {
          const img = new Image();
          img.onload = async () => {
            const imageUrl = await getSignedURL(json.awsKey);

            setImage(imageUrl);
            setIsLoading(false);
          };
          img.onerror = () => {
            console.log("Error loading image. Using default image.");
            setImage(defaultImage);
          };
          img.src = await getSignedURL(json.awsKey);
        } else {
          setImage(defaultImage);
        }
      } catch (error) {
        setImage(defaultImage);
      }
    }
  };

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      // Handle errors here, e.g., return a default image URL.
      return defaultImage;
    }
  };

  useEffect(() => {
    var stockLigne;
    if (products.Quantite > 1) {
      stockLigne = products.Quantite + " de disponibles";
      setEnStock(stockLigne);
    } else {
      stockLigne = products.Quantite + " de disponible";
      setEnStock(stockLigne);
    }
  }, [products.Quantite]);

  const productBody = (
    <>
      <Link
        className="noDecoration"
        to={`/Produit/${products._id}`}
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          window.open(
            `/Produit/${products._id}`,
            "_blank",
            "noopener,noreferrer"
          );
        }}
      >
        <Card className="card-item">
          <div className="card-item-img">
            {image && <img src={image} alt="" />}
          </div>
          <div className="card-title">
            {products.Number} - {products.Name}
          </div>
          <div className="card-dispo">
            <div className="disponibleCard">{enStock}</div>

            <img
              style={{ width: "15px" }}
              src={products.EnLigne ? pointVert : pointRouge}
            />
          </div>
        </Card>
      </Link>
    </>
  );

  const content = ref ? (
    <Grid item xs={3} lg={2} ref={ref}>
      {productBody}
    </Grid>
  ) : (
    <Grid item xs={3} lg={2}>
      {productBody}
    </Grid>
  );

  return content;
});
export default ProductCard;
