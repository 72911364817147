import { Grid } from "@mui/material";
import CompanyIcon from "./companyIcon";
import InfoCompany from "./infoCompany";


const CompanyInfos = ({ company, editing }) => {

    return(
        <div className="details">
      <Grid container spacing={3} style={{ margin: "auto", maxWidth: "700px"}}>
        <Grid item xs={12} md={5} style={{ marginBottom: "10px"}}>
          <CompanyIcon company={company} editing={editing} />
        </Grid>
        <Grid item xs={12} md={7}>
          <InfoCompany company={company} />
        </Grid>
      </Grid>
    </div>
    )

}
export default CompanyInfos;