import { useRef, useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import axios from "axios";
import CategoriesCheckboxes from "./components/categories/categories";
import TagsCheckboxes from "./components/tags/tags";
import config from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import "./imgBox.css";
import "./cssSwitch.css";
import "./productDetails.css";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import defaultIcon from "../../assets/images/no-image-icon.png";
import { useSnackbar } from "../../context/snackbarContext";
import ImgBox from "./components/imgBox/imgBox";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Switch,
  FormControlLabel
} from "@mui/material";

const FormData = require("form-data");

const AddProduct = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [produit, setProduit] = useState({
    Number: "",
    Name: "",
    Year: "",
    Brand: "",
    Model: "",
    Largeur: 0,
    Profondeur: 0,
    Hauteur: 0,
    Diametre: 0,
    Description: "",
    Quantite: 1,
    Prix: 0,
    Valeur: 0,
    Semaine1: "22",
    Semaine2: "5",
    SemainePlus: "2.5",
    Categories: [],
    Tags: [],
    EnLigne: false,
    Aquisition: new Date(),
    Images: []
  });
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [checkedTags, setCheckedTags] = useState([]);

  const [imagesList, setImagesList] = useState([]);
  const [activeImage, setActiveImage] = useState(defaultIcon);
  
  const [startDate, setStartDate] = useState(new Date());
  const { user, tags, tagsDispatch } = useGlobalContext();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [totalSemaine1, setTotalSemaine1] = useState(0);
  const [totalSemaine2, setTotalSemaine2] = useState(0);
  const [totalSemainePlus, setTotalSemainePlus] = useState(0);

  useEffect(() => {
    const fetchTags = async () => {
      const response = await fetch(`${config.BASE_URL}/api/tags`);
      const json = await response.json();
      tagsDispatch({ type: "SET_TAGS", payload: json });
    };
    if (!tags) {
      fetchTags();
    }
  }, [tags, tagsDispatch]);

  useEffect(() => {
    if (id) {
      fetchProduit();
    }
  }, [id]);

  const fetchProduit = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/produits/${id}`);
      const json = await response.json();
      if (response.ok) {
        setProduit(json);
        setCheckedCategories(json.Categories);
        setCheckedTags(json.Tags);
        setStartDate(new Date(json.Aquisition));
        setImagesList(json.Images || []);
      } else {
        console.error("Failed to fetch product");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    
    } 
  };

  useEffect(() => {
    calculateTotals();
  }, [produit.Valeur, produit.Semaine1, produit.Semaine2, produit.SemainePlus]);


  const calculateTotals = () => {
    const totalSemaine1 = (produit.Valeur * produit.Semaine1) / 100;
    const totalSemaine2 = (produit.Valeur * produit.Semaine2) / 100;
    const totalSemainePlus = (produit.Valeur * produit.SemainePlus) / 100;
    setTotalSemaine1(totalSemaine1);
    setTotalSemaine2(totalSemaine2);
    setTotalSemainePlus(totalSemainePlus);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduit((prevProduit) => ({
      ...prevProduit,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    const Categories = checkedCategories.map((category) => category._id);
    const Tags = checkedTags.map((tag) => tag._id);

    console.log("Categories", Categories);
    console.log("Tags", Tags);
    console.log("Images", imagesList);
    console.log("Produit", produit);
  
    if (!produit.Number) {
      showSnackbar("Vous devez inscrire un numéro", "error");
      return;
    }
    if (!produit.Name) {
      showSnackbar("Vous devez inscrire un nom", "error");
      return;
    }
    if (Categories.length === 0) {
      showSnackbar("Vous devez cocher une catégorie", "error");
      return;
    }
  
    const form = new FormData();
    Object.entries(produit).forEach(([key, value]) => {
      if (key !== "Categories" && key !== "Tags" && key !== "Images") {
        form.append(key, value);
      }
    });
    form.append("Categories", Categories);
    form.append("Tags", Tags);
  
    const sortedImagesList = imagesList.sort((a, b) => a.order - b.order);
    sortedImagesList.forEach((image) => {
      form.append("Images", image.file);
    });
  
    try {
      const response = id
        ? await axios.patch(`${config.BASE_URL}/api/produits/${id}`, form, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.token}`,
            },
          })
        : await axios.post(`${config.BASE_URL}/api/produits`, form, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${user.token}`,
            },
          });
  
      const json = response.data;
      resetForm();
      showSnackbar("Produit enregistré avec succès", "success");
      navigate(-1);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        showSnackbar(error.response.data.message, "error");
      } else {
        showSnackbar("Une erreur est survenue", "error");
      }
    }
  };
  
  const resetForm = () => {
    setCheckedCategories([]);
    setCheckedTags([]);
    setProduit({
      Number: "",
      Name: "",
      Year: "",
      Brand: "",
      Model: "",
      Largeur: 0,
      Profondeur: 0,
      Hauteur: 0,
      Diametre: 0,
      Description: "",
      Quantite: 1,
      Prix: 0,
      Valeur: 0,
      Semaine1: 22,
      Semaine2: 5,
      SemainePlus: 2.5,
      Categories: [],
      Tags: [],
      EnLigne: false,
      Aquisition: new Date(),
      Images: [],
    });
    setImagesList([]);
    setActiveImage(defaultIcon);
  };
  


  
  const getImages = async () => {
    
    const response = await fetch(
      `${config.BASE_URL}/api/images/all/${produit._id}`
    );
    const json = await response.json();
    if (response.ok && json.length > 0) {
      const newImageList = [];
      for (let i = 0; i < json.length; i++) {
        const image = json[i];
        const response = await fetch(`${config.BASE_URL}/imgs/${image.awsKey}`);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const imageObject = { image: url, file: blob, order: image.sortOrder };
        if (i === 0) {
          setActiveImage(imageObject.image);
        }
        newImageList.push(imageObject);
      }
      setImagesList(newImageList);
    } else {
      setActiveImage(defaultIcon);
    }
    
  };


  useEffect(() => {

    if (produit._id) {
      getImages();
    }


  }, [produit._id]);



  return (
    <div className="main-content">
      <Container maxWidth="lg" style={{ paddingBottom: "20px" }}>
        <div className="header">
          <div className="text">
            <div className="title">Produit</div>
            <div className="sub-title">{id ? "Modifier" : "Ajouter"} un produit</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <SaveIcon className="add-icon" onClick={handleClick} />
            </div>
            <div>
              <CancelIcon
                className="add-icon"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ImgBox
              activeImage={activeImage}
              setActiveImage={setActiveImage}
              imagesList={imagesList}
              setImagesList={setImagesList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="shadow-sm cardProductInfo">
              <div>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="NO"
                      name="Number"
                      onChange={handleInputChange}
                      value={produit.Number}
                      required
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Titre"
                      name="Name"
                      onChange={handleInputChange}
                      value={produit.Name}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Marque"
                      name="Brand"
                      onChange={handleInputChange}
                      value={produit.Brand}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Année"
                      name="Year"
                      onChange={handleInputChange}
                      value={produit.Year}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Modèle"
                      name="Model"
                      onChange={handleInputChange}
                      value={produit.Model}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Largeur (cm)"
                      name="Largeur"
                      type="number"
                      onChange={handleInputChange}
                      value={produit.Largeur}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Profondeur (cm)"
                      name="Profondeur"
                      type="number"
                      onChange={handleInputChange}
                      value={produit.Profondeur}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Hauteur (cm)"
                      name="Hauteur"
                      type="number"
                      onChange={handleInputChange}
                      value={produit.Hauteur}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Diamètre (cm)"
                      name="Diametre"
                      type="number"
                      onChange={handleInputChange}
                      value={produit.Diametre}
                      
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={4}
                      label="Description"
                      name="Description"
                      onChange={handleInputChange}
                      value={produit.Description}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Quantité"
                      name="Quantite"
                      type="number"
                      onChange={handleInputChange}
                      value={produit.Quantite}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Prix payé ($)"
                      name="Prix"
                      type="number"
                      onChange={handleInputChange}
                      value={produit.Prix}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Valeur ($)"
                      name="Valeur"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e);
                        
                      }}
                      value={produit.Valeur}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="1re Semaine (%)"
                      name="Semaine1"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e);
                        
                        
                      }}
                      value={produit.Semaine1}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="2e & 3e Semaine (%)"
                      name="Semaine2"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e);
                        
                        
                      }}
                      value={produit.Semaine2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Semaine + (%)"
                      name="SemainePlus"
                      type="number"
                      onChange={(e) => {
                        handleInputChange(e);
                        
                        
                      }}
                      value={produit.SemainePlus}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid item xs={4}>
                    <div>
                      <div>
                        1re Semaine
                      </div>
                      <div>
                        {totalSemaine1.toFixed(2)}$
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <div>
                        2e & 3e Semaine
                      </div>
                      <div>
                        {totalSemaine2.toFixed(2)}$
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <div>
                        Semaine +
                      </div>
                      <div>
                        {totalSemainePlus.toFixed(2)}$
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ marginTop: "15px", textAlign: "center" }}>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={produit.EnLigne}
                          onChange={(e) =>
                            setProduit((prevProduit) => ({
                              ...prevProduit,
                              EnLigne: e.target.checked,
                            }))
                          }
                        />
                      }
                      label="Disponible en ligne"
                    />
                  </div>
                </div>
                <Grid container spacing={2} style={{ marginTop: "15px" }}>
                  <Grid
                    item
                    xs={4}
                    style={{ lineHeight: "32px", textAlign: "right" }}
                  >
                    <Typography variant="body1">Date d'aquisition :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setProduit((prevProduit) => ({
                          ...prevProduit,
                          Aquisition: date,
                        }));
                      }}
                      className="input50 text-center"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>

        <CategoriesCheckboxes
          checkedCategories={checkedCategories}
          setCheckedCategories={setCheckedCategories}
        />

        <TagsCheckboxes
          checkedTags={checkedTags}
          setCheckedTags={setCheckedTags}
        />
      </Container>
    </div>
  );
};

export default AddProduct;
