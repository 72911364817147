import { Grid } from "@mui/material";
import ProjectIcon from "./projectIcon";
import InfoProject from "./infoProject";
import ProjectTeam from "./projectTeam";

const ProjectInfos = ({ newProject, setNewProject, setStateClientList, editing }) => {
  return (
    <div className="details">
      <Grid container spacing={3} style={{ margin: "auto", maxWidth: "700px" }}>
        <Grid item xs={12} md={5} style={{ marginBottom: "10px" }}>
          <ProjectIcon project={newProject} editing={editing} />
        </Grid>
        <Grid item xs={12} md={7}>
          <InfoProject newProject={newProject} />
        </Grid>
      </Grid>

      <ProjectTeam newProject={newProject} setNewProject={setNewProject} editing={editing} setStateClientList={setStateClientList} />
    </div>
  );
};
export default ProjectInfos;
