import React from 'react';
import { Grid, Switch } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DomainIcon from '@mui/icons-material/Domain';
import ContactsIcon from '@mui/icons-material/Contacts';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DvrIcon from '@mui/icons-material/Dvr';

const Dashboards = ({ activeEmploye, setActiveEmploye, editing }) => {
  const handleDashboardSwitchChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'dashboard') {
      // Toggle all dashboards based on the 'dashboard' switch
      const newDashboards = {
        dashboard: checked,
        resume: checked,
        products: checked,
        orders: checked,
        customers: checked,
        employees: checked,
        settings: checked,
        companies: checked,
        invoices: checked,
        expenses: checked,
        logs: checked,
      };

      setActiveEmploye((prevEmploye) => ({
        ...prevEmploye,
        permissions: {
          ...prevEmploye.permissions,
          dashboards: newDashboards,
        },
      }));
    } else {
      // Update individual dashboard switch
      const newDashboards = {
        ...activeEmploye.permissions.dashboards,
        [name]: checked,
      };

      // Determine if any dashboard other than the master switch is on
      const anyDashboardOn = Object.keys(newDashboards).some(
        (key) => key !== 'dashboard' && newDashboards[key]
      );

      setActiveEmploye((prevEmploye) => ({
        ...prevEmploye,
        permissions: {
          ...prevEmploye.permissions,
          dashboards: {
            ...newDashboards,
            dashboard: anyDashboardOn,
          },
        },
      }));
    }
  };

  const dashboards = {
    dashboard: false,
    resume: false,
    products: false,
    orders: false,
    customers: false,
    employees: false,
    settings: false,
    companies: false,
    invoices: false,
    expenses: false,
    logs: false,
    ...activeEmploye.permissions.dashboards,
  };

  return (
    <Grid item xs={12} sm={6} md={4} style={{ marginBottom: '10px' }}>
      <div className="small-header">
        <div className="title" style={{ display: 'flex', alignItems: 'center' }}>
          <DashboardIcon style={{ color: '#f3f3f3', marginRight: '5px' }} />
          <div>Tableau de bord</div>
        </div>
        {editing && 
        <div>
          <Switch
            name="dashboard"
            checked={dashboards.dashboard}
            onChange={handleDashboardSwitchChange}
          />
        </div>
}
      </div>
      {[
        { name: 'resume', label: 'Résumé', Icon: SignalCellularAltIcon },
        { name: 'products', label: 'Produits', Icon: InventoryIcon },
        { name: 'orders', label: 'Commandes', Icon: LocalShippingIcon },
        { name: 'customers', label: 'Clients', Icon: FolderSharedIcon },
        { name: 'invoices', label: 'Factures', Icon: ReceiptIcon },
        { name: 'companies', label: 'Compagnies', Icon: DomainIcon },
        { name: 'employees', label: 'Employés', Icon: ContactsIcon },
        { name: 'expenses', label: 'Dépenses', Icon: AttachMoneyIcon },
        { name: 'logs', label: 'Logs', Icon: DvrIcon },
      ].map(({ name, label, Icon }) => (
        <div
          key={name}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            paddingRight: '10px',
            marginTop: '10px',
          }}
        >
          <div className="title" style={{ display: 'flex', alignItems: 'center' }}>
            <Icon style={{ marginRight: '5px' }} />
            <div>{label}</div>
          </div>
          {
            editing ? (
              <Switch
                name={name}
                checked={dashboards[name]}
                onChange={handleDashboardSwitchChange}
              />
            )
            :  (
              <div>{dashboards[name] ? 'ON' : 'OFF'}</div>
            )}  
          
         
        </div>
      ))}
    </Grid>
  );
};

export default Dashboards;
