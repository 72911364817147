import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";


const ProjectLength = ({ newProject, setNewProject }) => {
  
      
  const [numberOfMonths, setNumberOfMonths] = useState(2);

  const [state, setState] = useState([
    {
      startDate: newProject.dateDepart,
      endDate: newProject.dateRetour,
      key: "selection"
    }
  ]);
  
// Function to calculate the number of months based on screen width
function calculateNumberOfMonths() {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1200) {
    return 3; // Set the number of months for large screens
  } else if (screenWidth >= 768) {
    return 2; // Set the number of months for medium screens
  } else {
    return 1; // Set the number of months for small screens
  }
}

  

  useEffect(() => {
    console.log("State", state);

    setNewProject({
      ...newProject, // Note the lowercase 'n' in 'newProject'
      Start: state[0].startDate,
      End: state[0].endDate
    });
  }, [state]);


  

    useEffect(() => {
        // Update the number of months when the window is resized
        function handleResize() {
          setNumberOfMonths(calculateNumberOfMonths());
        }
    
        // Attach the event listener
        window.addEventListener("resize", handleResize);
        handleResize();
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);



  return (
    <div style={{textAlign:"center", marginTop:"20px"}}>
    <DateRange
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={numberOfMonths}
          ranges={state}
          rangeColors={["#0979be"]}
          direction="horizontal"
          minDate={new Date()}
        />
    </div>
  );
};
export default ProjectLength;




