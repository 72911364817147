import { Container } from "@mui/material";


const Invoices = () => {
    return (
      <div className="main-content">
        <Container maxWidth="lg">
        <div className="header">
          <div className="text">
          <div className="title">Factures</div>
          <div className="sub-title">
            Gérer et consulter les factures de votre système.
            </div>
            </div>
         
        </div>
      </Container>
      </div>
    );
    }
    export default Invoices;