import { useState, useEffect } from "react";
import { useGlobalContext } from "./useGlobalContext";
import config from "../config";
import { useSnackbar } from "../context/snackbarContext";

const useOrder = ({ id }) => {
  const { user } = useGlobalContext();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [Tps, setTps] = useState(0);
  const [Tvq, setTvq] = useState(0);
  const [total, setTotal] = useState(0);
  const { showSnackbar } = useSnackbar();

  const getOrder = async () => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    try {
      const response = await fetch(`${config.BASE_URL}/api/Commandes/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch commande");
      }

      const json = await response.json();
      setOrder(json);
      console.log("ORDER #1", json);
    } catch (error) {
      setIsError(true);
      setError(error);
      showSnackbar(`${error.message}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const calculateSubTotal = (order, totalDays) => {
    if (!order || !totalDays) return 0;

    const subTotal = order.Produits.reduce((total, product) => {
      let pourcentage = 0;

      if (totalDays <= 7) {
        pourcentage = product.produit.Semaine1;
      } else if (totalDays <= 14) {
        pourcentage = product.produit.Semaine1 + product.produit.Semaine2;
      } else if (totalDays <= 21) {
        pourcentage = product.produit.Semaine1 + 2 * product.produit.Semaine2;
      } else {
        pourcentage = product.produit.Semaine1 + 2 * product.produit.Semaine2;
        const additionalDays = totalDays - 21;
        const additionalWeeks = Math.ceil(additionalDays / 7);
        const additionalWeeksTotal = additionalWeeks * product.produit.SemainePlus;
        pourcentage += additionalWeeksTotal;
      }

      const rentalValue = (product.produit.Valeur * pourcentage) / 100;
      const productSubTotal = rentalValue * product.quant;

      return total + productSubTotal;
    }, 0);

    return subTotal;
  };

  const calculateTaxes = (subTotal) => {
    const TPS = ((subTotal * 5) / 100).toFixed(2);
    const TVQ = (((subTotal + parseFloat(TPS)) * 9.975) / 100).toFixed(2);
    return { TPS, TVQ };
  };

  const calculateTotalDays = (order) => {
    if (!order || !order.dateDepart || !order.dateRetour) return 0;

    const startDate = new Date(order.dateDepart);
    const endDate = new Date(order.dateRetour);
    const timeDifference = endDate - startDate;
    return Math.floor(timeDifference / (1000 * 3600 * 24));
  };

  useEffect(() => {
    if (id && user) {
      getOrder();
    }
  }, [id, user]);

  useEffect(() => {
    if (order) {
      const totalDays = calculateTotalDays(order);
      setTotalDays(totalDays);

      const subTotal = calculateSubTotal(order, totalDays);
      setSubTotal(subTotal.toFixed(2));

      const { TPS, TVQ } = calculateTaxes(subTotal);
      setTps(TPS);
      setTvq(TVQ);

      const total = (parseFloat(subTotal) + parseFloat(TPS) + parseFloat(TVQ)).toFixed(2);
      setTotal(total);
      console.log("Total", total);
    }
  }, [order]);

  return {
    isLoading,
    isError,
    error,
    order,
    setOrder,
    totalDays,
    subTotal,
    Tps,
    Tvq,
    total
  };
};

export default useOrder;
