import { Box, Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useNotify } from "../../../hooks/useNotify";

const EditModal = ({
  show,
  handleClose,
  data,
  setDataList,
  action,
  from,
  chemin
}) => {
  const [newData, setNewData] = useState(data);
  const { user } = useGlobalContext();

  const { notify } = useNotify();

  // Add new data to the database
  const addData = async () => {
    if (!newData.Name) {
      notify("error", "Please enter a valid name");
      return;
    }

    try {
      const response = await fetch(
        `${config.BASE_URL}/api/settings/${from}/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          },
          body: JSON.stringify(newData)
        }
      );
      const data = await response.json();
      setDataList((prev) => [...prev, data]);
      handleClose();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteData = async () => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/settings/${from}/delete/${data._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          }
        }
      );
  
      if (response.status === 400) {
        const responseData = await response.json();
        notify("error", responseData.error); // Display error message from backend
        return;
      }
  
      const returnData = await response.json();
      setDataList((prev) => prev.filter((item) => item._id !== returnData._id));
      handleClose();
      console.log("Le retour du back", returnData);
    } catch (error) {
      console.log(error);
      notify("error", error.message);
    }
  };
  
  const saveData = async () => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/settings/${from}/save/${newData._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          },
          body: JSON.stringify(newData)
        }
      );
      const returnData = await response.json();
      setDataList((prev) =>
        
        prev.map((item) => (item._id === returnData._id ? returnData : item))
      );
      handleClose();
      console.log(returnData);
    }
    catch (error) {
      console.log(error);
    }
  };


  const handleClick = () => {
    if (action.type === "edit") {
      console.log("Saving ", from, " with data: ", newData);
      saveData();
    }
    if (action.type === "add") {
      console.log("Adding a ", from, " with data: ", newData);

      addData();
    }
    if (action.type === "delete") {
      console.log("Deleting ", from, " with data: ", newData);
      deleteData();
    }
  };


  useEffect(() => {
    setNewData(data);
  }, [data]);

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          timeout: 500,
          sx: {
            backgroundColor: "rgba(31, 37, 48, 0.1)",
            backdropFilter: "blur(5px)"
          }
        }}
      >
        <Box
          className="sub-box-flex"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            backgroundColor: "white",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px 0px #465363",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>{action.title}</div>
          {chemin && action.type !== "delete" && <div>{chemin}</div>}
          <div style={{ marginTop: "10px", padding:"0px" }}>
            {action.type != "delete" && (
              <input
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ddd",
                  width: "325px",
                  
                }}
                type="text"
                placeholder="Nom"
                value={newData.Name}
                onChange={(e) =>
                  setNewData({ ...newData, Name: e.target.value })
                }
              />
            )}

            {action.type === "delete" && <div>{data.Name}</div>}
          </div>
          <Button
            className="btn-primary"
            onClick={handleClick}
            variant="contained"
            style={{ marginTop: "10px" }}
          >
            {action.type === "edit" && "Enregistrer"}
            {action.type === "add" && "Ajouter"}
            {action.type === "delete" && "Supprimer"}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default EditModal;
