import { Link } from "react-router-dom";
import "../card.css";

import { Card, Grid } from "@mui/material";

import greenDot from "../../../assets/images/green-dot.png";
import redDot from "../../../assets/images/red-dot.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";

const EmployeCard = ({ employe }) => {
  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      lg={2}
      style={{ padding: "5px", marginTop: "0px" }}
    >
      <Link className="noDecoration" to={`/Employé/${employe._id}`}>
        <Card className="card">
          <div className="img">
            <img src={employe.user.avatar} alt="" />
          </div>
          <div className="card-title">
            {employe.user.userFirstName} {employe.user.userLastName}
          </div>
          <div className="card-subtitle">
            <PhoneIphoneIcon className="small-icon" /> {employe.user.userCell}
          </div>
          <div className="card-subtitle">
            <EmailIcon className="small-icon" /> {employe.user.userEmail}
          </div>
          <div>
            {employe.userVerified && (
              <>
                <img src={greenDot} className="dot" alt="" />
                <span>Verified</span>
              </>
            )}
            {!employe.user.userVerified && (
              <div
                style={{
                  fontSize: "12px",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <img src={redDot} className="dot" alt="" />
                <span style={{ marginLeft: "5px", fontSize: "11px" }}>
                  Not verified
                </span>
              </div>
            )}
          </div>
        </Card>
      </Link>
    </Grid>
  );
};

export default EmployeCard;
