import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";
import { useEffect, useState } from "react";
import config from "../../config";
import { Container } from "@mui/material";
import Loader from "../../components/loader";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MyOrders from "../orders/components/myOrders";
import MyInvoices from "../invoices/components/myInvoices";
import EditProject from "./components/editProject";
import ProjectInfos from "./components/projectInfos";
import AddWorker from "./components/addWorker";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { set } from "date-fns";

const ProjectDetails = () => {
  const params = useParams();
  const { id } = params;
  const { user } = useGlobalContext();
  const { notify } = useNotify();
  const [isLoading, setIsloading] = useState(true);
  const [newProject, setNewProject] = useState();

  const [activeProject, setActiveProject] = useState();
  const [editing, setEditing] = useState(false);

  const [stateClientList, setStateClientList] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false
  });

  const handleSaveProject = async () => {
    const newProjet = {
      Nom: newProject.Nom,
      projectType: newProject.projectType._id,
      Compagnie: newProject.Compagnie._id,
      Start: newProject.Start,
      End: newProject.End,
      Equipe: newProject.Equipe,
      logo: newProject.logo
    };

    try {
      const response = await fetch(`${config.BASE_URL}/api/projects/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify(newProjet)
      });
      if (!response.ok) {
        throw new Error("Failed to update prroject details");
      }
      const json = await response.json();
      notify("success", "Projet sauvegarder avec succès");
      setEditing(false);
      setActiveProject(json);
      setNewProject(json);
    } catch (error) {
      notify("error", error.message);
    }
  };

  const getProject = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/projects/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch project details");
      }
      const json = await response.json();
      if (json.length != 0) {
        setActiveProject(json);
        setNewProject(json);
      }
    } catch (error) {
      notify("error", error.message); // Set error message in state variable
    }
    setIsloading(false);
  };

  useEffect(() => {
    console.log("Active projet Equipe", activeProject);
    setNewProject(activeProject);
    if (
      activeProject &&
      activeProject.Equipe.length !== newProject.Equipe.length
    ) {
      console.log(
        "The number of team mates have change",
        activeProject.Equipe.length
      );

      //handleSaveProject()
    }
  }, [activeProject]);

  useEffect(() => {
    if (user) {
      getProject();
    }
  }, [user]);

  return (
    <div className="main-content">
      {isLoading && (
        <Container maxWidth="lg" style={{ height: "100%" }}>
          <Loader />
        </Container>
      )}
      {!isLoading && (
        <Container maxWidth="lg">
          <div className="header">
            <div className="text">
              <div className="title">Projet</div>
              <div className="sub-title">Détails du projet.</div>
            </div>
            {!editing ? (
              <div style={{ display: "flex" }}>
                <div
                  className="add-icon"
                  title="Modifier la compagnie"
                  onClick={() => setEditing(true)}
                >
                  <EditIcon />
                </div>
                <div className="add-icon" title="Archiver la compagnie">
                  <ArchiveIcon />
                </div>
                <div className="add-icon" title="Supprimer la compagnie">
                  <DeleteIcon />
                </div>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <div
                  className="add-icon"
                  title="Enregistrer la compagnie"
                  onClick={() => handleSaveProject()}
                >
                  <SaveIcon />
                </div>
                <div
                  className="add-icon"
                  title="Annuler"
                  onClick={() => setEditing(false)}
                >
                  <CancelIcon />
                </div>
              </div>
            )}
          </div>

          <div className="details">
            {!editing && activeProject && (
              <>
                <ProjectInfos
                  newProject={activeProject}
                  setNewProject={setActiveProject}
                  editing={editing}
                  setStateClientList={setStateClientList}
                />
              </>
            )}
            {editing && newProject && (
              <EditProject
                newProject={newProject}
                setNewProject={setNewProject}
                editing={editing}
                setStateClientList={setStateClientList}
              />
            )}
          </div>
          {!editing && (
            <div>
              <MyOrders projectId={id} />
              <MyInvoices projectId={id} />
            </div>
          )}
        </Container>
      )}
      <SlidingPane
        width="250px"
        isOpen={stateClientList.isPaneOpen}
        title="Ajouter des clients"
        subtitle="Projet"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setStateClientList({ isPaneOpen: false });
        }}
      >
        <AddWorker
          setStateClientList={setStateClientList}
          newProject={activeProject}
          setNewProject={setActiveProject}
        />
      </SlidingPane>
    </div>
  );
};
export default ProjectDetails;
