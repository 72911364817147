import { Card, Grid } from "@mui/material";
import orderIcon from "./imgs/commandes.png";
import { useEffect } from "react";
import "../orderCard.css";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import UserIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";


const OrderCard = ({ order }) => {
  useEffect(() => {
    console.log(order);
  }, []);
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ padding: "10px" }}>
      <Link to={`/Commande/${order._id}`} className="noDecoration">
        <Card className="card-order">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px"
            }}
          >
            <img
              src={order.myCompany.companyLogo || orderIcon}
              alt=""
              style={{ width: "75%" }}
            />
          </div>

          <div className="card-title">
            <div style={{ textTransform: "none" }}>Numéro de la commande</div>
            <div style={{ color: "#61dafb", fontWeight: "normal" }}>
              {order.Numero}
            </div>
          </div>
          <div
            className="card-subtitle"
            style={{
              marginLeft: "5px",
              fontWeight: "bold",
              borderBottom: "2px solid #2c3e50",
              paddingBottom: "5px"
            }}
          >
            Commande pour client
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div style={{ width: "33%" }}>
              {order.commandePour.avatar ? (
                <img
                  src={order.commandePour.avatar}
                  alt=""
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              ) : (<UserIcon />)}
              
            </div>
            <div style={{ paddingLeft: "10px", width: "60%" }}>
              <div className="card-title" style={{ width: "95%" }}>
                {order && <>{order.commandePour.userFirstName}</>}
                {order && <> {order.commandePour.userLastName}</>}
              </div>
              <div className="card-subtitle">
                <PhoneIphoneIcon className="small-icon" />{" "}
                {order && <>{order.commandePour.userCell}</>}
              </div>
              <div className="card-subtitle">
                <EmailIcon className="small-icon" />{" "}
                {order && <>{order.commandePour.userEmail}</>}
              </div>
            </div>
          </div>
          <div
            className="card-subtitle"
            style={{
              marginLeft: "5px",
              fontWeight: "bold",
              borderBottom: "2px solid #2c3e50",
              paddingBottom: "5px",
              marginTop:"5px"
            }}
          >
            Projet
          </div>
          {order.Projet ? (
            <div>
              <div
                style={{
                  padding: "5px",
                  textTransform: "capitalize",
                  fontWeight: "bold"
                }}
              >
                {order.Projet.Nom}
              </div>
              <div style={{marginLeft:"5px", fontSize:"12px"}}>{order.Projet.projectType.Name}</div>
            </div>
          ) : (
            <div
              style={{ textAlign: "center", fontSize: "12px", padding: "5px" }}
            >
              Aucun Projet
            </div>
          )}
          <div
            className="card-subtitle"
            style={{
              marginLeft: "5px",
              fontWeight: "bold",
              borderBottom: "2px solid #2c3e50",
              paddingBottom: "5px",
              marginTop:"5px"
            }}
          >
            Compagnie
          </div>
          {order.Company ? (
            <div>
              <div
                style={{
                  padding: "5px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  
                }}
              > {order.Company.companyName}
              </div>
              
            </div>
          ) : (
            <div
              style={{ textAlign: "center", fontSize: "12px", padding: "5px" }}
            >
              Aucune Compagnie
            </div>
          )}

          <div className="card-title2" style={{ marginTop: "10px" }}>
            <div>Statut</div>
            <div style={{ color: "#61dafb", fontWeight: "normal" }}>
              {order.statut}
            </div>
          </div>
        </Card>
      </Link>
    </Grid>
  );
};

export default OrderCard;
