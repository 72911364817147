import LocalphoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const InfoCompany = ({ company }) => {
  return (
    <>
      <div className="infos">
        <div className="details-title">{company.companyName}</div>
        <div className="details-subtitle">
          {" "}
          <EmailIcon className="small-icon" /> {company.companyEmail}{" "}
        </div>
        <div className="details-subtitle">
          {" "}
          <LocalphoneIcon className="small-icon" /> {company.companyTel}
        </div>
        <div className="details-subtitle">
          {company.website && (
            <Link
            to={company.website}
            target="_blank"
            className="noDecoration details-subtitle"
          >
            <LinkIcon className="small-icon" /> {company.website}
          </Link>
          )}
          
        </div>
        {company.myCompany && (
        <>
        <div className="details-subtitle">
           <div style={{marginRight:"5px", fontSize:"10px", backgroundColor:"#232f3e", borderRadius:"3px", paddingTop:"2px", paddingBottom:"2px", paddingLeft:"4px", paddingRight:"4px", color:"#f3f3f3", fontWeight:"bold", letterSpacing:"1px"}}>TPS</div> {company.TPSnumber}
        </div>
        <div className="details-subtitle">
           <div style={{marginRight:"5px",fontSize:"10px", backgroundColor:"#232f3e", borderRadius:"3px", paddingTop:"2px", paddingBottom:"2px", paddingLeft:"4px", paddingRight:"4px", color:"#f3f3f3", fontWeight:"bold", letterSpacing:"1px"}}>TVQ</div> {company.TVQnumber}
        </div>
        </>
        )}

      </div>

      {company.companyAddresses &&
        company.companyAddresses.map((address, index) => {
          return (
            <div key={index} className="addressCard">
              <div className="name">{address.label}</div>
              <div>{address.address}</div>
              <div>
                {address.city}
                {address.state && `, ${address.state}`}
              </div>
              <div>
                {address.country}
                {address.postalCode && `, ${address.postalCode}`}
              </div>
              <div>{address.telephone}</div>
            </div>
          );
        })}
    </>
  );
};

export default InfoCompany;
