//import "../css/account.css";

import { Button } from "@mui/material";

import { useEffect, useState } from "react";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import greenDot from "../../../assets/images/green-dot.png";
import redDot from "../../../assets/images/red-dot.png";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import CakeIcon from "@mui/icons-material/Cake";
import WorkIcon from "@mui/icons-material/Work";
import EventIcon from "@mui/icons-material/Event";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const UserInfo = ({ employe }) => {
  const [userAge, setUserAge] = useState();

  useEffect(() => {
    if (employe.user.userBirthDate) {
      const birthDate = new Date(employe.user.userBirthDate);
      const ageDifMs = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDifMs);
      setUserAge(Math.abs(ageDate.getUTCFullYear() - 1970));
    }
  }, [employe.user.userBirthDate]);

  const formatDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da}/${mo}/${ye}`;
  };


  function getEmployeEstimeLabel(nombreEstime) {
    if (!nombreEstime) {
      return "non défini";
    }
    if (employe.salaire.method === "jour" && nombreEstime === 1) {
      return "jour par semaine.";
    }
    if (employe.salaire.method === "jour" && nombreEstime > 1) {
      return "jours par semaine.";
    }

    return "heures par semaine.";
  }
  

  return (
    <div>
      <div className="infos">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="details-title"
            style={{
              padding: "10px",
              fontSize: "20px",
              letterSpacing: "1px",
              width: "100%",
              textAlign: "center"
            }}
          >
            {employe.user.userFirstName} {employe.user.userLastName}
          </div>
        </div>
        <div>
          {employe.user.userVerified && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={greenDot} className="dot-big" alt="" />
              <span className="details-subtitle">Compte Actif</span>
            </div>
          )}

          {!employe.user.userVerified && (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <img src={redDot} className="dot" alt="" />
              <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                Compte Inactif
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          <WorkIcon className="small-icon" />
          <div className="details-subtitle">{employe.poste.Name}</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <EmailIcon className="small-icon" />
          <div className="details-subtitle">{employe.user.userEmail}</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <PhoneIphoneIcon className="small-icon" />
          <div className="details-subtitle">{employe.user.userCell}</div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",

            alignItems: "center",
            marginTop: "10px"
          }}
        >
          {employe.user.userBirthDate && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CakeIcon className="small-icon" />
              <div>
                Date de naissance: {formatDate(employe.user.userBirthDate)} (
                {userAge} ans)
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px"
          }}
        >
          <EventIcon className="small-icon" />
          <div className="details-subTitle">
            Date de début :{" "}
            {employe.dateDebut ? formatDate(employe.dateDebut) : "Non défini"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <EventIcon className="small-icon" />
          <div className="details-subTitle">
            Date de fin :{" "}
            {employe.user.dateFin ? formatDate(employe.dateFin) : "Non défini"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px"
          }}
        >
          <div
            style={{
              marginRight: "5px",
              fontSize: "10px",
              backgroundColor: "#232f3e",
              borderRadius: "3px",
              paddingTop: "2px",
              paddingBottom: "2px",
              paddingLeft: "4px",
              paddingRight: "4px",
              color: "#f3f3f3",
              fontWeight: "bold",
              letterSpacing: "1px"
            }}
          >
            NAS
          </div>{" "}
          : {employe.NAS ? employe.NAS : "non défini"}
        </div>
        <div>
          {employe.user.userGender && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
                padding: "5px"
              }}
            >
              <div
                style={{
                  marginRight: "5px",
                  fontSize: "10px",
                  backgroundColor: "#232f3e",
                  borderRadius: "3px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  color: "#f3f3f3",
                  fontWeight: "bold",
                  letterSpacing: "1px"
                }}
              >
                Sexe
              </div>
              <div> : {employe.user.userGender}</div>
              <div>
                {employe.user.userGender === "male" ? (
                  <MaleIcon />
                ) : (
                  <FemaleIcon />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            alignItems: "center"
          }}
        >
          <AdminPanelSettingsIcon className="small-icon" />
          <div style={{ display: "flex", alignItems: "center" }}>
            Administrateur{" "}
            <div
              style={{
                fontSize: "12px",
                marginLeft: "3px",
                marginRight: "3px"
              }}
            >
              {" "}
              (affiche les montants){" "}
            </div>{" "}
            :
          </div>
          <div style={{ marginLeft: "5px" }}>
            {" "}
            {employe.user.isAdmin ? "Oui" : "Non"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          <MonetizationOnIcon className="small-icon" />
          <div className="details-subTitle">
            Salaire :{" "}
            {employe.salaire
              ? employe.salaire.montant + "$ / " + employe.salaire.method
              : "non défini"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          <AccessTimeIcon className="small-icon" />
          <div className="details-subTitle">
            {employe.typeHoraire ? employe.typeHoraire : "non défini"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
         <div className="details-subTitle">
  {`${employe.nombreEstime ? employe.nombreEstime + " " : ""}${getEmployeEstimeLabel(employe.nombreEstime)}`}
</div>
        </div>
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ marginRight: "10px" }}>
            Code de sécurité (mode iPad)
          </div>
          <div className="codeSecurityDigit">
            {employe.codeSecurity && employe.codeSecurity.digit1}
          </div>
          <div className="codeSecurityDigit">
            {employe.codeSecurity && employe.codeSecurity.digit2}
          </div>
          <div className="codeSecurityDigit">
            {employe.codeSecurity && employe.codeSecurity.digit3}
          </div>
          <div className="codeSecurityDigit">
            {employe.codeSecurity && employe.codeSecurity.digit4}
          </div>
        </div>
      </div>
      <div className="small-header">
        <div className="title">Adresses</div>
      </div>
      {employe.user.userAddresses &&
        employe.user.userAddresses.map((address, index) => {
          return (
            <div key={index} className="addressCard">
              <div className="main-name">{address.label}</div>
              <div className="sub-name">{address.address}</div>
              <div className="sub-name">
                {address.city}
                {address.state && `, ${address.state}`}
              </div>
              <div className="sub-name">
                {address.country}
                {address.postalCode && `, ${address.postalCode}`}
              </div>
              <div className="sub-name">{address.telephone}</div>
            </div>
          );
        })}
    </div>
  );
};

export default UserInfo;
