import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { useGlobalContext } from "../../../hooks/useGlobalContext";

const UserMenu = ({showMouseOver, setShowMouseOver}) => {

  const {user, userDispatch}= useGlobalContext();

  const navigate = useNavigate();

  const handleCloseMenu = () => {
    setShowMouseOver({
        ...showMouseOver,
        userMenu: false
    });
  };

  const handleDisconnect = () => {
    // delete user from local storage and redirect to login page
    localStorage.removeItem("user");
    userDispatch({ type: "LOGOUT" });
    navigate("/")
  

  }


  return (
    <div className="user-menu"
    
    onMouseLeave={() => {
      handleCloseMenu()
    }
    }       
    >

      <div style={{display:"flex", alignItems:"center", padding:"5px"}}>
        <img src={user.avatar} alt="avatar" className="avatar" style={{borderRadius:"15px"}} />
          <div style={{marginLeft:"10px", fontSize:"12px"}}>{user.userFirstName} {user.userLastName}</div>
      </div>
      <div className="horizontal-separator"></div>
      <Link to="/compte">
      <div className="user-menu-item"
      onClick={()=>{handleCloseMenu()}}
      >
        
          Compte
          <AccountCircleIcon className="icon" />
        
      </div>
      </Link>
      <Link to="/dashboard">
      <div className="user-menu-item"
      onClick={()=>{handleCloseMenu()}}
      >
        
          Tableau de bord
          <SpaceDashboardIcon className="icon" />
      
      </div>
      </Link>
      <Link to="/paramètres">
      <div className="user-menu-item"
      onClick={()=>{handleCloseMenu()}}
      >
        
          Paramètres
          <SettingsIcon className="icon" />
        
      </div>
      </Link>
      <div className="horizontal-separator"></div>
      <div className="user-menu-item"
      onClick={()=>{handleDisconnect()}}
      
      >
        
          Déconnexion
          <ExitToAppIcon className="icon" />
        
      </div>
    </div>
  );
};

export default UserMenu;
