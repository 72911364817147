import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { Card, Grid } from "@mui/material";
import config from "../../../config";
import AddBox from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditModal from "./editModal";
import { useNotify } from "../../../hooks/useNotify";

const Jobs = () => {
  const { user } = useGlobalContext();
  const { notify } = useNotify();

  const [jobsEmployes, setJobsEmployes] = useState([]);
  const [jobsProjects, setJobsProjects] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalProject, setShowModalProject] = useState(false);

  const [action, setAction] = useState({
    type: "",
    title: ""
  });
  const [activeJob, setActiveJob] = useState({
    _id: "",
    Name: "",
    Client: false
  });
  const [activeJobProject, setActiveJobProject] = useState({
    _id: "",
    Name: "",
    Client: true
  });

  const handleAdd = (jopType) => {
    console.log("Adding Job Type");

    if (jopType === "employe") {
      console.log("Adding Job Type : ", jopType);
      setActiveJob({
        _id: "",
        Name: "",
        Client: false
      });
      setAction({ type: "add", title: "Ajouter un type de poste d'employé" });
      handleShow();
    }
    if (jopType === "project") {
      console.log("Adding Job Type : ", jopType);
      setActiveJobProject({
        _id: "",
        Name: "",
        Client: true
      });
      setAction({ type: "add", title: "Ajouter un type de poste de projet" });
      handleShowProject();
    }
  };

  const handleDoubleClick = (e, jobType) => {
    if (jobType === "employe") {
      setAction({ type: "edit", title: "Modifier un type de poste d'employé" });
      handleShow();
    }
    if (jobType === "project") {
      setAction({ type: "edit", title: "Modifier un type de poste de projet" });
      handleShowProject();
    }
  };

  const handleDelete = (e, jobType) => {
    if (jobType === "employe") {
      if (activeJob._id === "") {
        notify(
          "error",
          "Veuillez sélectionner un type de poste d'employé à supprimer"
        );
        return;
      }

      setAction({
        type: "delete",
        title: "Supprimer un type de poste d'employé"
      });
      handleShow();
    }
    if (jobType === "project") {
      if (activeJobProject._id === "") {
        notify(
          "error",
          "Veuillez sélectionner un type de poste de projet à supprimer"
        );
        return;
      }
      setAction({
        type: "delete",
        title: "Supprimer un type de poste de projet"
      });
      handleShowProject();
    }
  };

  const handleClick = (e, jobType) => {
    console.log(e.target.getAttribute("data"));

    switch (jobType) {
      case "employe":
        setActiveJob({
          _id: e.target.getAttribute("data"),
          Name: e.target.textContent,
          Client: false
        });
        break;
      case "project":
        setActiveJobProject({
          _id: e.target.getAttribute("data"),
          Name: e.target.textContent,
          Client: true
        });
        break;
      default:
        break;
    }
  };

  const handleShow = () => {
    setShowModal(!showModal);
  };

  const handleShowProject = () => {
    setShowModalProject(!showModalProject);
  };

  const fetchAllJobs = async () => {
    console.log("Fetching Jobs")
    const response = await fetch(`${config.BASE_URL}/api/settings/jobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`
      }
    });
    const data = await response.json();
    console.log(data);
    setJobsEmployes(data.filter((job) => job.Client === false));
    setJobsProjects(data.filter((job) => job.Client === true));
  };

  useEffect(() => {
    if(user) fetchAllJobs();
  }, [user]);

  return (
    <div>
      <div className="sub-header">
        <div className="text">
          <div className="title">Postes employés & projets</div>
          <div className="sub-title">
            Ajouter, modifier ou supprimer des postes d'employés ou de projets.
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Postes employés</div>
              <div className="content">
                {jobsEmployes.map((job) => (
                  <div
                    onClick={(e) => {
                      handleClick(e, "employe");
                    }}
                    onDoubleClick={(e) => {
                      handleDoubleClick(e, "employe");
                    }}
                    data={job._id}
                    key={job._id}
                    className={activeJob._id === job._id ? "tag active" : "tag"}
                  >
                    {job.Name}
                  </div>
                ))}
              </div>
              <div className="footer">
                <div
                  className="btn"
                  title="Ajouter un type de poste"
                  onClick={() => {
                    handleAdd("employe");
                  }}
                >
                  <AddBox />
                </div>

                <div
                  className="btn"
                  title="Supprimer le type de poste"
                  onClick={(e) => {
                    handleDelete(e, "employe");
                  }}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Postes projets</div>
              <div className="content">
                {jobsProjects.map((job) => (
                  <div
                    onClick={(e) => {
                      handleClick(e, "project");
                    }}
                    onDoubleClick={(e) => {
                      handleDoubleClick(e, "project");
                    }}
                    data={job._id}
                    key={job._id}
                    className={
                      activeJobProject._id === job._id ? "tag active" : "tag"
                    }
                  >
                    {job.Name}
                  </div>
                ))}
              </div>
              <div className="footer">
                <div
                  className="btn"
                  title="Ajouter un type de poste"
                  onClick={() => {
                    handleAdd("project");
                  }}
                >
                  <AddBox />
                </div>

                <div
                  className="btn"
                  title="Supprimer le type de poste"
                  onClick={(e) => {
                    handleDelete(e, "project");
                  }}
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <EditModal
        show={showModal}
        handleClose={handleShow}
        data={activeJob}
        setDataList={setJobsEmployes}
        action={action}
        from="jobs"
      />
      <EditModal
        show={showModalProject}
        handleClose={handleShowProject}
        data={activeJobProject}
        setDataList={setJobsProjects}
        action={action}
        from="jobs"
      />
    </div>
  );
};
export default Jobs;
