import React from "react";
import defaultImage from "../../../assets/images/imgIcon.png";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import config from "../../../config";
import { Card, Grid } from "@mui/material";
import { useSnackbar } from "../../../context/snackbarContext";
import { Link } from "react-router-dom";
import pointVert from "../../../assets/images/green-dot.png";
import pointRouge from "../../../assets/images/red-dot.png";

const ProductCard = React.forwardRef(
  ({ product }, ref) => {
    const [enStock, setEnStock] = useState(null);
    const [image, setImage] = useState();
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
      getImage();
    }, [product]);

    const getImage = async () => {
      if (product.Image) {
        setImage(product.Image);
      } else {
        const response = await fetch(
          `${config.BASE_URL}/api/images/${product._id}`
        );
        try {
          const json = await response.json();
          if (response.ok) {
            const img = new Image();
            img.onload = async () => {
              const imageUrl = await getSignedURL(json.awsKey);
              setImage(imageUrl);
              
            };
            img.onerror = () => {
              console.log("Error loading image. Using default image.");
              setImage(defaultImage);
            };
            img.src = await getSignedURL(json.awsKey);
          } else {
            setImage(defaultImage);
          }
        } catch (error) {
          setImage(defaultImage);
        }
      }
    };

    const getSignedURL = async (awsKey) => {
      const response = await fetch(
        `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
      );
      if (response.ok) {
        const data = await response.json();
        return data.signedUrl;
      } else {
        return defaultImage;
      }
    };

    

    useEffect(() => {
      var stockLigne;
      if (product.Quantite > 1) {
        stockLigne = product.Quantite + " de disponibles";
        setEnStock(stockLigne);
      } else {
        stockLigne = product.Quantite + " de disponible";
        setEnStock(stockLigne);
      }
    }, [product.Quantite]);

    const productBody = (
      <>
        <Link className="noDecoration" to={`/Produit/${product._id}`}>
          <Card className="card-item">
            <div className="card-item-img">
              {image && <img src={image} alt="" />}
            </div>
            <div className="card-title">
              {product.Number} - {product.Name}
            </div>
            <div className="card-dispo">
              <div className="disponibleCard">{enStock}</div>
             
                <img style={{width:"15px"}} src={product.EnLigne ? pointVert : pointRouge} />
             
            </div>
          </Card>
          
        </Link>
      </>
    );

    const content = ref ? (
      <Grid item xs={3} lg={2} ref={ref}>
        {productBody}
      </Grid>
    ) : (
      <Grid item xs={3} lg={2}>{productBody}</Grid>
    );

    return content;
  }
);

export default ProductCard;
