import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar";

import "./css/dashboard.css";
import { useEffect } from "react";

const Dashboard = () => {
  return (
    <div className="main-content">
      <div className="dashboard-container">
        <Sidebar />

        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
