import { Container } from "@mui/material";


const DashboardProjects = () => {

    return (
        <Container maxWidth="lg">
            <div className="header">
                <div className="text">
                    <div className="title">Projets</div>
                    <div className="sub-title">Résumé des projets.</div>
                    
                </div>
            </div>
        </Container>
    )
}

export default DashboardProjects;