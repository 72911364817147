import axios from "axios";
import config from "../config";

export const api = axios.create({
  baseURL: `${config.BASE_URL}/api`,
});

export const getProductsPage = async (searching, searchQuery, options = {}) => {
  const queryParams = new URLSearchParams();

  queryParams.set("page", searching.page);
  queryParams.set("limit", searching.limit);

  if (searchQuery) queryParams.set("search", searchQuery);

  if (searching.categorie)
    queryParams.set(
      "category",
      searching.categorie == "Tous"
        ? searching.categorie
        : searching.categorie._id
    );
  if (searching.decor)
    queryParams.set(
      "decor",
      searching.decor == "Tous" ? searching.decor : searching.decor._id
    );

  if (searching.sortBy) queryParams.set("sortBy", searching.sortBy);
  if (searching.orderBy) queryParams.set("sortOrder", searching.orderBy);
  if (searching.start) queryParams.set("start", searching.start);
  if (searching.end) queryParams.set("end", searching.end);
  queryParams.set("includeArchived", searching.includeArchived);
  

  const response = await api.get(`/produits/search/?${queryParams.toString()}`);

  console.log("Réponse ", response.data);

  return response.data;
};
