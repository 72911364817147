import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductDetails from "./components/productDetails";
import config from "../../config";
import Loader from "../../components/loader";
import { Container } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeleteIcon from "@mui/icons-material/Delete";
import ProductRentalsCalendar from "./components/productRentalsCalendar";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useSnackbar } from "../../context/snackbarContext";


const calculateNumberOfMonths = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1200) {
    return 3;
  } else if (screenWidth >= 768) {
    return 2;
  } else {
    return 1;
  }
};



const Details = () => {
  const { user, scrollPosition } = useGlobalContext();
  const [numberOfMonths, setNumberOfMonths] = useState(calculateNumberOfMonths());
  const [rangeColors, setRangeColors] = useState(["#232f3e"]);
  const [produit, setProduit] = useState();
  const [rentals, setRentals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();


  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    fetchProduit();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    console.log("scrollPosition", scrollPosition);
    const handleResize = () => setNumberOfMonths(calculateNumberOfMonths());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchProduit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/produits/${id}`);
      const json = await response.json();
      if (response.ok) {
        setProduit(json);
        setRentalsInCalendar(json);
        setRangeColors(generateContrastColors("#232f3e", json.rentals.length + 1)); // +1 for the default range
      } else {
        console.error("Failed to fetch product");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const setRentalsInCalendar = (json) => {
    const newRentals = json.rentals.map((rental, index) => ({
      startDate: new Date(rental.start),
      endDate: new Date(rental.end),
      key: `rental_${index}`
    }));

    setRentals([
      { startDate: new Date(), endDate: new Date(), key: "selection" },
      ...newRentals
    ]);
  };

  const generateContrastColors = (baseColor, amount) => {
    const colors = [baseColor];
    const baseColorRGB = hexToRgb(baseColor);
    for (let i = 1; i < amount; i++) {
      const newColor = adjustBrightness(baseColorRGB, i * 20);
      colors.push(rgbToHex(newColor));
    }
    return colors;
  };

  const hexToRgb = (hex) => {
    hex = hex.replace("#", "");
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  const adjustBrightness = (rgb, amount) => {
    const [r, g, b] = rgb;
    return [
      clamp(r + amount, 0, 255),
      clamp(g + amount, 0, 255),
      clamp(b + amount, 0, 255)
    ];
  };

  const rgbToHex = (rgb) => {
    return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
  };

  const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

  const deleteProduct = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this product?");
    if (!confirmed) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/produits/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        }
      });
      if (response.ok) {
        console.log("Product deleted");
      } else {
        console.error("Failed to delete product");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const archiveProduct = async () => {
    const confirmed = window.confirm("Are you sure you want to archive this product?");
    if (!confirmed) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/produits/archive/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify({ archived: true })
      });
      if (response.ok) {
        console.log("Product archived");
      } else {
        console.error("Failed to archive product");
      }
    } catch (error) {
      console.error("Error archiving product:", error);
    } finally {
      setIsLoading(false);
      navigate(-1)
    }
  };

  
  const unArchiveProduct = async () => {
    const confirmed = window.confirm("Are you sure you want to archive this product?");
    if (!confirmed) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/produits/archive/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify({ archived: false })
      });
      if (response.ok) {
        console.log("Product archived");
      } else {
        console.error("Failed to archive product");
      }
    } catch (error) {
      console.error("Error archiving product:", error);
    } finally {
      setIsLoading(false);
      navigate(-1)
    }
  };

  return (
    <div className="main-content">
      <Container maxWidth="lg" style={{ paddingBottom: "20px" }}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              height: "400px",
              paddingTop: "100px",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="header">
              <div className="title">
                {produit.Number} - {produit.Name}
              </div>
              <div className="icons-container">
                <Link to={`/Produit/Modifier/${id}`}>
                  <EditIcon className="add-icon" title="Modifier le produit" />
                </Link>
                {
                  produit.archived ? <UnarchiveIcon className="add-icon" title="Désarchiver le produit" onClick={unArchiveProduct} /> : <ArchiveIcon className="add-icon" title="Archiver le produit" onClick={archiveProduct} />
                }
                
                <DeleteIcon className="add-icon" title="Supprimer le produit" onClick={deleteProduct} />
              </div>
            </div>
            <ProductDetails
              product={produit}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <div className="header">
              <div className="title">Disponibilités</div>
            </div>
            <ProductRentalsCalendar rentals={rentals} />
          </>
        )}
      </Container>
    </div>
  );
};

export default Details;
