import { Container, Grid, Snackbar, Alert } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import config from "../../config";
import Loader from "../../components/loader";
import OrderCard from "./components/orderCard";
import SearchBar from "./components/searchBar";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditOrder from "./components/editOrder";
import { Link, useNavigate } from "react-router-dom";

const Orders = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const [allOrders, setAllOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [newOrder, setNewOrder] = useState({
    Numero: "",
    statut: "En cours",
    dateDepart: new Date(),
    dateRetour: new Date(),
    myCompany: "",
    Produits: [],
  });
  
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/Commandes`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        setAllOrders(json);
        setFilteredOrders(json);
      } else {
        throw new Error(json.mssg || "Failed to fetch orders");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({ open: true, message: error.message, severity: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateOrder = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/Commandes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(newOrder),
      });
      const json = await response.json();
      if (response.ok) {
        fetchOrders();
        
        setNewOrder({
          Numero: "",
          statut: "En cours",
          dateDepart: new Date(),
          dateRetour: new Date(),
          myCompany: "",
          Produits: [],
          Projet:""
        });
        setSnackbar({ open: true, message: "Order created successfully", severity: "success" });
      } else {
        throw new Error(json.mssg || "Failed to create order");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({ open: true, message: error.message, severity: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else {
      fetchOrders();
    }
  }, [user, navigate]);

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">
              Commandes
            </div>
            <div className="sub-title">
               
                 Gérer les commandes de votre entreprise.
            </div>
          </div>
         
            <Link to="/Commande/Ajouter" title="Ajouter un nouveau produit">
            <div
              title="Nouvelle commande"
              
            >
              <AddBoxIcon className="add-icon" />
            </div>
            </Link>
          
        </div>
        <div className="details">
          {isLoading ? (
            <div
              className="loading"
              style={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="orders">
              
                <>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      alignItems: "center",
                    }}
                  >
                    <SearchBar search={search} setSearch={setSearch} />
                    <div style={{ marginLeft: "5px" }}>
                      {filteredOrders.length} commande
                      {filteredOrders.length > 1 ? "s" : ""}
                    </div>
                  </div>
                  <Grid container style={{ padding: "10px", margin: "0px" }}>
                    {filteredOrders.map((order) => (
                      <OrderCard key={order._id} order={order} />
                    ))}
                  </Grid>
                </>
              
            </div>
          )}
        </div>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default Orders;
