import { useState, useEffect, useRef, useCallback } from "react";
import useProducts from "../../../hooks/useProducts";
import ProductCard from "./productCardSmall";
import SearchBar from "./searchBarSmall";
import "./card.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const SidebarProduct = ({ id, order, setOrder }) => {

  const [searching, setSearching] = useState({
    page: 1,
    limit: 24,
    start: "",
    end: "",
    sortBy: "Number",
    orderBy: 1,
    searchQuery: "",
    categorie: "Tous",
    decor: "Tous"
  });

  const [searchQuery, setSearchQuery] = useState("");

  const { isLoading, isError, error, products, setProducts, hasNextPage } = useProducts({ searchQuery, searching });

  const intObserver = useRef();

  // Function to format the createdAt date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  const lastProductRef = useCallback(
    (product) => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((products) => {
        if (products[0].isIntersecting && hasNextPage) {
          console.log("We are near the last product!!");
          setSearching({
            ...searching,
            page: searching.page + 1
          });
        }
      });

      if (product) intObserver.current.observe(product);
    },
    [isLoading, hasNextPage]
  );

  useEffect(() => {
    if (order.dateDepart && order.dateRetour) {
      setSearching({
        ...searching,
        page: 1,
        start: order.dateDepart,
        end: order.dateRetour,
        categorie: "Tous",
        decor: "Tous"
      });
    }
  }, [order.dateDepart, order.dateRetour]);

  if (isError) return <p>Error : {error.message}</p>;

  const content = products.map((product, i) => {
    if (products.length === i + 1) {
      return (
        <ProductCard
          ref={lastProductRef}
          key={product._id}
          product={product}
          setProducts={setProducts}
          id={id}
          order={order}
          setOrder={setOrder}
        />
      );
    }
    return (
      <ProductCard
        key={product._id}
        product={product}
        setProducts={setProducts}
        id={id}
        order={order}
        setOrder={setOrder}
      />
    );
  });

  return (
    <div className="heightMin60">
      <div
        style={{
          borderBottom: "solid thin #232f3e",
          padding: "10px",
          marginBottom: "10px"
        }}
      >
        <div
          style={{
            fontSize: "10px",
            textTransform: "uppercase",
            textAlign: "center",
            fontWeight: "bold"
          }}
        >
          Items disponible du
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowUpwardIcon
            style={{
              fontSize: "15px",
              color: "red",
              fontWeight: "bold"
            }}
          />
          <div style={{ fontSize: "12px" }}>
            {order && formatDate(order.dateDepart)}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowDownwardIcon
            style={{
              fontSize: "15px",
              color: "green",
              fontWeight: "bold"
            }}
          />

          <div style={{ fontSize: "12px" }}>
            {order && formatDate(order.dateRetour)}
          </div>
        </div>
      </div>
      <SearchBar
        searching={searching}
        setSearching={setSearching}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <div style={{ padding: "5px" }} id="searchResult">
        {content}
      </div>
    </div>
  );
};

export default SidebarProduct;
