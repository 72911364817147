import { Grid } from "@mui/material";
import UserIcon from "./userIcon";
import UserInfo from "./userInfo";

const ClientInfos = ({ client, editing }) => {
  return (
    <div className="details">
      <Grid container spacing={3} style={{ margin: "auto", maxWidth: "700px" }}>
        <Grid item xs={12} md={5} style={{ marginBottom: "10px" }}>
          <UserIcon client={client} editing={editing} />
        </Grid>
        <Grid item xs={12} md={7}>
          <UserInfo userInfo={client} />
        </Grid>
      </Grid>
    </div>
  );
};
export default ClientInfos;
