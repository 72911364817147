import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import { useGlobalContext } from '../../../../hooks/useGlobalContext';
import config from '../../../../config';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../products.css';

function DeleteTag({ nom, id, checkedTags, setCheckedTags, title }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { user, tagsDispatch } = useGlobalContext();

  const handleClickDelete = async () => {
    const response = await fetch(`${config.BASE_URL}/api/tags${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      tagsDispatch({ type: 'DELETE_TAG', payload: json });
      setCheckedTags(checkedTags.filter(tag => tag.id !== id));
    }
    handleClose();
  };

  return (
    <>
      <div style={{display:"flex"}} onClick={handleShow}>
        <DeleteIcon className="add-icon" />
      </div>

      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Êtes-vous certain de vouloir supprimer <span className="lowerCase">{nom}</span>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleClickDelete} color="primary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteTag;
