import { Container, Grid } from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import config from "../../config";
import ProjectCard from "./components/projectCard";
import SearchBar from "./components/searchBar";
import EditProject from "./components/editProject";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import "./projet.css";
import "./sidebar.css";
import "./card.css";

import AddWorker from "./components/addWorker";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useNotify } from "../../hooks/useNotify";
import Loader from "../../components/loader";

const Projects = ({ editing }) => {
  const { notify } = useNotify();

  const [stateClientList, setStateClientList] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false
  });

  const { user } = useGlobalContext();
  
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [search, setSearch] = useState("");


  const [newProject, setNewProject] = useState({
    logo: "",
    Nom: "",
    Equipe: [],
    projectType: {
      _id:"",
    },
    Compagnie: {
      _id:"",
    },
  });


  


  useEffect(() => {
    if(editing)
    {
      setShowAddProject(true);
    }
  }
  , [editing]);

  const [showAddProject, setShowAddProject] = useState(false);
const [isLoading, setIsloading] = useState(true);

  const getAllProjects = async () => {
    const response = await fetch(`${config.BASE_URL}/api/projects`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });

    const json = await response.json();
    setProjects(json);
    setFilteredProjects(json)
    console.log(json);
    setIsloading(false);
  };

  const saveNewProject = async () => {
    if (newProject.Nom === "") {
      notify("error", "Veuillez inscrire un nom de projet svp!");
    } else if (newProject.projectType === "") {
      notify("error", "Veuillez sélectionner un type de projet svp!");
    } else if (newProject.Compagnie === "") {
      notify(
        "error",
        "Veuillez sélectionner une compagnie pour ce projet svp!"
      );
    } else if (newProject.Equipe.length === 0) {
      notify(
        "error",
        "Veuillez ajouter un client à l'équipe de ce projet svp!"
      );
    } else {
      const equipeIds = newProject.Equipe.map((client) => client.client._id);

      const newProjet = {
        Nom: newProject.Nom,
        projectType: newProject.projectType._id,
        Compagnie: newProject.Compagnie._id,
        Start: newProject.Start,
        End: newProject.End,
        Equipe: newProject.Equipe,
        logo: newProject.logo
      };

      console.log("New projet : ", newProjet);

      console.log("equipeIds : ", equipeIds);

      try {
        const response = await fetch(`${config.BASE_URL}/api/projects/`, {
          method: "POST",
          body: JSON.stringify(newProjet),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          }
        });

        const json = await response.json();

        if (!response.ok) {
          throw new Error(json.message);
        }
        setProjects([json, ...projects]);

        notify("success", "Nouveau projet ajouté avec succès.");
        setNewProject({
          Nom: "",
          projetType: {
            Name: "",
            id: ""
          },
          Compagnie: {
            nom: "",
            id: ""
          },
          Start: "",
          End: "",
          Equipe: []
        });

        setShowAddProject(false);
      } catch (error) {
        notify("error", error.message);
      }
    }
  };

  useEffect(() => {
    if (user) {
      getAllProjects();
    }
  }, [user]);


  useEffect(() => {
    if (search) {
      // Let's search first name, last name,email and phone number
      const filteredProjects = projects.filter((project) => {
        return (
          project.Nom.toLowerCase().includes(search.toLowerCase()) ||
          project.Start.toLowerCase().includes(search.toLowerCase()) ||
          project.End.toLowerCase().includes(search.toLowerCase()) 
        );
      });
      setFilteredProjects(filteredProjects);
    } else {
      setFilteredProjects(projects);
    }
  }, [search]);


  return (
    <div className="main-content" >
      <Container maxWidth="lg">
        {!showAddProject && (
          <div className="header">
            <div className="text">
              <div className="title">Projets</div>
              <div className="sub-title">
                Gérer et consulter les projets de votre système.
              </div>
            </div>
            <div onClick={() => setShowAddProject(true)}>
              <AddBoxIcon className="add-icon" />
            </div>
          </div>
        )}
        
        {showAddProject && (
          <div className="header">
            <div className="text">
              <div className="title">Projets</div>
              <div className="sub-title">
                Ajouter un projet a votre système.
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                title="Ajouter le projet"
                onClick={() => {
                  saveNewProject();
                }}
              >
                <SaveIcon className="add-icon" />
              </div>
              <div title="Annuler" onClick={() => setShowAddProject(false)}>
                <CancelIcon className="add-icon" />
              </div>
            </div>
          </div>
        )}

        {!showAddProject && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px"
              }}
            >
              <SearchBar search={search} setSearch={setSearch} />
              <div style={{ marginLeft: "5px" }}>
                {filteredProjects && filteredProjects.length} Projet{filteredProjects && filteredProjects.length > 1 ? "s" : ""}
              </div>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <Grid
                container
                spacing={3}
                style={{ margin: "0px", padding: "0px" }}
              >
                {filteredProjects && filteredProjects.map((project) => (
                  <ProjectCard key={project._id} project={project} />
                ))}
              </Grid>
            </div>
          </div>
        )}
        {isLoading && !showAddProject && 
          <Loader />
          }
        {showAddProject && (
          <EditProject
            newProject={newProject}
            setNewProject={setNewProject}
            editing={true}
            setStateClientList={setStateClientList}
          />
        )}
      </Container>
      <SlidingPane
        width="250px"
        isOpen={stateClientList.isPaneOpen}
        title="Ajouter des clients"
        subtitle="Projet"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setStateClientList({ isPaneOpen: false });
        }}
      >
        <AddWorker
          setStateClientList={setStateClientList}
          newProject={newProject}
          setNewProject={setNewProject}
        />
      </SlidingPane>
    </div>
  );
};
export default Projects;
