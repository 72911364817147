import { useEffect, useRef, useCallback, useMemo, useState } from "react";
import useProducts from "../../hooks/useProducts";
import ProductCard from "./components/productCardSmall";
import SearchBar from "./components/searchBarSmall";
import { Container, Grid } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import ScrollToTop from "../../components/scrollToTop";
import Loader from "../../components/loader";
import "./products.css";

const Products = () => {
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const {
    products,
    totalProducts,
    scrollPosition,
    productsDispatch,
    searching,
    searchQuery,
    searchDispatch,
  } = useGlobalContext();

  const { isLoading, isError, error, hasNextPage, setHasNextPage } = useProducts();
  const intObserver = useRef();
  const [isScrollingToTop, setIsScrollingToTop] = useState(false); // New state to track if scrolling to top

  // Restore scroll position when returning from a product
  useEffect(() => {
    if (!isLoading && products.length > 0) {
      if (scrollPosition > 0 && scrollRef.current) {
        scrollRef.current.scrollTo(0, scrollPosition);
      }
    }
  }, [isLoading, products.length, scrollPosition]);

  const lastProductRef = useCallback(
    (product) => {
      if (isLoading) return;
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          searchDispatch({
            type: "SET_SEARCHING",
            payload: {
              ...searching,
              page: searching.page + 1,
            },
          });
        }
      });

      if (product) intObserver.current.observe(product);
    },
    [isLoading, hasNextPage, searchDispatch, searching]
  );

  // Update the scroll position in the global state when scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current && !isScrollingToTop) {
        const currentScrollPosition = scrollRef.current.scrollTop;
        productsDispatch({
          type: "SET_SCROLL_POSITION",
          payload: currentScrollPosition,
        });
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [productsDispatch, isScrollingToTop]);

  const handleProductClick = (productId) => {
    navigate(`/Produit/${productId}`);
  };

  const content = useMemo(() => {
    return products.map((product, i) => {
      if (products.length === i + 1) {
        return (
          <ProductCard
            ref={lastProductRef}
            key={product._id}
            product={product}
            onClick={() => handleProductClick(product._id)}
          />
        );
      }
      return (
        <ProductCard
          key={product._id}
          product={product}
          onClick={() => handleProductClick(product._id)}
        />
      );
    });
  }, [products, lastProductRef]);

  // Set hasNextPage if there are already products in the global state
  useEffect(() => {
    if (products.length > 0) {
      setHasNextPage(true);
    }
  }, [products, setHasNextPage]);

  // Debounce search query updates
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      searchDispatch({
        type: "SET_SEARCH_QUERY",
        payload: searchQuery,
      });
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchQuery, searchDispatch]);

  const handleScrollToTop = () => {
    setIsScrollingToTop(true);
    scrollRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // Allow some time for the scroll to complete before resetting the flag
    setTimeout(() => {
      setIsScrollingToTop(false);
    }, 1000);
  };

  if (isError) {
    return <div className="error-message">Erreur : {error.message}</div>;
  }

  return (
    <div className="main-content" ref={scrollRef}>
      <Container maxWidth="xl">
        <div className="header">
          <div className="title">Produits</div>
          <Link to="/Produit/Ajouter" title="Ajouter un nouveau produit">
            <AddBoxIcon className="add-icon" />
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SearchBar
              searching={searching}
              setSearching={(newSearching) =>
                searchDispatch({ type: "SET_SEARCHING", payload: newSearching })
              }
              searchQuery={searchQuery}
              setSearchQuery={(newQuery) =>
                searchDispatch({ type: "SET_SEARCH_QUERY", payload: newQuery })
              }
            />
            <div style={{ marginLeft: "5px" }}>
              {totalProducts && totalProducts} produits
            </div>
          </div>
        </div>
        <Grid container spacing={3} style={{ marginTop: "5px" }}>
          {content}
        </Grid>
        {isLoading && <Loader />}
      </Container>
      <ScrollToTop scrollContainer={scrollRef} onScrollToTop={handleScrollToTop} />
    </div>
  );
};

export default Products;
