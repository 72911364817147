import React from "react";
import Modal from "@mui/material/Modal";
import PDFGenerator from "./pdfGenerator";

const PDFModal = ({ open, handleClose, order, subTotal, Tps, Tvq, total, totalDays }) => {
  console.log("Order in Modal : ", order);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          width:"fit-content",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          maxHeight: "90vh",
          overflowY: "auto",
          maxWidth: "90vw"
        }}
      >
        <PDFGenerator
          order={order}
          subTotal={subTotal}
          Tps={Tps}
          Tvq={Tvq}
          total={total}
          totalDays={totalDays}
        />
      </div>
    </Modal>
  );
};

export default PDFModal;
