import { Container } from "@mui/material";

const Account = () => {
  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">Compte</div>
            <div className="sub-title">
              Gérer et consulter les informations de votre compte.
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Account;
