import { Grid } from "@mui/material";
import UserIcon from "./userIcon";
import ModifyUserInfo from "./modifyUserInfo";
import config from "../../../config";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useEffect } from "react";

const ClientEdit = ({ newClient, setNewClient, editing }) => {
  const { user } = useGlobalContext();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.startsWith("userAddresses.")) {
      const addressIndex = name.split(".")[1];
      const addressField = name.split(".")[2];

      const updatedAddresses = [...newClient.userAddresses];

      updatedAddresses[addressIndex] = {
        ...updatedAddresses[addressIndex],
        [addressField]: value
      };

      setNewClient({
        ...newClient,
        userAddresses: updatedAddresses
      });
    } else {
      setNewClient({ ...newClient, [name]: value });
    }
  };

   // Generate avatar when component mounts or newClient changes
   const generateAvatar = async () => {
    console.log("Generate Avatar")
    const gender = { gender: newClient.userGender };

    try {
      const avatarUrl = await fetch(
        `${config.BASE_URL}/api/generate/generateAvatar`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          },
          body: JSON.stringify(gender)
        }
      );

      if (avatarUrl.ok) {
        const json = await avatarUrl.json();
        // Update the client with the generated avatar URL
        setNewClient({ ...newClient, avatar: json.url });
      } else {
        console.error("Failed to fetch avatar URL");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  useEffect(() => {
   
    if (!newClient.avatar) {
      generateAvatar();
    }
  }, [newClient]);

  return (
    <div>
      <Grid container spacing={3} style={{ margin: "auto", maxWidth: "700px" }}>
        <Grid item xs={12} md={5} style={{ marginBottom: "10px" }}>
        {newClient.avatar !== "" && (
            <UserIcon
            handleInputChange={handleInputChange}
            client={newClient} // Pass the updated client with avatar URL
            setClient={setNewClient}
            editing={editing}
            generateAvatar={generateAvatar}
          />
        )}
          
        </Grid>
        <Grid item xs={12} md={7}>
          {editing && (
            <ModifyUserInfo
              handleInputChange={handleInputChange}
              client={newClient}
              setClient={setNewClient}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientEdit;
