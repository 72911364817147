import './searchBar.css'

import SearchIcon from '@mui/icons-material/Search';


const SearchBar = ({ searching, setSearching, searchQuery, setSearchQuery }) => {
 


  return (
    <div className="search-bar">
                    
      <form style={{ width: '100%', display:"flex" }} onSubmit={(e) => {
        e.preventDefault()
        setSearching({
          ...searching,
          page: 1,
          categorie: "Tous",
          decor: "Tous"
        })
      }}>
        <SearchIcon style={{fontSize:"20px"}} />
        <input
          
          style={{
            height: "100%",
            width: "100%",
            lineHeight: "40px"
          }}
          type="search"
          id="rechercher"
          placeholder="Rechercher"
          value={searchQuery} onChange={(e) => {
            setSearchQuery(e.target.value)
            setSearching({
              ...searching,
              page:1,
              categorie:"Tous",
              decor:"Tous"
            })
           
          }}
        />
      </form>
    </div>
  )
}
export default SearchBar