import { Container, Grid } from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import config from "../../config";
import { useSnackbar } from "../../context/snackbarContext";
import EmployeCard from "./components/clientCard";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";

const Employees = () => {
  const { showSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false); // Set loading state
  const { user } = useGlobalContext();
  const [employes, setEmployes] = useState();

  const getEmployes = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.BASE_URL}/api/employes`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch employes");
      }
      const json = await response.json();
      setEmployes(json);
    } catch (error) {
      showSnackbar("Erreur lors de la récupération des employés", "error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      getEmployes();
    }
  }, [user]);

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">Employés</div>
            <div className="sub-title">
              Gérer et consulter les employés de votre système.
            </div>
          </div>
          <div title="Ajouter un employé">
            <Link to="/Employé/Nouvel">
              <AddBoxIcon className="add-icon" />
            </Link>
          </div>
        </div>

        {isLoading && (
          <div
            style={{
              display: "flex",
              height: "400px",
              paddingTop: "100px",
              justifyContent: "center",
              justifyItems: "center"
            }}
          >
            <Loader />
          </div>
        )}
        {!isLoading && employes && (
          <Grid container style={{ width: "100%", marginTop: "10px" }}>
            {employes.map((employe) => {
              return <EmployeCard key={employe._id} employe={employe} />;
            })}
          </Grid>
        )}
       
      </Container>
    </div>
  );
};
export default Employees;
