import DomainIcon from "@mui/icons-material/Domain";
import { Button } from "@mui/material";

const CompanyIcon = ({ company, setCompany, editing }) => {
  
  const handleImageChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
  
      setCompany({ ...company, companyLogo: reader.result });
    };

    reader.readAsDataURL(file);
  };
  return (
    <div
      className="company-icon"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <div className="img">
        {company && company.companyLogo === "" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start"
            }}
          >
            <DomainIcon style={{ fontSize: "150" }} />
          </div>
        )}
        {company && company.companyLogo !== "" && (
          <img
            src={company.companyLogo}
            alt=""
          />
        )}
      </div>
      {editing && (
        <Button
          onClick={() => document.querySelector("input[type='file']").click()}
          className="btn-primary"
          variant="contained"
          component="label"
          style={{ marginTop: "10px", width: "100%" }}
        >
          {company.companyLogo ? "Modifier le logo" : "Ajouter un logo"}
        </Button>
      )}
      <input type="file" onChange={handleImageChange} hidden />
    </div>
  );
};

export default CompanyIcon;
