import { Grid } from "@mui/material";
import Applications from "./applications";
import Dashboards from "./dashboard";
import Settings from "./settings";

const Permissions = ({ activeEmploye, setActiveEmploye, editing }) => {  
  return (
    <Grid container spacing={3}>
      <Applications activeEmploye={activeEmploye} setActiveEmploye={setActiveEmploye} editing={editing} />
      <Dashboards activeEmploye={activeEmploye} setActiveEmploye={setActiveEmploye} editing={editing} />
      <Settings activeEmploye={activeEmploye} setActiveEmploye={setActiveEmploye} editing={editing} />
    </Grid>
  );
};

export default Permissions;
