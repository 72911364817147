import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import calendrierIcon from "../../../assets/images/calendar.png";
import { useGlobalContext } from "../../../hooks/useGlobalContext";

const OrderInfo = ({ order, totalDays }) => {
  const {user} = useGlobalContext();
  const [projectStatus, setProjectStatus] = useState("");
  const dateCreated = new Date(order.createdAt).toLocaleDateString();
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getStatus = () => {
    const today = new Date();
    const startDate = new Date(order.Projet.Start);
    const endDate = new Date(order.Projet.End);
    if (today < startDate) {
      setProjectStatus("À venir");
    } else if (today > endDate) {
      setProjectStatus("Terminé");
    } else {
      setProjectStatus("En cours");
    }
  };

  useEffect(() => {
    if (order.Projet) {
      getStatus();
    }
  }, [order.Projet]);

  
  return (
    <div>
      {order && (
        <div>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              style={{
                textAlign: "center",
                padding: "10px"
              }}
            >
              <Card>
                <div style={{ padding: "20px" }}>
                  <div>
                    {order.myCompany?.companyLogo && (
                      <img
                        src={order.myCompany.companyLogo}
                        height="30px"
                        alt="company logo"
                      />
                    )}
                  </div>
                  <div>
                    {order.myCompany?.companyAddresses &&
                      order.myCompany.companyAddresses.length > 0 && (
                        <div style={{ fontSize: "12px" }}>
                          <div>
                            {order.myCompany.companyAddresses[0].address}
                          </div>
                          <div>
                            {order.myCompany.companyAddresses[0].city},{" "}
                            {order.myCompany.companyAddresses[0].state}
                          </div>
                          <div>
                            {order.myCompany.companyAddresses[0].country},{" "}
                            {order.myCompany.companyAddresses[0].zipCode}
                          </div>
                        </div>
                      )}
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {order.myCompany?.companyEmail}
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {order.myCompany?.companyTel}
                    </div>
                  </div>
                </div>
              </Card>
              <div
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  maxWidth: "50%",
                  margin: "auto",
                  marginTop: "15px",
                  backgroundColor: "#232f3e"
                }}
              >
                <div
                  style={{
                    color: "#f3f3f3",
                    fontSize: "10px",
                    textTransform: "uppercase"
                  }}
                >
                  Statut
                </div>
                <div style={{ color: "#65fadb" }}>{order.statut}</div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              style={{
                textAlign: "center",
                padding: "10px"
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="info-title">Projet :</div>
                  {order.Projet && (
                    <Link
                      className="noDecoration"
                      to={`/projets/${order.Projet._id}`}
                    >
                      <Card className="mini-card">
                        <div style={{ display: "flex", padding: "5px" }}>
                          <div className="img">
                            <img src={order.Projet.logo} alt="" />
                          </div>
                          <div className="info">
                            <div className="card-title">{order.Projet.Nom}</div>
                            <div
                              className="card-subtitle"
                              style={{
                                marginLeft: "5px",
                                textTransform: "capitalize"
                              }}
                            >
                              {order.Projet.projectType.Name}
                            </div>
                            <div
                              className="card-subtitle"
                              style={{
                                marginLeft: "5px",
                                textTransform: "capitalize"
                              }}
                            >
                              {projectStatus}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  )}
                  {!order.Projet && <div>Aucun projet</div>}
                </Grid>
                <Grid item xs={6}>
                  <div className="info-title">Compagnie :</div>
                  {order.Company && (
                    <Link
                      className="noDecoration"
                      to={`/compagnies/${order.Company._id}`}
                    >
                      <Card className="mini-card">
                        <div style={{ display: "flex", padding: "5px" }}>
                          <div className="img">
                            <img src={order.Company.companyLogo} alt="" />
                          </div>
                          <div className="info">
                            <div className="card-title">
                              {order.Company.companyName}
                            </div>
                            <div
                              className="card-subtitle"
                              style={{
                                marginLeft: "5px",
                                textTransform: "capitalize"
                              }}
                            >
                              <LocalPhoneIcon className="small-icon" />{" "}
                              {order.Company.companyTel}
                            </div>

                            <div
                              className="card-subtitle"
                              style={{
                                marginLeft: "5px",
                                textTransform: "capitalize"
                              }}
                            >
                              <EmailIcon className="small-icon" />{" "}
                              {order.Company.companyEmail}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  )}
                  {!order.Company && <div>Aucune compagnie</div>}
                </Grid>
                <Grid item xs={6}>
                  <div className="info-title">Commande passé par :</div>
                  <Link
                    className="noDecoration"
                    to={`/clients/${order.commandePour._id}`}
                  >
                    <Card className="mini-card">
                      <div style={{ display: "flex", padding: "5px" }}>
                        <div className="img">
                          <img src={order.commandePour.avatar} alt="" />
                        </div>
                        <div className="info">
                          <div className="card-title">
                            {order.commandePour.userFirstName}{" "}
                            {order.commandePour.userLastName}
                          </div>
                          <div
                            className="card-subtitle"
                            style={{
                              marginLeft: "5px",
                              textTransform: "capitalize"
                            }}
                          >
                            <LocalPhoneIcon className="small-icon" />{" "}
                            {order.commandePour.userCell}
                          </div>

                          <div
                            className="card-subtitle"
                            style={{
                              marginLeft: "5px",
                              textTransform: "capitalize"
                            }}
                          >
                            <EmailIcon className="small-icon" />{" "}
                            {order.commandePour.userEmail}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <div className="info-title">Commande passé le:</div>
                  <Card className="card-date">
                    <div style={{ display: "flex", padding: "5px" }}>
                      <div className="img">
                        <img src={calendrierIcon} alt="" />
                      </div>
                      <div className="info">
                        <div
                          className="card-subtitle"
                          style={{
                            marginLeft: "5px"
                          }}
                        >
                          {dateCreated}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <div className="info-title">Départ :</div>
              <Card className="card-date">
                <div className="info">
                  <div className="card-subtitle">
                    <ArrowUpwardIcon
                      style={{
                        fontSize: "30px",
                        color: "red",
                        fontWeight: "bold"
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>
                      {formatDate(order.dateDepart)}
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="info-title">Retour :</div>
              <Card className="card-date">
                <div className="info">
                  <div className="card-subtitle">
                    <ArrowDownwardIcon
                      style={{
                        fontSize: "30px",
                        color: "green",
                        fontWeight: "bold"
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>
                      {formatDate(order.dateRetour)}
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="info-title">Durée de la location :</div>
              <Card className="card-date">
                <div className="info">
                  <div className="card-subtitle">
                    <SwapVertIcon
                      style={{
                        fontSize: "30px",
                        color: "green",
                        fontWeight: "bold"
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>{totalDays} jours</div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default OrderInfo;
