import ConnexionForm from "./components/connexionForm";
import "./login.css";

const Login = () => {
  return (
    <div className="login-page">
      
      
      <ConnexionForm />
    </div>
  );
};
export default Login;
