import { Grid } from "@mui/material";
import UserIcon from "./userIcon";
import UserInfo from "./userInfo";

const EmployeInfos = ({ employe }) => {
  return (
    <div className="details">
      <Grid container  style={{padding:"15px"}}>
        <Grid item xs={12} md={5} style={{ marginBottom: "10px" }}>
          <UserIcon client={employe} editing={false} />
        </Grid>
        <Grid item xs={12} md={7}>
          <UserInfo employe={employe} />
        </Grid>
      </Grid>
    </div>
  );
};
export default EmployeInfos;
