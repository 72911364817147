import { createContext } from 'react'

export const CategoryContext = createContext()

export const categoriesReducer = (state, action) => {
    switch(action.type){
        case 'SET_CATEGORIES':
            return {
                categories: action.payload.sort(function(a, b) {
                    return (a.Name > b.Name) - (a.Name < b.Name);
                    })
            }
        case 'SET_CATEGORIE':
                //console.log("Load un Produit")
                //console.log(action.payload)
            return {
                
                //categories:[action.payload]
                //products: state.products.filter((p) => p.id !== action.payload._id)
            }   
        case 'CREATE_CATEGORY':
            console.log("Creating category")
            return {
                categories:[action.payload, ...state.categories]
            }
        case 'DELETE_CATEGORY':
            console.log("Deleting category")
            return {
                categories: state.categories.filter((c) => c._id !== action.payload._id)
            }
                
        default:
            return state
    }
}
