import { Select, Grid, Card, Button, Container, Snackbar, Alert } from "@mui/material";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import config from "../../config";
import "./order.css";
import ProjectCard from "../projects/components/projectCardNoGrid";
import CompanyCard from "../companies/components/companyCardNoGrid";
import ClientCard from "../clients/components/clientCardNoGrid";
import OrderLength from "./components/orderLength";
import { Link, useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import useOrder from "../../hooks/useOrder";
import Loader from "../../components/loader";

const OrderInputs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useGlobalContext();

  const { order, isLoading } = useOrder({ id });

  const [newOrder, setNewOrder] = useState({
    Numero: "",
    statut: "Création",
    dateDepart: new Date(),
    dateRetour: new Date(),
    myCompany: "",
    Produits: [],
  });

  const [myCompanies, setMyCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [totalDays, setTotalDays] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const [isClientsLoading, setIsClientsLoading] = useState(false);

  useEffect(() => {
    if (order) {
      console.log("Commande Pour dans order : ", order.commandePour);
      setNewOrder(order);
    }
  }, [order]);
  

  const fetchMyCompanies = async () => {
    const response = await fetch(`${config.BASE_URL}/api/Company/myCompanies`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();
    if (json.length !== 0) {
      setMyCompanies(json);
      setNewOrder((prevOrder) => ({ ...prevOrder, myCompany: json[0] }));
    } else {
      setMyCompanies([]);
    }
  };

  const fetchAllCompanies = async () => {
    const response = await fetch(`${config.BASE_URL}/api/Company`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();
    if (json.length !== 0) {
      setAllCompanies(json);
    } else {
      setAllCompanies([]);
    }
  };

  const fetchAllClients = async () => {
    setIsClientsLoading(true);
    
    try {

    
    const response = await fetch(`${config.BASE_URL}/api/clients`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();

    if (json.length !== 0) {
      setAllClients(json.clients);
    } else {
      setAllClients([]);
    } 
  } catch (error) {
    console.error(error);
    setSnackbar({ open: true, message: error.message, severity: "error" });
  }
  finally {
      setIsClientsLoading(false);
    }
  };

  const fetchCompanyProjects = async (company) => {
    const response = await fetch(
      `${config.BASE_URL}/api/projects/company/${company._id}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    const json = await response.json();
    if (json.length !== 0) {
      setProjects(json);
      setNewOrder((prevOrder) => ({
        ...prevOrder,
        Projet: "",
        Company: company,
        commandePour: "",
      }));
    } else {
      setProjects([]);
    }
  };

  const handleCreateOrder = async () => {
    if (!newOrder.Projet) {
      setSnackbar({ open: true, message: "Veuillez sélectionner un projet.", severity: "error" });
      return;
    }
    if (!newOrder.Company) {
      setSnackbar({ open: true, message: "Veuillez sélectionner une compagnie.", severity: "error" });
      return;
    }
    if (!newOrder.commandePour) {
      setSnackbar({ open: true, message: "Veuillez sélectionner un client.", severity: "error" });
      return;
    }
    if (totalDays === 0) {
      setSnackbar({ open: true, message: "Vous devez sélectionner une date de départ et une date de retour", severity: "error" });
      return;
    }

    try {
      const response = await fetch(`${config.BASE_URL}/api/Commandes${id ? `/${id}` : ""}`, {
        method: id ? "PATCH" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(newOrder),
      });
      const json = await response.json();
      if (response.ok) {
        setNewOrder({
          Numero: "",
          statut: "En cours",
          dateDepart: new Date(),
          dateRetour: new Date(),
          myCompany: "",
          Produits: [],
          Projet: "",
        });
        setSnackbar({ open: true, message: `Order ${id ? "updated" : "created"} successfully`, severity: "success" });
      } else {
        throw new Error(json.mssg || `Failed to ${id ? "update" : "create"} order`);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({ open: true, message: error.message, severity: "error" });
    } finally {
      navigate(-1);
    }
  };

  const fetchProjects = async () => {
    const response = await fetch(`${config.BASE_URL}/api/projects`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();
    if (json.length !== 0) {
      setProjects(json);
    } else {
      setProjects([]);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMyCompanies();
      fetchProjects();
      fetchAllCompanies();
      fetchAllClients();
    }
  }, [user]);

  const handleChangeProject = (e) => {
    const selectedProject = projects.find(
      (project) => project._id === e.target.value
    );
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      Projet: selectedProject,
      Company: selectedProject.Compagnie,
      commandePour: "",
    }));

    const clientsInProject = selectedProject.Equipe.map((team) => team.client);
    setAllClients(clientsInProject);
  };

  const handleChangeCompagnie = (e) => {
    const selectedCompany = allCompanies.find(
      (Company) => Company._id === e.target.value
    );
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      Company: selectedCompany,
      Projet: "",
    }));
    fetchCompanyProjects(selectedCompany);
  };

  
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  useEffect(() => {

    const startDate = new Date(newOrder.dateDepart) || new Date();
    const endDate = new Date(newOrder.dateRetour) || new Date();

    if (newOrder.dateDepart && newOrder.dateRetour) {
      if (newOrder.dateDepart === newOrder.dateRetour) {
        setTotalDays(1);
      } else {
        setTotalDays(Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)));
      }
    }
  }, [newOrder]);

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">{id ? "Modifier la commande" : "Nouvelle commande"}</div>
            <div className="sub-title">
              {id ? "Modifier les informations de la commande." : "Créer une nouvelle commande en remplissant les informations nécessaires."}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div title={id ? "Modifier la commande" : "Ajouter une nouvelle commande"}>
              <SaveIcon className="add-icon" onClick={handleCreateOrder} />
            </div>
            <div
              title="Annuler"
              onClick={() => {
                
                    navigate(-1);
                
                
              }}
            >
              <CancelIcon className="add-icon" />
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              <div style={{ padding: "10px", textAlign: "left" }}>
                Ma compagnie
              </div>
              <div>
                <Select
                  className="menu-select"
                  fullWidth
                  native
                  value={newOrder.myCompany._id || ""}
                  onChange={(e) => {
                    const selectedCompany = myCompanies.find(
                      (company) => company._id === e.target.value
                    );
                    setNewOrder({ ...newOrder, myCompany: selectedCompany });
                  }}
                >
                  <option value="" disabled>
                    Choisir votre compagnie
                  </option>
                  {myCompanies &&
                    myCompanies.map((company) => (
                      <option key={company._id} value={company._id}>
                        {company.companyName}
                      </option>
                    ))}
                </Select>
              </div>

              <Card style={{ marginTop: "20px" }}>
                <div style={{ padding: "10px" }}>
                  <div>
                    <img
                      src={newOrder.myCompany.companyLogo}
                      height="40px"
                      alt="company logo"
                    />
                  </div>
                  <div>
                    {newOrder.myCompany.companyAddresses &&
                      newOrder.myCompany.companyAddresses.length > 0 && (
                        <div style={{ fontSize: "12px" }}>
                          <div>
                            {newOrder.myCompany.companyAddresses[0].address}
                          </div>
                          <div>
                            {newOrder.myCompany.companyAddresses[0].city},{" "}
                            {newOrder.myCompany.companyAddresses[0].state}
                          </div>
                          <div>
                            {newOrder.myCompany.companyAddresses[0].country},{" "}
                            {newOrder.myCompany.companyAddresses[0].zipCode}
                          </div>
                        </div>
                      )}
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {newOrder.myCompany.companyEmail}
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      {newOrder.myCompany.companyTel}
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              <div style={{ padding: "10px", textAlign: "left" }}>Projet</div>
              <div>
                <Select
                  className="menu-select"
                  style={{ textTransform: "capitalize" }}
                  native
                  fullWidth
                  value={(newOrder.Projet && newOrder.Projet._id) || ""}
                  onChange={(e) => {
                    handleChangeProject(e);
                  }}
                >
                  <option value="" disabled>
                    Choisir un projet
                  </option>
                  {projects &&
                    projects.map((project) => (
                      <option
                        key={project._id}
                        value={project._id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {project.Nom}
                      </option>
                    ))}
                </Select>
              </div>

              <div style={{ marginTop: "20px" }}>
                {newOrder.Projet && <ProjectCard project={newOrder.Projet} />}
                {!newOrder.Projet && (
                  <div style={{ marginTop: "20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      Aucun projet de sélectioné
                    </div>
                    <Link to="/projets/edit" className="noDecoration">
                      <Button className="btn-primary">Ajouter un projet</Button>
                    </Link>
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              <div style={{ padding: "10px", textAlign: "left" }}>
                Compagnie
              </div>
              <div>
                <Select
                  className="menu-select"
                  fullWidth
                  native
                  value={(newOrder.Company && newOrder.Company._id) || ""}
                  onChange={(e) => {
                    handleChangeCompagnie(e);
                  }}
                >
                  <option value="" disabled>
                    Choisir une compagnie
                  </option>
                  {allCompanies.map((company) => (
                    <option key={company._id} value={company._id}>
                      {company.companyName}
                    </option>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: "20px" }}>
                {newOrder.Company && <CompanyCard company={newOrder.Company} />}
                {!newOrder.Company && (
                  <div style={{ marginTop: "20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      Aucune compagnie de sélectioné
                    </div>
                    <Link to="/compagnies/edit" className="noDecoration">
                      <Button className="btn-primary">Ajouter une compagnie</Button>
                    </Link>
                  </div>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              <div style={{ padding: "10px", textAlign: "left" }}>
                Commade pour
              </div>
              <div>
                <Select
                  className="menu-select"
                  fullWidth
                  native
                  value={newOrder.commandePour?._id || ""}
                  onChange={(e) => {
                    setNewOrder({ ...newOrder, commandePour: allClients.find((client)=> client._id === e.target.value)});
                  }}
                >
                  <option value="" disabled>
                    Choisir un client
                  </option>
                  {allClients &&
                    allClients.map((client) => {
                      return (
                        <option key={client._id} value={client._id}>
                          {client.userFirstName} {client.userLastName}
                        </option>
                      );
                    })}
                </Select>
              </div>
              <div style={{ marginTop: "20px" }}>
                {newOrder.commandePour && (
                  <ClientCard
                    client={newOrder.commandePour}
                  />
                )
                
                
                }
                {!newOrder.commandePour && (
                  <div style={{ marginTop: "20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      Aucun client de sélectioné
                    </div>
                    <Link to="/clients/edit" className="noDecoration">
                      <Button className="btn-primary">Ajouter un client</Button>
                    </Link>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <div style={{ paddingBottom: "20px" }}>
            <div className="header">
              <div className="text">
                <div className="title">Durée de location</div>
                <div className="sub-title">
                  Sélectionner la durée de la location.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#61dafb",
                }}
              >
                Durée: {totalDays} jours
              </div>
            </div>
            {!isLoading && (
              <OrderLength newOrder={newOrder} setNewOrder={setNewOrder} />
            ) 
            }
            
          </div>
        </div>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OrderInputs;
