import { Container, Grid } from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import config from "../../config";
import { Edit } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditCompany from "./components/editCompany";
import SaveIcon from "@mui/icons-material/Save";

import "./company.css";
import "./card.css";
import CompanyCard from "./components/companyCard";
import SearchBar from "./components/searchBar";
import Loader from "../../components/loader";


const Companies = ({ editing }) => {

  

  const { user } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const [newCompany, setNewCompany] = useState({
    companyName: "",
    companyEmail: "",
    companyTel: "",
    companyLogo: "",
    companyAddresses: []
  });

  const [showAddCompany, setShowAddCompany] = useState(false);

  const fetchCompanies = async () => {
    const response = await fetch(`${config.BASE_URL}/api/Company`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });

    const json = await response.json();
    console.log(json);
    if (json.length != 0) {
      setCompanies(json);
      setFilteredCompanies(json);
    } else {
      setCompanies([]);
      setIsLoading(false);

      return;
    }
    setIsLoading(false);
  };

  const handleSaveNewCompany = async () => {
    const response = await fetch(`${config.BASE_URL}/api/Company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`
      },
      body: JSON.stringify(newCompany)
    });

    const json = await response.json();
    if (json.error) {
      console.log(json.error);
      return;
    }

    setCompanies([json, ...companies]);
    setNewCompany({
      companyName: "",
      companyEmail: "",
      companyTel: "",
      companyLogo: "",
      companyAddresses: []
    });
    setShowAddCompany(false);
  };

  useEffect(() => {
    if (user) fetchCompanies();
  }, [user]);


  useEffect(() => {
    if(editing)
    {
      setShowAddCompany(true);
    }
  }
  , [editing]);
  useEffect(() => {
    if (search) {
      // Let's search first name, last name,email and phone number
      const filteredCompanies = companies.filter((company) => {
        return (
          company.companyName.toLowerCase().includes(search.toLowerCase()) ||
          company.companyEmail.toLowerCase().includes(search.toLowerCase()) ||
          company.companyTel.toLowerCase().includes(search.toLowerCase()) 
        );
      });
      setFilteredCompanies(filteredCompanies);
    } else {
      setFilteredCompanies(companies);
    }
  }, [search]);

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        {!showAddCompany && (
          <div className="header">
            <div className="text">
              <div className="title">Compagnies</div>
              <div className="sub-title">
                Gérer les compagnies de votre entreprise.
              </div>
            </div>

            <div onClick={() => setShowAddCompany(true)}>
              <AddBoxIcon className="add-icon" />
            </div>
          </div>
        )}
        {showAddCompany && (
          <div className="header">
            <div className="text">
              <div className="title">Nouvelle compagnie</div>
              <div className="sub-title">Veuillez remplir les informations nécessaires pour la nouvelle compagnie.</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                title="Ajouter la nouvelle compagnie."
                onClick={async () => {
                  await handleSaveNewCompany();
                }}
              >
                <SaveIcon className="add-icon" />
              </div>
              <div
                title="Annuler"
                onClick={() => {
                  setShowAddCompany(false);
                  setNewCompany({
                    companyName: "",
                    companyEmail: "",
                    companyTel: "",
                    companyLogo: "",
                    companyAddresses: []
                  });
                }}
              >
                <CancelIcon className="add-icon" />
              </div>
            </div>
          </div>
        )}

        <div>
          {showAddCompany && (
            <EditCompany
              newCompany={newCompany}
              setNewCompany={setNewCompany}
              editing={true}
            />
          )}
        </div>
        {!showAddCompany && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px"
              }}
            >
              <SearchBar search={search} setSearch={setSearch} />
              <div style={{ marginLeft: "5px" }}>
                {filteredCompanies.length} Compagnie
                {filteredCompanies.length > 1 ? "s" : ""}
              </div>
            </div>
            {isLoading && (
          <Loader />
        )}
            <div style={{ width: "100%", display: "flex" }}>
              <Grid
                container
                spacing={3}
                style={{ margin: "0px", padding: "0px" }}
              >
                {filteredCompanies &&
                  filteredCompanies.map((company, index) => (
                    <CompanyCard company={company} key={company._id} />
                  ))}
              </Grid>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};
export default Companies;
