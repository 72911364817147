import { Container } from "@mui/material";
import config from "../../../config";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../../context/snackbarContext";


const DashboardClients = () => {
    const [clients, setClients] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const {showSnackbar} = useSnackbar();

    const [statsClients, setStatsClients] = useState({
        total: 0,
        verified: 0,
        notVerified: 0
    });


    const {user} = useGlobalContext();

    const fetchAllClients = async () => {
        const response = await fetch(`${config.BASE_URL}/api/clients`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        });
        const json = await response.json();
        if (response.ok) {
            setClients(json);
            setIsloading(false);
        }
        if (!response.ok) {
            showSnackbar("Erreur lors de la récupération des clients", "error");
            setIsloading(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchAllClients();
        }
        window.scrollTo(0, 0);
    }
    , [user]);

    return (
        <Container maxWidth="lg">
            <div className="header">
                <div className="text">
                    <div className="title">Clients</div>
                    <div className="sub-title">Résumé des clients.</div>
                </div>
            </div>
            <div>

            </div>
        </Container>
        
    )
    }

export default DashboardClients;