import { createContext } from 'react'

export const SelectedTagContext = createContext()

export const selectedTagsReducer = (state, action) => {
    switch(action.type){
        case 'SET_SELECTEDTAGS':
            return {
                selectedTags: action.payload
            }
        case 'CLEAR_SELECTEDTAG':
                //console.log("Load un Produit")
                //console.log(action.payload)
            return {
                selectedTags: []
                //categories:[action.payload]
                //products: state.products.filter((p) => p.id !== action.payload._id)
            }   
        case 'ADD_SELECTEDTAG':
            console.log("Creating category")
            return {
                selectedTags:[action.payload, ...state.selectedTags]
            }
        case 'DELETE_SELECTEDTAG':
            console.log("Deleting category")
            return {
                selectedTags: state.selectedTags.filter((c) => c._id !== action.payload._id)
            }
                
        default:
            return state
    }
}
