import { Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import CompanyIcon from "./companyIcon";

const CompanyCard = ({ company }) => {
  return (
    
      <Link className="noDecoration" to={`/compagnies/${company._id}`}>
        <Card className="card-company">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CompanyIcon company={company} editing={false} />
              <div className="card-title">{company.companyName}</div>
              <div className="card-subtitle">
                <LocalPhoneIcon className="small-icon" /> {company.companyTel}
              </div>
              <div className="card-subtitle">
                <EmailIcon className="small-icon" /> {company.companyEmail}
              </div>
              
              
              
            </Grid>
          </Grid>
        </Card>
      </Link>
    
  );
};
export default CompanyCard;
