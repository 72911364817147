import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const useNotify = () => {
  const notify = (action, message) => {
    if (action == "success") {
      toast.success(message);
    }
    if (action == "error") {
      toast.error(message);
    }
    if (action == "warning") {
      toast.warning(message);
    }
  };

  return { notify };
};
