import { Button } from "@mui/material";
import projectIcon from "../imgs/projet.png";
import { useEffect } from "react";

const ProjectIcon = ({ project, setProject, editing }) => {
  
  useEffect(() => {
    console.log("THere is a change in Project : ", project);
  }, [project]);

  const handleImageChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      setProject({ ...project, logo: reader.result });
    };

    reader.readAsDataURL(file);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <div className="img">
        {!project.logo && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              aspectRatio: "1/1"
            }}
          >
            <img src={projectIcon} alt="Projet" style={{ width: "100px" }} />
          </div>
        )}
        {project.logo && <img src={project.logo} alt=""  style={{ width: "100%" }} />}
      </div>
      {editing && (
        <Button
          onClick={() => document.querySelector("input[type='file']").click()}
          className="btn-primary"
          variant="contained"
          component="label"
          style={{ marginTop: "10px", width: "100%" }}
        >
          {project.logo ? "Modifier le logo" : "Ajouter un logo"}
        </Button>
      )}
      <input type="file" onChange={handleImageChange} hidden />
    </div>
  );
};

export default ProjectIcon;
