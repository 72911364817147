import { Select, TextField } from "@mui/material";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { useEffect, useMemo, useState } from "react";
import config from "../../../config";
import EditModal from "../../settings/components/editModal";
import { AddBox } from "@mui/icons-material";
import { Link } from "react-router-dom";

const EditProjectInfo = ({ newProject, setNewProject }) => {
  const { user } = useGlobalContext();
  const [projectTypes, setProjectTypes] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [action, setAction] = useState({
    type: "",
    title: ""
  });
  const [activeType, setActiveType] = useState({
    _id: "",
    Name: ""
  });

  const handleAdd = () => {
    setAction({
      type: "add",
      title: "Ajouter un type de projet"
    });

    handleShow();
  };

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    setShowModal(!showModal);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // If the select is for projectType or Compagnie, find the corresponding object
    if (name === "projectType") {
      const selectedType = projectTypes.find((type) => type._id === value);
      updatedValue = selectedType || ""; // Set to empty string if not found
      setNewProject({
        ...newProject,
        projectType: updatedValue // Update newProject state
      });

    } else if (name === "Compagnie") {
      const selectedCompany = companies.find(
        (company) => company._id === value
      );
      updatedValue = selectedCompany || ""; // Set to empty string if not found
      setNewProject({
        ...newProject,
        Compagnie: updatedValue // Update newProject state
      });
    } else {
      // For other fields, simply update the state
      setNewProject({
        ...newProject,
        [name]: updatedValue
      });
    }
  };

  const fetchCompanies = useMemo(() => {
    return async () => {
      const response = await fetch(`${config.BASE_URL}/api/Company`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      const json = await response.json();
      if (response.ok) {
        setCompanies(json);
      }
    };
  });

  const fetchProjectTypes = async () => {
    const response = await fetch(`${config.BASE_URL}/api/projectType`, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });

    const json = await response.json();
    setProjectTypes(json);
  };

  useEffect(() => {
    fetchCompanies();
    fetchProjectTypes();
  }, [user]);

  return (
    <div>
      <TextField
        label="Nom du projet"
        variant="outlined"
        name={`Nom`}
        style={{ width: "100%" }}
        onChange={handleChange}
        value={newProject.Nom}
      />
      <div style={{ marginTop: "10px" }}>
        <div style={{ marginBottom: "10px", marginLeft: "3px", color: "#999" }}>
          Type de projet
        </div>
        <div className="edit">
          <Select
            native
            value={newProject.projectType._id} // Set value to the ID of the selected projectType
            onChange={handleChange}
            inputProps={{
              name: "projectType"
            }}
            style={{ width: "100%" }}
          >
            <option aria-label="None" value="" />
            {projectTypes.map((type) => (
              <option key={type._id} value={type._id}>
                {type.Name}
              </option> // Set value to type._id
            ))}
          </Select>

          <div title="Ajouter un type de poste" onClick={handleAdd}>
            <AddBox className="add-icon" />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <div style={{ marginBottom: "10px", marginLeft: "3px", color: "#999" }}>
          Compagnie
        </div>
        <div className="edit">
        <Select
  native
  value={newProject.Compagnie._id} // Set value to the ID of the selected Compagnie
  onChange={handleChange}
  inputProps={{
    name: "Compagnie"
  }}
  style={{ width: "100%" }}
>
  <option aria-label="None" value="" />
  {companies.map((company) => (
    <option key={company._id} value={company._id}>
      {company.companyName}
    </option>
  ))}
</Select>

          <Link to={`/compagnies/edit`}>
            <div title="Ajouter un type de poste">
              <AddBox className="add-icon" />
            </div>
          </Link>
        </div>
      </div>
      <EditModal
        show={showModal}
        handleClose={handleShow}
        setDataList={setProjectTypes}
        data={activeType}
        action={action}
        from="types"
      />
    </div>
  );
};

export default EditProjectInfo;
