import { createContext } from "react";

export const ProductContext = createContext();

export const productsReducer = (productState, action) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...productState,
        products: action.payload.products.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        ),
        totalProducts: action.payload.total,
        scrollPosition: action.payload.scrollPosition || 0,
      };
    case "UPDATE_PRODUCT":
      return {
        ...productState,
        products: productState.products.map((p) =>
          p._id === action.payload._id ? { ...p, ...action.payload } : p
        ),
        totalProducts: productState.totalProducts,
      };
    case "ADD_PRODUCT":
      return {
        ...productState,
        products: [action.payload, ...productState.products],
        totalProducts: productState.totalProducts + 1,
      };
    case "ADD_PRODUCTS":
      return {
        ...productState,
        products: [...productState.products, ...action.payload.products],
        totalProducts: action.payload.total,
      };
    case "DELETE_PRODUCT":
      return {
        ...productState,
        products: productState.products.filter(
          (p) => p._id !== action.payload._id
        ),
        totalProducts: productState.totalProducts - 1,
      };
    case "SET_SCROLL_POSITION":
      return {
        ...productState,
        scrollPosition: action.payload,
      };
    default:
      return productState;
  }
};
