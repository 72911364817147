import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const InfoProject = ({ newProject }) => {
  const [projectStatus, setProjectStatus] = useState("");
  const startDate = new Date(newProject.Start);
  const endDate = new Date(newProject.End);


  const getStatus = () => {
    const today = new Date();
  
    if (today < startDate) {
      setProjectStatus("À venir");
    } else if (today > endDate) {
      setProjectStatus("Terminé");
    } else {
      setProjectStatus("En cours");
    }
  }

  useEffect(() => {
    getStatus();
  }, [newProject]);





  return (
    <>
      <div className="infos">
        <div className="details-title">{newProject.Nom}</div>
        <div className="details-subtitle">{newProject.projectType.Name}</div>
        <Link to={`/compagnies/${newProject.Compagnie._id}`} className="details-subtitle company-name">{newProject.Compagnie.companyName}</Link>
        <div className="details-subtitle">Débute le {startDate.toLocaleDateString()}</div>
        <div className="details-subtitle">Termine le {endDate.toLocaleDateString()}</div>
        <div className="details-subtitle">Durée: {Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24))} jours</div>
        <div className="details-subtitle">Statut: {projectStatus}</div>
        
      </div>
    </>
  );
};

export default InfoProject;

