import { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import OrderInfo from "./components/infoOrder";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SlidingPanel from "react-sliding-pane";
import useOrder from "../../hooks/useOrder";
import SidebarProduct from "./components/sidebar";
import OrderRow from "./components/orderRow";
import "./order.css";
import PDFModal from "./components/pdfModal";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PDFGenerator from "./components/pdfGenerator";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useSnackbar } from "../../context/snackbarContext";
import config from "../../config"; // Ensure this import is correct

const OrderDetails = () => {
  const { id } = useParams();
  const { user } = useGlobalContext();
  const { showSnackbar } = useSnackbar();
  const [stateProductsList, setStateProductsList] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false
  });

  const {
    isLoading,
    isError,
    error,
    order,
    setOrder,
    totalDays,
    subTotal,
    total,
    Tps,
    Tvq
  } = useOrder({ id });

  const [showAddOrder, setShowAddOrder] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const sendOrderByEmail = async () => {
    const data = {
      userEmail: order.commandePour.userEmail,
      accessToken: order.accessToken
    };

    try {
      const response = await fetch(
        `${config.BASE_URL}/api/Commandes/sendByEmail/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          },
          body: JSON.stringify(data)
        }
      );
      if (!response.ok) {
        throw new Error("Failed to send email");
      }
      setOrder({ ...order, statut: "En attente" });
      showSnackbar("Email envoyé", "success");
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">
              {showAddOrder ? "Modifier commande" : "Commande"}{" "}
              <span style={{ color: "#61dafb" }}>
                {order && order.Numero}
              </span>
            </div>
            <div className="sub-title">
              Gérer votre commande et les produits associés
            </div>
          </div>
          
          
            <div style={{ display: "flex" }}>
              <Link to={`/Commande/Modifier/${id}`} className="noDecoration">
              <div
                title="Modifier la commande"
                
              >
                <EditIcon className="add-icon" />
              </div>
              </Link>
              <div
                title="Envoyer la commande par courriel"
                onClick={sendOrderByEmail}
              >
                <ForwardToInboxIcon className="add-icon" />
              </div>
              <div title="Créer la facture">
                <ReceiptIcon className="add-icon" />
              </div>
              {order && totalDays && (
                <div title="Télécharger la commande en PDF">
                  <PDFGenerator order={order} totalDays={totalDays} />
                </div>
              )}
              {order && order.statut !== "Creation" && (
                <div title="Archiver la commande">
                  <ArchiveIcon className="add-icon" />
                </div>
              )}
              <div title="Supprimer la commande">
                <DeleteIcon className="add-icon" />
              </div>
            </div>
          
        </div>
        {!isLoading && order && (
          <div
            style={{
              padding: "20px"
            }}
          >
            <OrderInfo order={order} totalDays={totalDays} />
            <div
              style={{
                marginTop: "15px",
                paddingBottom: "5px",
                marginBottom: "15px",
                borderBottom: "solid thin #232f3e",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div>Articles</div>
              <div className="btn-secondary">
                <AddBoxIcon
                  className="icons"
                  onClick={() => {
                    setStateProductsList({ isPaneOpen: true });
                  }}
                />
                <Link to="/Produit/Ajouter" className="noDecoration">
                  <FiberNewIcon className="icons" />
                </Link>
              </div>
            </div>
            {totalDays &&
              order.Produits &&
              order.Produits.map((product, index) => {
                return (
                  <OrderRow
                    key={product._id}
                    index={index}
                    product={product}
                    totalDays={totalDays}
                    setOrder={setOrder}
                    order={order}
                  />
                );
              })}
            {totalDays && order.Produits.length === 0 && (
              <div style={{ textAlign: "center", padding: "30px" }}>
                Aucun produit
              </div>
            )}
            <div
              style={{
                marginTop: "15px",
                paddingTop: "5px",

                textAlign: "right"
              }}
            >
              Total :{" "}
              {order.Produits.reduce((acc, product) => acc + product.quant, 0) +
                " articles"}
            </div>

            {user.userType === "Admin" && (
              <div
                style={{
                  marginTop: "15px",
                  paddingTop: "5px",
                  borderTop: "solid thin #232f3e"
                }}
              >
                <div
                  style={{
                    marginTop: "15px",
                    border: "solid thin #999",
                    padding: "20px",
                    borderRadius: "10px",
                    width: "250px",
                    marginLeft: "auto"
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Sous-total :</div>
                    <div>{subTotal}$</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>TPS :</div>
                    <div>{Tps}$</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>TVQ :</div>
                    <div>{Tvq}$</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Total :</div>
                    <div>{total}$</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!isLoading && order && (
          <PDFModal
            open={openModal}
            handleClose={handleCloseModal}
            order={order}
            subTotal={subTotal}
            Tps={Tps}
            Tvq={Tvq}
            total={total}
            totalDays={totalDays}
          />
        )}
      </Container>
      <SlidingPanel
        width="250px"
        isOpen={stateProductsList.isPaneOpen}
        title="Ajouter un produit"
        subtitle="Commande"
        onRequestClose={() => {
          setStateProductsList({ isPaneOpen: false });
        }}
      >
        <SidebarProduct id={id} order={order} setOrder={setOrder} />
      </SlidingPanel>
    </div>
  );
};

export default OrderDetails;
