import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

const LogCard = ({ log }) => {
  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };
  const formatTime = (date) => {
    const d = new Date(date);
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  return (
    <div className="log-card">
      <div className="log-card-header">
        <div className="log-card-date">{formatDate(log.createdAt)}</div>
        <div className="log-card-time">{formatTime(log.createdAt)}</div>
      </div>
      <div className="log-card-user">
        <div className="img">
          <img src={log.user.avatar} alt="user" />
        </div>
        <div>
          <div>
            {log.user.userFirstName} {log.user.userLastName}
          </div>
          <div>{log.user.employe.poste.Name} </div>
        </div>
      </div>
      <div style={{textAlign:"center"}} >
        {log.action === "Logged in" && <div>C'est connecté</div>}

        {log.action === "update" && (
          <>
            
              A modifier {log.whatCollection === "products" && <> le produit</>}
            
          </>
        )}
      </div>
      {log.whatCollection === "products" && (
        <div style={{textAlign:"center"}}>
          <Link to={`/Produit/${log.target}`}>{log.target}</Link>
        </div>
      )}
    </div>
  );
};

export default LogCard;
