import { useRef, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "./hooks/useGlobalContext";
import Dock from "./components/dock";
import Login from "./pages/login";
import Desktop from "./pages/desktop";

import Products from "./pages/products";
import Companies from "./pages/companies";
import Projects from "./pages/projects";
import Orders from "./pages/orders";
import Invoices from "./pages/invoices";
import Employees from "./pages/employes";
import Expenses from "./pages/expenses";
import Schedules from "./pages/schedules";
import Account from "./pages/account";
import Settings from "./pages/settings";
import Dashboard from "./pages/dashboard";
import Details from "./pages/products/details";
import DetailsClient from "./pages/clients/details";
import CompanyDetails from "./pages/companies/details";
import ProjectDetails from "./pages/projects/details";
import OrderDetails from "./pages/orders/details";
import AddProduct from "./pages/products/addProduct";
import OrderInputs from "./pages/orders/orderInputs";
import DetailsEmployes from "./pages/employes/details";
import InputsEmploye from "./pages/employes/components/inputsEmployes";
import Clients from "./pages/clients";
import DashEmployes from "./pages/dashboard/components/employes";
import DashboardCompagnies from "./pages/dashboard/components/companies";
import DashboardDepenses from "./pages/dashboard/components/depenses";
import DashboardProjects from "./pages/dashboard/components/projects";
import DashboardOrders from "./pages/dashboard/components/orders";
import DashboardInvoices from "./pages/dashboard/components/invoices";
import DashboardLogs from "./pages/dashboard/components/logs";
import DashboardResume from "./pages/dashboard/components/resume";
import DashboardClients from "./pages/dashboard/components/clients";
import DashboardProducts from "./pages/dashboard/components/products";

import ScrollToTop from "./components/scrollToTop";

function AuthGuard({ children }) {
  const { user } = useGlobalContext();
  const navigate = useNavigate();

  if (!user) {
    navigate("/"); // Redirect to login page if user is not authenticated
    return null;
  }

  return children;
}

function App() {
  const { user } = useGlobalContext();
  const [showSearch, setShowSearch] = useState(false);
  

  return (
    <div className="App">
      <BrowserRouter>
        <div className="content">
          <Routes>
            <Route
              element={
                <AuthGuard>
                  <Desktop />
                </AuthGuard>
              }
            />
            <Route path="/" element={user ? <Desktop /> : <Login />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/produits" element={<Products />} />
            <Route path="/Produit/:id" element={<Details />} />
            <Route path="/Produit/Ajouter" element={<AddProduct />} />
            <Route path="/Produit/Modifier/:id" element={<AddProduct />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/edit" element={<Clients editing={true} />} />
            <Route path="/clients/:id" element={<DetailsClient />} />
            <Route path="/compagnies" element={<Companies />} />
            <Route
              path="/compagnies/edit"
              element={<Companies editing={true} />}
            />
            <Route path="/compagnies/:id" element={<CompanyDetails />} />
            <Route path="/projets" element={<Projects />} />
            <Route path="/projets/edit" element={<Projects editing={true} />} />

            <Route path="/projets/:id" element={<ProjectDetails />} />

            <Route path="/Commandes" element={<Orders />} />
            <Route path="/Commande/:id" element={<OrderDetails />} />
            <Route path="/Commande/Ajouter" element={<OrderInputs />} />
            <Route path="/Commande/Modifier/:id" element={<OrderInputs />} />

            <Route path="/factures" element={<Invoices />} />

            <Route path="/Employés" element={<Employees />} />
            <Route path="/Employé/:id" element={<DetailsEmployes />} />
            <Route path="/Employé/Nouvel" element={<InputsEmploye />} />
            <Route path="/Employé/Modifier/:id" element={<InputsEmploye />} />

            <Route path="/dépenses" element={<Expenses />} />
            <Route path="/horaires" element={<Schedules />} />
            <Route path="/compte" element={<Account />} />
            <Route path="/paramètres" element={<Settings />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route
                path="/dashboard/Produits"
                element={<DashboardProducts />}
              />
              <Route path="/dashboard/Clients" element={<DashboardClients />} />
              <Route path="/dashboard/Résumé" element={<DashboardResume />} />
              <Route path="/dashboard/Employés" element={<DashEmployes />} />
              <Route
                path="/dashboard/Compagnies"
                element={<DashboardCompagnies />}
              />
              <Route
                path="/dashboard/Dépenses"
                element={<DashboardDepenses />}
              />
              <Route
                path="/dashboard/Projets"
                element={<DashboardProjects />}
              />
              <Route
                path="/dashboard/Commandes"
                element={<DashboardOrders />}
              />
              <Route
                path="/dashboard/Factures"
                element={<DashboardInvoices />}
              />
              <Route path="/dashboard/Logs" element={<DashboardLogs />} />
            </Route>
          </Routes>
        </div>
        {user && <Dock setShowSearch={setShowSearch} showSearch={showSearch} />}
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          theme="colored"
        />
        
      </BrowserRouter>
    </div>
  );
}

export default App;
