import { useEffect, useState } from "react";
import config from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import defaultImage from "../../../assets/images/no-image-icon.png";
import { useSnackbar } from "../../../context/snackbarContext";

const OrderRow = ({ product, index, totalDays, order, setOrder }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const { showSnackbar } = useSnackbar();

  const [image, setImage] = useState();

  useEffect(() => {
    getImage();
  }, [product]);

  const getImage = async () => {
    setIsLoading(true);

    const response = await fetch(
      `${config.BASE_URL}/api/images/${product.produit._id}`
    );
    try {
      const json = await response.json();
      if (response.ok) {
        const img = new Image();
        img.onload = async () => {
          const imageUrl = await getSignedURL(json.awsKey);

          setImage(imageUrl);
          setIsLoading(false);
        };
        img.onerror = () => {
          console.log("Error loading image. Using default image.");
          setImage(defaultImage);
        };
        img.src = await getSignedURL(json.awsKey);
      } else {
        setImage(defaultImage);
      }
    } catch (error) {
      setImage(defaultImage);
    }
  };

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      return defaultImage;
    }
  };

  const addProductToOrder = async () => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/Commandes/item/add/${order._id}`,
        {
          method: "POST",
          body: JSON.stringify({
            productID: product.produit._id,
            quant: 1
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }
      const data = await response.json();
      setOrder(data);

      showSnackbar("Produit ajouté à la commande", "success");
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  const deleteItemFromOrder = async () => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/Commandes/item/delete/${order._id}`,
        {
          method: "POST",
          body: JSON.stringify({
            productID: product.produit._id
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }
      const data = await response.json();
      setOrder(data);

      showSnackbar("Produit supprimé de la commande", "success");
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  const removeProductFromOrder = async () => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/Commandes/item/remove/${order._id}`,
        {
          method: "POST",
          body: JSON.stringify({
            productID: product.produit._id,
            quant: 1
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }
      const data = await response.json();
      setOrder(data);

      showSnackbar("Quantité de produit réduite dans la commande", "success");
    } catch (error) {
      showSnackbar(error.message, "error");
    }
  };

  const handleChangeInput = (e) => {
    const inputValue = parseInt(e.target.value);
    const availableQuantity = product.produit.Quantite - product.quant;

    console.log("Input value :", inputValue);
    console.log("Available quantity :", availableQuantity);

    if (availableQuantity == 0 && inputValue > product.quant) {
      showSnackbar("Quantité non disponible", "error");
      return;
    }

    if (inputValue < product.quant) {
      removeProductFromOrder();
    } else {
      addProductToOrder();
    }
  };

  const rowClassName = "bg";

  const [pourcentage, setPourcentage] = useState(0);
  const [rentalValue, setRentalValue] = useState(0);

  const getRentalValue = () => {
    let pourcentage = 0;

    if (totalDays <= 7) {
      pourcentage = product.produit.Semaine1;
    } else if (totalDays <= 14) {
      pourcentage = product.produit.Semaine1 + product.produit.Semaine2;
    } else if (totalDays <= 21) {
      pourcentage = product.produit.Semaine1 + 2 * product.produit.Semaine2;
    } else {
      pourcentage = product.produit.Semaine1 + 2 * product.produit.Semaine2;
      const additionalDays = totalDays - 21;
      const additionalWeeks = Math.ceil(additionalDays / 7);
      const additionalWeeksTotal =
        additionalWeeks * product.produit.SemainePlus;
      pourcentage += additionalWeeksTotal;
    }

    setPourcentage(pourcentage);
    const rentalValue = (product.produit.Valeur * pourcentage) / 100;

    return rentalValue;
  };

  useEffect(() => {
    if (product.produit.Valeur && product.produit.Valeur > 0) {
      setRentalValue(getRentalValue());
    }
  }, [product.produit.Valeur, product.quant]);

  return (
    <div className={`order-row ${rowClassName}`}>
      <div className="number">{index + 1}.</div>
      <div className="img">
        <img src={image} alt="" />
      </div>
      <div>
        <div className="product-info">
          <div className="product-number">{product.produit.Number}</div>
          <div className="product-name">{product.produit.Name}</div>
        </div>
        <div style={{ fontSize: "12px", marginLeft: "10px" }}>
          {product.produit.Quantite - product.quant} de disponible
        </div>
      </div>
      <div className="btn-right">
        {user.userType === "Admin" && (
          <div style={{ display: "flex", alignItems:"center" }}>
            <div>{product.produit.Valeur}$</div>
            <div style={{ padding: "5px" }}>* {pourcentage}%</div>
            <div style={{ padding: "5px" }}>=</div>
            <div>{rentalValue}</div>
            <div style={{ padding: "5px" }}>*</div>
          </div>
        )}
        <div style={{ marginRight: "5px" }}>
          <input
            type="number"
            value={product.quant}
            onChange={(e) => {
              handleChangeInput(e);
            }}
          />
        </div>
        {user.userType === "Admin" && (
          <div style={{ padding: "5px", width: "80px" }}>
            {(rentalValue * product.quant).toFixed(2)}$
          </div>
        )}
      </div>
      <div className="btn-secondary">
        <Link to={`/Produit/Modifier/${product.produit._id}`} className="noDecoration">
          <EditIcon
            className="icons"
            title="Modifier l'item"
            onClick={() => {
              navigate(`/Produits/Modifier/${product.produit._id}`);
            }}
          />
        </Link>
        <DeleteIcon
          className="icons"
          title="Supprimer l'item"
          onClick={deleteItemFromOrder}
        />
      </div>
    </div>
  );
};

export default OrderRow;
