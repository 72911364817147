import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import defaultImage from "../../../assets/images/no-image-icon.png";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import config from "../../../config";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ProductDetails = ({ product, isLoading, setIsLoading }) => {
  const { user } = useGlobalContext();

  const [unit, setUnit] = useState("in");
  const convertToInches = (cm) => cm / 2.54;
  const [online, setOnline] = useState("");

  const [isAdmin, setIsAdmin] = useState() ;
  const [enStock, setEnStock] = useState();

  const [imagesList, setImagesList] = useState([]);
  const [activeImage, setActiveImage] = useState();

  const [statut, setStatut] = useState();

  const [totalSemaine, setTotalSemaine] = useState();
  const [totalSemainePlus, setTotalSemainePlus] = useState();
  const [totalMois, setTotalMois] = useState();


  useEffect(() => {
    if(user)
      {
        
    setIsAdmin(user.isAdmin);
    console.log("IsAdmin: ", isAdmin )
}
  }, [user]);

  useEffect(() => {
    if (product.Quantite > 1) {
      var stockLigne = product.Quantite + " de disponibles";
      setEnStock(stockLigne);
    } else {
      var stockLigne = product.Quantite + " de disponible";
      setEnStock(stockLigne);
    }
  }, [product]);

  const getImages = async () => {
    const response = await fetch(
      `${config.BASE_URL}/api/images/all/${product._id}`
    );
    const json = await response.json();
    if (response.ok && json.length > 0) {
      const newImageList = json.map(async (image, index) => {
        const awsKey = image.awsKey;
        const signedUrl = await getSignedURL(awsKey);
        const imageObject = { image: signedUrl, order: image.sortOrder };
        if (index === 0) {
          setActiveImage(imageObject.image);
        }
        return imageObject;
      });
      const resolvedImages = await Promise.all(newImageList);
      setImagesList(resolvedImages);
    } else {
      setActiveImage(defaultImage);
      setIsLoading(false);
    }
  };

  const [imagePreloaded, setImagePreloaded] = useState(false);

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = activeImage;

    preloadImage.onload = () => {
      setIsLoading(false);
      setImagePreloaded(true);
    };

    return () => {
      preloadImage.onload = null;
    };
  }, [activeImage]);

  const getSignedURL = async (awsKey) => {
    const response = await fetch(
      `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
    );
    if (response.ok) {
      const data = await response.json();
      return data.signedUrl;
    } else {
      return defaultImage;
    }
  };

  const handleImgClick = useCallback((image) => {
    setActiveImage(image);
  }, []);

  useEffect(() => {
    if (online) {
      setStatut("En ligne");
    } else setStatut("Hors ligne");
  }, [online]);

  useEffect(() => {
    if (product._id) {
      if (typeof product.EnLigne === "boolean") {
        setOnline(product.EnLigne);
      } else {
        setOnline(Boolean(product.EnLigne));
      }
      getImages();
    }

    setTotalSemaine(
      (parseFloat(product.Valeur) * parseInt(product.Semaine1)) / 100
    );
    setTotalSemainePlus(
      (parseFloat(product.Valeur) * parseInt(product.Semaine2)) / 100
    );
    setTotalMois(
      (parseFloat(product.Valeur) * parseInt(product.SemainePlus)) / 100
    );

    if (product.Quantite > 1) {
      var stockLigne = product.Quantite + " de disponibles";
      setEnStock(stockLigne);
    } else {
      var stockLigne = product.Quantite + " de disponible";
      setEnStock(stockLigne);
    }
  }, [product._id]);

  useEffect(() => {
    setImagesList([]);
  }, []);

  return (
    <>
      {!isLoading && imagePreloaded && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Card style={{ marginTop: "20px" }}>
                <div className="imgBig">
                  <img
                    src={activeImage}
                    className="bigImage"
                    alt=""
                    id="bigImage"
                  />
                </div>
                <div className="imgSmall">
                  {imagesList &&
                    imagesList.map((image, index) => (
                      <div
                        key={`image${index}`}
                        onClick={() => {
                          handleImgClick(image.image);
                        }}
                      >
                        <img
                          className={
                            activeImage === image.image ? "active" : ""
                          }
                          src={image.image}
                          alt=""
                          data-index={index}
                        />
                      </div>
                    ))}
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card style={{ marginTop: "20px" }}>
                <CardContent>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5>{enStock}</h5>
                    {statut && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                          textTransform: "uppercase"
                        }}
                      >
                        {statut}{" "}
                        <FiberManualRecordIcon
                          fontSize="small"
                          className={online ? "pointVert" : "pointRouge"}
                        />
                      </div>
                    )}
                  </div>
                  {isAdmin && (
                    <div className="price-box">
                      <div className="title">
                        <div className="sub-title">Valeur</div>
                        <div className="data">{product.Valeur.toFixed(2)}$</div>
                      </div>
                      <div className="title">
                        <div className="sub-title">Prix payé</div>
                        <div className="data">{product.Prix?.toFixed(2)}$</div>
                      </div>
                      <div className="location-title">Tarif de location</div>
                      <div className="title">
                        <div className="sub-title">
                          Semaine 1 / {product.Semaine1}%
                        </div>
                        <div className="data">{totalSemaine.toFixed(2)}$</div>
                      </div>
                      <div className="title">
                        <div className="sub-title">
                          Semaine 2 & 3 / {product.Semaine2}%
                        </div>
                        <div className="data">
                          {totalSemainePlus.toFixed(2)}$
                        </div>
                      </div>
                      <div className="title">
                        <div className="sub-title">
                          Semaine + / {product.SemainePlus}%
                        </div>
                        <div className="data">{totalMois.toFixed(2)}$</div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "14px"
                    }}
                  >
                    Description
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "12px"
                    }}
                  >
                    {product.Description !== "" ? (
                      product.Description
                    ) : (
                      <>N-D</>
                    )}
                  </div>

                  <div style={{ padding: "20px" }}>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontSize: "14px"
                      }}
                    >
                      Détails
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "13px"
                      }}
                    >
                      <div>Année</div>
                      <div>{product.Year !== "" ? product.Year : <>N-D</>}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "13px"
                      }}
                    >
                      <div>Marque</div>
                      <div>
                        {product.Brand !== "" ? product.Brand : <>N-D</>}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "13px"
                      }}
                    >
                      <div>Modèle</div>
                      <div>
                        {product.Model !== "" ? product.Model : <>N-D</>}
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontSize: "14px"
                      }}
                    >
                      <div>Dimensions</div>
                      <div style={{ display: "flex", alignItems: "center " }}>
                        <div style={{marginRight:"5px", textTransform:"uppercase", fontSize:"12px", fontWeight:"bold", letterSpacing:"2px"}}>Unité</div>
                        <div>
                          <Select
                           style={{fontSize:"12px"}}
                          size="small"
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                          >
                            <MenuItem style={{fontSize:"12px"}} value="cm">Centimètres</MenuItem>
                            <MenuItem style={{fontSize:"12px"}} value="in">Pouces</MenuItem>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "13px"
                      }}
                    >
                      <div>Largeur</div>
                      <div>
                        {product.Largeur > 0 ? (
                          <div>
                            {unit === "cm" ? (
                              <>{product.Largeur} cm</>
                            ) : (
                              <>
                                {convertToInches(product.Largeur).toFixed(2)} po
                              </>
                            )}
                          </div>
                        ) : (
                          <>N-D</>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "13px"
                      }}
                    >
                      <div>Profondeur</div>
                      <div>
                        {product.Profondeur > 0 ? (
                          <div>
                            {unit === "cm" ? (
                              <>{product.Profondeur} cm</>
                            ) : (
                              <>
                                {convertToInches(product.Profondeur).toFixed(2)}{" "}
                                po
                              </>
                            )}
                          </div>
                        ) : (
                          <>N-D</>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "13px"
                      }}
                    >
                      <div>Hauteur</div>
                      <div>
                        {product.Hauteur > 0 ? (
                          <div>
                            {unit === "cm" ? (
                              <>{product.Hauteur} cm</>
                            ) : (
                              <>
                                {convertToInches(product.Hauteur).toFixed(2)} po
                              </>
                            )}
                          </div>
                        ) : (
                          <>N-D</>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                        fontSize: "13px"
                      }}
                    >
                      <div>Diamètre</div>
                      <div>
                        {product.Diametre > 0 ? (
                          <div>
                            {unit === "cm" ? (
                              <>{product.Diametre} cm</>
                            ) : (
                              <>
                                {convertToInches(product.Diametre).toFixed(2)}{" "}
                                po
                              </>
                            )}
                          </div>
                        ) : (
                          <>N-D</>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "20px", fontSize:"13px" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{fontWeight:"bold"}}>Catégories : </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {product.Categories &&
                          product.Categories.map((categorie, index) => (
                            <div
                              key={categorie._id}
                              style={{ display: "flex", marginLeft: "5px" }}
                            >
                              {categorie.Name}
                              {index !== product.Categories.length - 1 && ","}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{fontWeight:"bold"}}>Décors : </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {product.Tags &&
                          product.Tags.filter(
                            (tag) => tag.tagType === "Décor"
                          ).map((tag, index) => (
                            <div
                              key={tag._id}
                              style={{ display: "flex", marginLeft: "5px" }}
                            >
                              {tag.Name}
                              {index !==
                                product.Tags.filter(
                                  (tag) => tag.tagType === "Décor"
                                ).length -
                                  1 && ","}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{fontWeight:"bold"}}>Tags : </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {product.Tags &&
                          product.Tags.filter(
                            (tag) => tag.tagType !== "Décor"
                          ).map((tag, index) => (
                            <div
                              key={tag._id}
                              style={{ display: "flex", marginLeft: "5px" }}
                            >
                              {tag.Name}
                              {index !==
                                product.Tags.filter(
                                  (tag) => tag.tagType !== "Décor"
                                ).length -
                                  1 && ","}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          
        </>
      )}
    </>
  );
};

export default ProductDetails;
