const MyInvoices = () => {
  return (
    <div>
      <div className="header">
        <div className="text">
          <div className="title">Factures</div>
          <div className="sub-title">Liste des factures.</div>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "30px" }}>Aucune facture</div>
    </div>
  );
};
export default MyInvoices;
