import { createContext, useReducer, useEffect } from "react";
import { categoriesReducer } from "./CategoryContext";
import { productsReducer } from "./ProductContext";
import { tagsReducer } from "./TagsContext";
import { selectedCategoryReducer } from "./selectedCategoryContext";
import { selectedTagsReducer } from "./selectedTagContext";
import { userReducer } from "./userContext";
import { orderReducer } from "./orderContext";
import { searchReducer } from "./searchContext";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [productsState, productsDispatch] = useReducer(productsReducer, {
    products: [],
    total: 0,
    scrollPosition: 0,
  });

  const [categoriesState, categoriesDispatch] = useReducer(categoriesReducer, {
    categories: null,
  });

  const [tagsState, tagsDispatch] = useReducer(tagsReducer, {
    tags: null,
  });

  const [selectedTagsState, selectedTagsDispatch] = useReducer(
    selectedTagsReducer,
    {
      selectedTags: [],
    }
  );

  const [selectedCategoriesState, selectedCategoriesDispatch] = useReducer(
    selectedCategoryReducer,
    {
      selectedCategories: [],
    }
  );

  const [userState, userDispatch] = useReducer(userReducer, {
    user: null,
  });

  const [orderState, orderDispatch] = useReducer(orderReducer, {
    order: null,
  });

  const [searchState, searchDispatch] = useReducer(searchReducer, {
    searching: {
      page: 1,
      limit: 24,
      start: "",
      end: "",
      sortBy: "Number",
      orderBy: 1,
      includeArchived: false,
      searchQuery: "",
      categorie: "Tous",
      decor: "Tous",
    },
    searchQuery: "",
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      userDispatch({ type: "LOGIN", payload: user });
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        ...orderState,
        orderDispatch,
        ...userState,
        userDispatch,
        ...productsState,
        productsDispatch,
        ...categoriesState,
        categoriesDispatch,
        ...tagsState,
        tagsDispatch,
        ...selectedCategoriesState,
        selectedCategoriesDispatch,
        ...selectedTagsState,
        selectedTagsDispatch,
        ...searchState,
        searchDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
