import { Container } from "@mui/material";

import "./settings.css";

import { useGlobalContext } from "../../hooks/useGlobalContext";
import config from "../../config";
import { useEffect } from "react";
import Apis from "./components/apis";
import TypeofProjects from "./components/typeofProjects";
import Jobs from "./components/jobs";
import Categories from "./components/categories";
import Tags from "./components/tags";
import MyCompanies from "./components/myCompanies";

const Settings = () => {
  const { user } = useGlobalContext();

  const fetchAllProjectTypes = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/settings/types`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        }
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user) fetchAllProjectTypes();
    
  }, [user]);

  return (
    <div className="main-content">
      <Container maxWidth="lg">
        <div className="header">
          <div className="text">
            <div className="title">Paramètres</div>
            <div className="sub-title">
              Configurer votre système, base de données et API's.
            </div>
          </div>
        </div>

        
        <MyCompanies />

        <Categories />
        <Tags />
        <Jobs />
        <TypeofProjects />
        <Apis />
      </Container>
    </div>
  );
};

export default Settings;
