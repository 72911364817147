import { useState, useEffect } from "react";
import logo from "../../../assets/images/deco_small.png";
import logo2 from "../../../assets/images/medic_small.png";
import { useLogin } from "../../../hooks/useLogin";
import { useNotify } from "../../../hooks/useNotify";
import { Button, TextField } from "@mui/material";

const ConnexionForm = () => {
  const { login, error, isLoading } = useLogin();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const { notify } = useNotify();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(userEmail, userPassword);
  };

  useEffect(() => {
    if (error) {
      notify("error", error);
    }
  }, [error]);

  return (
    <main className="form-signin">
      <form
        onSubmit={function (e) {
          handleSubmit(e);
        }}
      >
        <img src={logo} alt="" />

        <div className="sousTitre">&</div>

        <img src={logo2} alt="" />

        <h2 className="sousTitre">Administration</h2>

        <TextField
          id="outlined-basic"
          label="Courriel"
          variant="outlined"
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
          value={userEmail}
          className="custom-textfield"
        />

        <TextField id="outlined-basic" label="Mot de passe" variant="outlined"
          type="password"
          onChange={(e) => {
            setUserPassword(e.target.value);
          }}
          value={userPassword}
          className="custom-textfield"
        />

       <Button className="btn-primary" type="submit" >
          Connexion
        </Button>
      </form>
    </main>
  );
};

export default ConnexionForm;
