import { createContext } from "react";

export const SearchContext = createContext();

export const searchReducer = (searchState, action) => {
  switch (action.type) {
    case "SET_SEARCHING":
      return {
        ...searchState,
        searching: action.payload
      };
    case "SET_SEARCH_QUERY":
      return {
        ...searchState,
        searchQuery: action.payload
      };
    case "CLEAR_SEARCHING":
      return {
        ...searchState,
        searching: {},
        searchQuery: ""
      };
    default:
      return searchState;
  }
};
