import { Container, Grid } from "@mui/material";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import config from "../../../config";
import { useEffect, useState } from "react";
import LogCard from "./logs/logCard";
import "./logs/logs.css"

const DashboardLogs = () => {

    const {user} = useGlobalContext();
    const [logs, setLogs] = useState([]);
   
    const fetchLogs = async () => {
        console.log("Fetching logs");
        const response = await fetch(`${config.BASE_URL}/api/logs`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        });
        const json = await response.json();
        if (response.ok) {
            setLogs(json);
            console.log("Logs ",json);
        
        }

    }

    useEffect(() => {
        if(user)
            {
                fetchLogs();
            }   
    }
    , [user]);



    return (
        <Container maxWidth="lg">
            <div className="header">
                <div className="text">
                    <div className="title">Logs</div>
                    <div className="sub-title">Liste des logs.</div>
                </div>
            </div>
            <Grid container spacing={2} style={{marginTop:"10px", paddingBottom:"20px"}}>
                {logs.map((log) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={log._id}>
                        <LogCard log={log} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
    }

export default DashboardLogs;