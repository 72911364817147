import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import './ProductRentalsCalendar.css'; // Import custom CSS for styling

const ProductRentalsCalendar = ({ rentals }) => {
  const [numberOfMonths, setNumberOfMonths] = useState(2);

  // Function to calculate the number of months based on screen width
  function calculateNumberOfMonths() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 3; // Set the number of months for large screens
    } else if (screenWidth >= 768) {
      return 2; // Set the number of months for medium screens
    } else {
      return 1; // Set the number of months for small screens
    }
  }

  useEffect(() => {
    // Update the number of months when the window is resized
    function handleResize() {
      setNumberOfMonths(calculateNumberOfMonths());
    }

    // Attach the event listener
    window.addEventListener("resize", handleResize);
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="product-rentals-calendar">
      <DateRange
        editableDateInputs={false}
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={numberOfMonths}
        ranges={rentals}
        rangeColors={["#0979be"]}
        direction="horizontal"
        minDate={new Date()}
        onChange={() => {}}
      />
    </div>
  );
};

export default ProductRentalsCalendar;
