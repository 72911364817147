import { useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalContext } from "../../../hooks/useGlobalContext";
import { Card, Grid } from "@mui/material";
import AddBox from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditModal from "./editModal";
import { set } from "date-fns";
import { useNotify } from "../../../hooks/useNotify";

const TypeofProjects = () => {
  const { user } = useGlobalContext();
  const {notify} = useNotify()
  const [allTypes, setallTypes] = useState([]);
  const [activeType, setActiveType] = useState({
    _id: "",
    Name: "",
  });

  const [action, setAction] = useState({
    type: "",
    title: "",
  });

  const[showModal, setShowModal] = useState(false);

    const handleShow = () => {
        setShowModal(!showModal);
    }


  const fetchAllProjectTypes = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/settings/types`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        }
      });
      const data = await response.json();
      setallTypes(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(user) fetchAllProjectTypes();
  }, [user]);

  const handleClick = (e) => {
    console.log(e.target.getAttribute("data"));

    setActiveType({
      _id: e.target.getAttribute("data"),
      Name: e.target.textContent,
    });
    
  };


  const handleDoubleClick = (e) => {
    setAction({
      type: "edit",
      title: "Modifier le type de projet"
    });

    console.log(e.target.getAttribute("data"));
    //setActiveType(e.target.getAttribute("data"));
    handleShow();
  };

  const handleAdd = () => {
    setAction({
      type: "add",
      title: "Ajouter un type de projet"
    });

    handleShow(); 
  }

  const hanldeDelete = () => {
    if(activeType._id === ""){
      notify("error", "Veuillez sélectionner un type de projet à supprimer");
      return;
    }
    setAction({
      type: "delete",
      title: "Êtes-vous sûr de vouloir supprimer ce type de projet ?"
    });

    handleShow();
  }


  return (
    <div>
      <div className="sub-header">
        <div className="text">
          <div className="title">Types</div>
          <div className="sub-title">
            Ajouter, modifier ou supprimer des types de projets.
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Card className="card-list-settings">
              <div className="title">Types de projets</div>
              <div className="content">
                {allTypes.map((type) => (
                  <div
                    onClick={handleClick}
                    onDoubleClick={handleDoubleClick}
                    data={type._id}
                    key={type._id}
                    className={activeType._id === type._id ? "tag active" : "tag"}
                  >
                    {type.Name}
                  </div>
                ))}
              </div>
              <div className="footer">
                <div className="btn" title="Ajouter un type de poste"
                onClick={handleAdd}
                >
                  <AddBox />
                </div>

                <div className="btn" title="Supprimer le type de poste"
                onClick={hanldeDelete}
                
                >
                  <DeleteIcon className="btn" />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      
      <EditModal show={showModal} handleClose={handleShow} data={activeType} setDataList={setallTypes} action={action} from="types" />
    
    </div>
  );
};
export default TypeofProjects;
