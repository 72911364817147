import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext";
import { useNotify } from "../../hooks/useNotify";
import { useEffect, useState } from "react";
import config from "../../config";
import { Container } from "@mui/material";
import Loader from "../../components/loader";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditCompany from "./components/editCompany";
import CompanyInfos from "./components/companyInfos";
import MyProjects from "../projects/components/myProjects";
import MyOrders from "../orders/components/myOrders";
import MyInvoices from "../invoices/components/myInvoices";

import "./card.css";

const CompanyDetails = () => {
  const params = useParams();
  const { id } = params;
  const { user } = useGlobalContext();
  const { notify } = useNotify();
  const [isLoading, setIsloading] = useState(true);
  const [newCompany, setNewCompany] = useState();
  const [myProjects, setMyProjects] = useState();
  const [myOrders, setMyOrders] = useState();
  const [myInvoices, setMyInvoices] = useState();

  const [activeCompany, setActiveCompany] = useState();
  const [editing, setEditing] = useState(false);

  const handleSaveCompany = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/Company/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify(newCompany)
      });
      if (!response.ok) {
        throw new Error("Failed to update company");
      }
      notify("success", "Company updated successfully");
      setEditing(false);
      setActiveCompany(newCompany);
    } catch (error) {
      notify("error", error.message);
    }
  };

  const getCompanyProjects = async () => {
    try {
      const response = await fetch(
        `${config.BASE_URL}/api/projects/company/${activeCompany._id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch company projects");
      }
      const json = await response.json();
      if (json.length != 0) {
        setMyProjects(json);
        console.log("Projects : ", json);
      }
    } catch (error) {
      notify("error", error.message); // Set error message in state variable
    }
  };

  const getCompany = async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/api/Company/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch company");
      }
      const json = await response.json();
      if (json.length != 0) {
        setActiveCompany(json);
        setNewCompany(json);
      }
    } catch (error) {
      notify("error", error.message); // Set error message in state variable
    }
    setIsloading(false);
  };

  useEffect(() => {
    if (activeCompany) {
      getCompanyProjects();
    }
  }, [activeCompany]);

  useEffect(() => {
    if (user) {
      getCompany();
    }
  }, [user]);

  return (
    <div className="main-content">
      {isLoading && (
        <Container maxWidth="lg" style={{ height: "100%" }}>
          <Loader />
        </Container>
      )}
      {!isLoading && (
        <Container maxWidth="lg">
          <div className="header">
            <div className="text">
              <div className="title">Compagnie</div>
              <div className="sub-title">
                Détails de la compagnie et ses projets.
              </div>
            </div>
            {!editing ? (
              <div style={{ display: "flex" }}>
                <div
                  className="add-icon"
                  title="Modifier la compagnie"
                  onClick={() => setEditing(true)}
                >
                  <EditIcon />
                </div>
                <div className="add-icon" title="Archiver la compagnie">
                  <ArchiveIcon />
                </div>
                <div className="add-icon" title="Supprimer la compagnie">
                  <DeleteIcon />
                </div>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <div
                  className="add-icon"
                  title="Enregistrer la compagnie"
                  onClick={() => handleSaveCompany()}
                >
                  <SaveIcon />
                </div>
                <div
                  className="add-icon"
                  title="Annuler"
                  onClick={() => setEditing(false)}
                >
                  <CancelIcon />
                </div>
              </div>
            )}
          </div>

          <div className="details">
            {!editing && activeCompany && (
              <CompanyInfos company={activeCompany} editing={editing} />
            )}
            {editing && newCompany && (
              <EditCompany
                newCompany={newCompany}
                setNewCompany={setNewCompany}
                editing={editing}
              />
            )}
          </div>
          {!activeCompany.myCompany && (
            <>
              <MyProjects projects={myProjects} />
              <MyOrders companyId={id} />
              <MyInvoices companyId={id} />
            </>
          )}
        </Container>
      )}
    </div>
  );
};
export default CompanyDetails;
