import { Grid } from "@mui/material";
import ClientCardProjet from "./clientCardProjet";
import AddBoxIcon from "@mui/icons-material/AddBox";

const ProjectTeam = ({ newProject, setNewProject, setStateClientList, editing }) => {
  return (
    <>
      <div className="header">
        <div className="text">
          <div className="title">Équipe</div>
          <div className="sub-title">
            {newProject.Equipe.length}{" "}Équipier{newProject.Equipe.length > 1 ? "s" : ""}
          </div>
        </div>
        {editing && (
          <div
          onClick={() => {
            setStateClientList({ isPaneOpen: true });
          }}
        >
          <AddBoxIcon className="add-icon" />
        </div>
        )}
        
      </div>
      <Grid container spacing={3} style={{ margin: "0px", padding: "10px" }}>
        {newProject && newProject.Equipe.length === 0 && (
          <div style={{ textAlign: "center", margin: "30px", width: "100%" }}>
            Ajouter un client à l'équipe
          </div>
        )}

        {newProject &&
          newProject.Equipe &&
          newProject.Equipe.map((equipier) => {
            console.log("Équipier ID: ", equipier.client._id);

            return (
              <ClientCardProjet
                key={equipier.client._id}
                client={equipier}
                activeProject={newProject}
                setActiveProject={setNewProject}
                editing={editing}
              />
            );
          })}
      </Grid>
    </>
  );
};
export default ProjectTeam;
