import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField } from '@mui/material';
import AddBoxIcon from "@mui/icons-material/AddBox";
import '../../products.css';
import { useGlobalContext } from '../../../../hooks/useGlobalContext';
import config from '../../../../config';

function AddTag({ tagType, title, checkedTags, setCheckedTags }) {
  const { user, tagsDispatch } = useGlobalContext();

  const [Name, setNewTagName] = useState("");
  const [error, setError] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addTag = async () => {
    const newTag = {
      Name: Name,
      tagType: tagType
    };

    const response = await fetch(`${config.BASE_URL}/api/tags`, {
      method: 'POST',
      body: JSON.stringify(newTag),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.error);
    }
    if (response.ok) {
      tagsDispatch({ type: 'CREATE_TAG', payload: json });

      const addTag = {
        name: Name,
        id: json._id,
        tagType: tagType
      };
      setCheckedTags([...checkedTags, addTag]);

      setNewTagName('');
      setError(null);
      handleClose();
    }
  };

  return (
    <>
      <div style={{display:"flex"}} onClick={handleShow}>
        <AddBoxIcon  className="add-icon"/>
      </div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Tag Name"
            type="text"
            fullWidth
            value={Name}
            onChange={(e) => setNewTagName(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Fermer
          </Button>
          <Button onClick={addTag} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddTag;
